import React, { useEffect, useState } from "react";
import { Card, Row, Form, Col, Button, Modal, InputGroup } from "react-bootstrap";
import { SimpleTable } from "../../genericComponents/Tables";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashAlt } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import {
  addPayCode,
  fetchPayCode,
} from "../../../redux/actions/payCodeActions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { IoSearchOutline } from "react-icons/io5";
import AddPayCodeModal from "./AddPayCode";

function Index() {
  const [modalShow, setModalShow] = useState(false);
  // const dispatch = useDispatch();
  // const paycodeReducer = useSelector((state) => state.payCode);
  // console.log("paycodeReducer", paycodeReducer);

  // const formik = useFormik({
  //   initialValues: {
  //     payCodeType: "",
  //     payCode: "",
  //     makeinstallments: false,
  //   },
  //   onSubmit: (values) => {
  //     debugger;

  //     values = { ...values, payCodeType: +values.payCodeType };
  //     console.log(values);
  //     dispatch(addPayCode(values));
  //     setModalShow(false);
  //   },
  // });

  // useEffect(() => {
  //   dispatch(fetchPayCode());
  // }, [dispatch]);

  const paycodeColumn = [
    {
      Header: "Pay Code",
      accessor: "paycode",
    },
    {
      Header: "Type",
      accessor: "paycodeType",
    },
    {
      Header: "Actions",
      accessor: "Action",
      Cell: (props) => <DropdownThreeDotComponent />,
    },
  ];

  const paycodeData = [
    {
      id: 0,
      paycode: "1-00",
      paycodeType: "GR",
    },
    {
      id: 1,
      paycode: "3-00",
      paycodeType: "GR",
    },
    {
      id: 2,
      paycode: "4-00",
      paycodeType: "GR",
    },
    {
      id: 3,
      paycode: "7-00",
      paycodeType: "GM",
    },
    {
      id: 4,
      paycode: "8-00",
      paycodeType: "GO",
    },
    {
      id: 5,
      paycode: "21-01",
      paycodeType: "GR",
    },
    {
      id: 6,
      paycode: "40-00",
      paycodeType: "D",
    },
    {
      id: 7,
      paycode: "50-01",
      paycodeType: "R",
    },

  ]
  return (
    <>
      <div className="row">
        <div className="col-12 d-flex flex-wrap justify-content-between mb-8">
          <h2 className="align-self-center">Pay Code</h2>
          <div className="Fms-income">
            <button
              type="button"
              className="generic-gradient-button"
              onClick={() => setModalShow(true)}
            >
              Add Pay Code
            </button>
          </div>
        </div>
      </div>

      <Row>
        <Col lg="12" className="mb-3">
          <Card>
            <Card.Body className="p-0">
              <div className="d-flex flex-wrap justify-content-between align-items-center pt-8 pb-4 px-6">
                <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
                  <InputGroup className="mb-3">
                    <InputGroup.Text className="search-field-input-group" id="basic-addon1"><IoSearchOutline /></InputGroup.Text>
                    <Form.Control
                      className="search-field-input-class"
                      placeholder="Search Pay Code"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <SimpleTable
                    className="table-sm"
                    columns={paycodeColumn}
                    // data={paycodeReducer.payCodeData}
                    data={paycodeData}
                    customClass="cashCategoryTable"
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <AddPayCodeModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
      </Row>
    </>
  );
}

function DropdownThreeDotComponent() {
  const Swal = require("sweetalert2");
  function ThreeDotDropdown() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#01362c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="d-flex p-4">
      <div className="mr-4">
        <FiEdit
          size={18}
          onClick={() => setModalShow(true)}
          className="text-primary cursor-pointer" />
      </div>
      <div className="">
        <FaRegTrashAlt
          size={18}
          className="text-danger cursor-pointer" />
      </div>
      {/* <EditPayCodeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <AddPayCodeModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

function EditPayCodeModal(props) {
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          EDIT PAY CODE
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-5">
          <div className="col-xl-12  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Pay Code
              </label>
              <input
                type="text"
                className="form-control h-35px"
                placeholder="Enter Pay Code"
              />
            </div>
          </div>
          <Col>
            <label>
              <input type="checkbox" /> Make Installments
            </label>
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary">Save</Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default Index;
