import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
// import {
//   convertBase64,
//   FieldError,
//   IMAGES,
//   Notification,
// } from "../../../assets";

// import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
// import {
//   addOrganization,
//   getOrganizationList,
// } from "../../../redux/actions/organiaztionAction";
// import { azureBlob } from "../../../redux/api";
// import { FieldError } from "components/util-components/ErrorMessage";
import { IMAGES } from "../../../../_metronic/_assets/sass/images";
// import { IMAGES } from "assets/images";

export default function AddOrganizationModal(props) {
  const { organizationHandleClose, organizationShow } = props;
  return (
    <Modal
      className="p-0"
      aria-labelledby="contained-modal-title-vcenter"
      id="user-welcome-card"
      centered
      show={organizationShow}
      onHide={() => organizationHandleClose()}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header
        className="mx-4 pb-3 px-0 d-flex align-items-end"
        closeButton
      >
        <h5 className="generic-heading-level-3">Add Organization</h5>
      </Modal.Header>
      <ModalBody {...props} />
    </Modal>
  );
}
function ModalBody(props) {
  // const { register, handleSubmit, errors } = useForm();

  const [image, setImage] = useState(null);
  const [hideShow, sethideShow] = useState(true);
  // const [profilePicture, setProfilePicture] = useState(null);
  // const [profilePictureLoading, setProfilePictureLoading] = useState(null);
  // let auth = useAuth();
  // console.log("auth", auth);
  // let token = auth.user.token;
  let dispatch = useDispatch();
  function onChangeProfilePicture(e) {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      sethideShow(false);
    }
    let file = e.target.files[0];

    if (file) {
      let lastIndexOfDot = file.name.lastIndexOf(".");
      let name = file.name.slice(0, lastIndexOfDot);
      let ext = file.name.slice(lastIndexOfDot + 1, file.name.length);
      // convertBase64(file).then((getValue) => {
      //   setProfilePictureLoading(true);
      //   let fileData = {
      //     name: name,
      //     base64: getValue.split(",")[1],
      //     fileExtension: "." + ext,
      //   };
      //   azureBlob(fileData).then(
      //     (response) => {
      //       if (response.status === 200) {
      //         setProfilePicture(response.data);
      //         setProfilePictureLoading(false);
      //       }
      //     },
      //     (error) => {
      //       setProfilePictureLoading(false);
      //     }
      //   );
      // });
    }
  }
  const inputClick = () => {
    document.getElementById("inputFile").click();
  };
  // function moveToNext() {
  //   props.organizationHandleClose();
  // }
  function onSubmit(data) {
    // let finalData = {
    //   organizationId: 0,
    //   organizationName: data.organizationName,
    //   connectionString: "",
    //   createdById: auth.user.userId,
    //   photo: profilePicture?.url,
    // };
    // dispatch(addOrganization(finalData, token, moveToNext, Notification));
  }
  return (
    <form>
      <Modal.Body className="py-0 px-2">
        <div className="px-3 b7">
          <Row className="mb-2 mt-3">
            <Col lg="12">
              <Form.Group className="mb-2">
                <label className="salary-labels">{"Organization Name"}</label>
                <Form.Control
                  className="mb-2"
                  type="Text"
                  name="organizationName"
                  placeholder="Organization Name"
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                />
                {/* {errors.organizationName && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="add_new_project pt-3">
              <Form.Label className="salary-labels">
                Upload Attachments
              </Form.Label>

              {hideShow ? (
                <div className=" file_uploader d-flex justify-content-start ">
                  <InputGroup className="text-right f-14 position-relative justify-content-around align-items-center">
                    <div className="d-flex position-absolute ">
                      <img
                        className="uploaderImg"
                        src={IMAGES.FILEUPLOADERIMG}
                        alt=""
                      />
                      <span className="pl-3">
                        <p className="d-flex uploaderTextSize">
                          {" "}
                          <p className="textColor">Upload a file&nbsp;</p> or
                          drag and drop
                        </p>
                        <p className="uploaderGrayText text-left">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </span>
                    </div>
                    <Form.File
                      name="projectLogo"
                      // ref={register}
                      onChange={(e) => onChangeProfilePicture(e)}
                      className="w-100 "
                    />
                  </InputGroup>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="f-14 mt-2  relative ">
                    <div className="d-flex">
                      <span className="Project_logo_img">
                        <img
                          src={image}
                          height={80}
                          width={80}
                          alt=""
                          className="rounded-circle uploadedFileImg "
                        />
                      </span>
                      <span className="pl-5 ml-4 position-relative">
                        <button
                          type="button"
                          onClick={() => {
                            inputClick();
                          }}
                          className="btn btn-primary uploaderBtn my-3 cursor "
                        >
                          Replace
                        </button>
                        <input
                          type="file"
                          onChange={(e) => onChangeProfilePicture(e)}
                          className="btn position-absolute replaceBtn"
                          id="inputFile"
                        />

                        <button
                          onClick={() => {
                            setImage("");
                            sethideShow(true);
                          }}
                          className="btn btn-danger d-block  uploaderBtn"
                        >
                          Delete
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Button
                type="submit"
                className="mt-4 py-2 mt-3 mb-4 w-100 button-sizes Home-buttons"
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </form>
  );
}
