import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { WEB_PAGES } from "./pages";
import { PATH } from "./config";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <ContentRoute
          path={PATH.DASHBOARD}
          component={WEB_PAGES.DASHBOARD}
          exact
        /> */}
        <ContentRoute
          path={PATH.DASHBOARD}
          component={WEB_PAGES.FINANCEDASHBOARD}
          exact
        />
        <ContentRoute path={PATH.INCOME} component={WEB_PAGES.INCOME} exact />
        <ContentRoute path={PATH.EXPENSE} component={WEB_PAGES.EXPENSE} exact />
        <ContentRoute path={PATH.INVOICE} component={WEB_PAGES.INVOICE} exact />
        <ContentRoute
          path={PATH.NOTIFICATION_SETTING}
          component={WEB_PAGES.NOTIFICATION_SETTING}
          exact
        />

        <ContentRoute
          path={PATH.ADD_INVOICE}
          component={WEB_PAGES.ADD_INVOICE}
          exact
        />
        <ContentRoute path={PATH.CLIENTS} component={WEB_PAGES.CLIENTS} exact />
        <ContentRoute
          path={PATH.ORGANIZATION}
          component={WEB_PAGES.ORGANIZATION}
          exact
        />
        <ContentRoute
          path={PATH.MANAGE_USER}
          component={WEB_PAGES.MANAGE_USER}
          exact
        />
        <ContentRoute path={PATH.TRASH} component={WEB_PAGES.TRASH} exact />

        <ContentRoute path={PATH.LOAN} component={WEB_PAGES.LOAN} exact />
        <ContentRoute
          path={PATH.SALARIES}
          component={WEB_PAGES.SALARIES}
          exact
        />
        <ContentRoute
          path={PATH.ADDSALARY}
          component={WEB_PAGES.ADDSALARY}
          exact
        />
        <ContentRoute
          path={PATH.EDITSALARY}
          component={WEB_PAGES.EDITSALARY}
          exact
        />
        <ContentRoute
          path={PATH.COMPENSATION}
          component={WEB_PAGES.COMPENSATION}
          exact
        />
        <ContentRoute
          path={PATH.PAY_CODE}
          component={WEB_PAGES.PAY_CODE}
          exact
        />
        <ContentRoute
          path={PATH.PROVIDENTFUND}
          component={WEB_PAGES.PROVIDENTFUND}
          exact
        />
        <ContentRoute
          path={PATH.CASHCATEGORY}
          component={WEB_PAGES.CASHCATEGORY}
          exact
        />
        <ContentRoute
          path={PATH.BILLABLEHOURS}
          component={WEB_PAGES.BILLABLEHOURS}
          exact
        />
        <ContentRoute
          path={PATH.EMPLOYEECOMPENSATIONS}
          component={WEB_PAGES.EMPLOYEECOMPENSATIONS}
          exact
        />
        <ContentRoute
          path={PATH.VIEW_EMPLOYEE_COMPENSATION_DETAILS}
          component={WEB_PAGES.VIEW_EMPLOYEE_COMPENSATION_DETAILS}
          exact
        />
        <ContentRoute
          path={PATH.MONTHLYEMPLOYEECOMPENSATION}
          component={WEB_PAGES.MONTHLYEMPLOYEECOMPENSATION}
          exact
        />
        <ContentRoute
          path={PATH.ADDCOMPENSATION}
          component={WEB_PAGES.ADDCOMPENSATION}
          exact
        />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
