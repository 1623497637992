import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'

const NotificationTable = (props) => {
    const [email, setEmail] = useState(false);
    const [push, setPush] = useState(false);
    const [sms, setSms] = useState(false);

    useEffect(() => {
        setEmail(props.listItem.email);
        setPush(props.listItem.push);
        setSms(props.listItem.sms);
    }, [props.listItem.email, props.listItem.push, props.listItem.sms]);

    return (
        <tr>
            <td>
                {props.listItem.name}
            </td>
            <td className="text-center">
                <Form.Group className="no-margin">
                    <Form.Check
                        type="checkbox"
                        checked={email}
                        name="email"
                        onChange={() => setEmail(!email)}
                    />
                </Form.Group>
            </td>
            <td className="text-center">
                <Form.Group className="no-margin">
                    <Form.Check
                        type="checkbox"
                        checked={sms}
                        name="sms"
                        onChange={() => setSms(!sms)}
                    />
                </Form.Group>
            </td>
            <td className="text-center">
                <Form.Group className="no-margin">
                    <Form.Check
                        type="checkbox"
                        checked={push}
                        name="push"
                        onChange={() => setPush(!push)}
                    />
                </Form.Group>
            </td>
        </tr>
    )
}

export default NotificationTable
