export function request(actionType) {
  return {
    type: actionType,
  };
}

export function success(actionType, payload, info) {
  return {
    type: actionType,
    payload: payload,
    info: info,
  };
}
export function failure(actionType, error) {
  return {
    actionType: actionType,
    payload: error,
  };
}

export const PAYCODE_ACTIONS = {
  FETCH_PAY_CODE_REQUEST: "FETCH_PAY_CODE_REQUEST",
  FETCH_PAY_CODE_SUCCESS: "FETCH_PAY_CODE_SUCCESS",
  FETCH_PAY_CODE_FAILURE: "FETCH_PAY_CODE_FAILURE",
  ADD_PAY_CODE_REQUEST: "ADD_PAY_CODE_REQUEST",
  ADD_PAY_CODE_SUCCESS: "ADD_PAY_CODE_SUCCESS",
  ADD_PAY_CODE_FAILURE: "ADD_PAY_CODE_FAILURE",
  UPDATE_PAY_CODE_REQUEST: "UPDATE_PAY_CODE_REQUEST",
  UPDATE_PAY_CODE_SUCCESS: "UPDATE_PAY_CODE_SUCCESS",
  UPDATE_PAY_CODE_FAILURE: "UPDATE_PAY_CODE_FAILURE",
  DELETE_PAY_CODE_REQUEST: "DELETE_PAY_CODE_REQUEST",
  DELETE_PAY_CODE_SUCCESS: "DELETE_PAY_CODE_SUCCESS",
  DELETE_PAY_CODE_FAILURE: "DELETE_PAY_CODE_FAILURE",
};
