import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Chart from "react-apexcharts";
import ReactDatePicker from "react-datepicker";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { ReactSelectSm } from "../../genericComponents";

const Dashboard = () => {
  const StatsIcon = ({ isProfit }) =>
    isProfit ? (
      <FaCaretUp size="18" className="text-success" />
    ) : (
      <FaCaretDown size="18" className="text-danger" />
    );

  const expenseSeries = [1399217, 1052057, 350000, 150000, 120000];

  const expenseChartOptions = {
    labels: ["Salaries", "Laptop", "Rent", "Utilities", "Miscellaneous"],
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "250px",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "300px",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  };

  const clientSeries = [1399217, 1052057, 350000, 150000, 120000, 196000];

  const clientChartOptions = {
    labels: [
      "Ayaz Qaiser",
      "Umer Waqas",
      "Asad Abbas",
      "Hamza Saboor",
      "Farhan Gondal",
      "Others",
    ],
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "250px",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "300px",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  };

  const incomeSeries = [350000, 150000, 120000, 196000];

  const incomeChartOptions = {
    labels: ["Received", "Receivable", "Pending", "Hold"],
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "250px",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "300px",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  };

  const projectLeaderSeries = [
    {
      name: "Projects",
      data: [
        { projectBudget: 94, spend: 50, x: "Vision Collab", y: 44 },
        { projectBudget: 125, spend: 70, x: "MediClinic", y: 55 },
        { projectBudget: 23, spend: 12, x: "MediLink", y: 41 },
        { projectBudget: 23, spend: 12, x: "MFG", y: 64 },
        { projectBudget: 23, spend: 12, x: "Emformance", y: 22 },
        { projectBudget: 23, spend: 12, x: "AlfaCare", y: 43 },
        { projectBudget: 23, spend: 12, x: "OGOW", y: 21 },
      ],
    },
  ];

  const projectLeaderChartOptions = {
    chart: {
      height: "auto",
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        horizontal: true,
        // distributed: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      formatter: function(val, { dataPointIndex, w }) {
        let seriesData = w.config.series[0].data[dataPointIndex];

        // return `${w.globals.labels[dataPointIndex]}: ${val} `;
        return `$${seriesData.spend}/$${seriesData.projectBudget} ($${seriesData.y})`;
      },
      offsetX: 0,
      style: {
        fontSize: "12px",
        fontWeight: "normal",
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "250px",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "300px",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  };

  const incomeAndExpenseSeries = [
    {
      name: "Income",
      data: [144, 155, 141, 164, 122, 143, 121, 89, 93, 75, 54, 120],
    },
    { name: "Expense", data: [53, 32, 33, 52, 13, 44, 32, 34, 56, 23, 45, 23] },
  ];

  const incomeAndExpenseChartOptions = {
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "250px",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "300px",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  };

  const receivableAndReceivedSeries = [
    {
      name: "Receivable Sales",
      type: "column",
      data: [440, 505, 414, 600, 227, 413, 201, 352, 600, 320, 257, 160],
    },
    {
      name: "Received Sales",
      type: "column",
      data: [240, 355, 124, 555, 117, 134, 101, 152, 432, 230, 127, 230],
    },
    {
      name: "Target",
      type: "line",
      data: [200, 250, 500, 600, 432, 322, 117, 231, 522, 222, 612, 116],
    },
  ];

  const receivableAndReceivedChartOptions = {
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [2],
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "250px",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "300px",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };

  const clientAmountSeries = [
    {
      name: "Year (2019)",
      data: [44, 55, 41, 64, 22, 43, 21],
    },
    {
      name: "Year (2020)",
      data: [144, 155, 141, 164, 122, 143, 121],
    },
    {
      name: "Year (2021)",
      data: [344, 355, 341, 364, 322, 343, 321],
    },
  ];

  const clientAmountChartOptions = {
    xaxis: {
      categories: [
        "Ayaz Qaiser",
        "Umer Waqas",
        "Asad Abbas",
        "Hamza Saboor",
        "Farhan Gondal",
        "Fahad",
        "Zeeshan",
      ],
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "250px",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "300px",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  };

  const accumulatedRevenueSeries = [
    {
      name: "Achieved",
      type: "column",
      data: [440, 505, 414, 600, 227, 413, 201, 352, 600, 320, 257, 160],
    },
    {
      name: "Target",
      type: "line",
      data: [200, 250, 500, 600, 432, 322, 117, 231, 522, 222, 612, 116],
    },
  ];

  const accumulatedRevenueChartOptions = {
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "250px",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "300px",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };

  const expenseTypeSeries = [
    {
      name: "Income",
      data: [
        344,
        155,
        141,
        164,
        122,
        143,
        121,
        89,
        93,
        75,
        54,
        120,
        221,
        120,
        65,
        95,
      ],
    },
  ];

  const expenseTypeChartOptions = {
    xaxis: {
      categories: [
        "Salaries",
        "Grocery",
        "Utility",
        "Assets",
        "Refreshment",
        "Rent",
        "Health & Safety",
        "Purchase",
        "Travelling",
        "Internet",
        "Drinking Water",
        "Printing",
        "Electricity",
        "Office Equipments",
        "Housekeeping",
        "Stationary",
      ],
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: "250px",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "300px",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  };

  const expenseType = [
    { value: "upfront", label: "Assets" },
    { value: "recurrent", label: "Utility" },
    { value: "milestone Payment", label: "Rent" },
    { value: "milestone Payment", label: "Laptop" },
    { value: "milestone Payment", label: "Internet" },
  ];

  const clientType = [
    { value: "upfront", label: "Assets" },
    { value: "recurrent", label: "Utility" },
    { value: "milestone Payment", label: "Rent" },
    { value: "milestone Payment", label: "Laptop" },
    { value: "milestone Payment", label: "Internet" },
  ];

  const incomeType = [
    { value: "upfront", label: "Recieved" },
    { value: "recurrent", label: "Recievable" },
    { value: "milestone Payment", label: "Pending" },
    { value: "milestone Payment", label: "Hold" },
  ];
  const projectType = [
    { value: "upfront", label: "Nouman" },
    { value: "recurrent", label: "Obaid" },
    { value: "milestone Payment", label: "Ashfaq" },
  ];
  const analysisType = [
    { value: "upfront", label: "Assets" },
    { value: "recurrent", label: "Utility" },
    { value: "milestone Payment", label: "Rent" },
    { value: "milestone Payment", label: "Laptop" },
    { value: "milestone Payment", label: "Internet" },
  ];

  return (
    <div className="row">
      {/* Start Page Header (Title and Other Option) */}
      <div className="col-lg-12 mb-3">
        <h2 className="font-weight-boldest">Dashboard</h2>
      </div>
      {/* End Page Header (Title and Other Option) */}

      <Col lg="12">
        <Row>
          <Col>
            <div className="card card-custom gutter-b h-130px">
              <div className="card-body d-flex flex-column">
                <div className="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="text-dark-50 font-weight-bold">Revenue</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="font-weight-bolder font-size-h3">
                      $195.6M
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <StatsIcon isProfit />
                      <span>23.95%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col>
            <div className="card card-custom gutter-b h-130px">
              <div className="card-body d-flex flex-column">
                <div className="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="text-dark-50 font-weight-bold">Expense</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="font-weight-bolder font-size-h3">
                      $12.6M
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <StatsIcon />
                      <span>18.87%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col>
            <div className="card card-custom gutter-b h-130px">
              <div className="card-body d-flex flex-column">
                <div className="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="text-dark-50 font-weight-bold">Upwork</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="font-weight-bolder font-size-h3">
                      $37.8M
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <StatsIcon isProfit />
                      <span>15%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col>
            <div className="card card-custom gutter-b h-130px">
              <div className="card-body d-flex flex-column">
                <div className="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="text-dark-50 font-weight-bold">Xeven UK</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="font-weight-bolder font-size-h3">5.6%</div>
                    <div className="d-flex flex-column align-items-center">
                      <StatsIcon isProfit />
                      <span>3.95%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col>
            <div className="card card-custom gutter-b h-130px">
              <div className="card-body d-flex flex-column">
                <div className="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="text-dark-50 font-weight-bold">
                    PK Balance / UK Balance
                  </div>
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="font-weight-bolder font-size-h3">5%</div>
                    <div className="d-flex flex-column align-items-center">
                      <StatsIcon isProfit />
                      <span>3.95%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      {/* Start Expense Chart */}
      <div className="col-lg-6 mb-4">
        <Card className="h-100">
          <Card.Header className="py-6">
            <Card.Title className="m-0 font-weight-bolder">
              Expense Type
            </Card.Title>
          </Card.Header>
          <Card.Body className="py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-3 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="startDate">
                        Start Date
                      </label>
                      <ReactDatePicker
                        id="startDate"
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="endDate">
                        End Date
                      </label>
                      <ReactDatePicker
                        id="endDate"
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="catalog">
                        Select Category
                      </label>

                      <ReactSelectSm options={expenseType} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Chart
                  options={expenseChartOptions}
                  series={expenseSeries}
                  type="pie"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* End Expense Chart */}

      {/* Start Client Chart */}
      <div className="col-lg-6 mb-4">
        <Card className="h-100">
          <Card.Header className="py-6">
            <Card.Title className="m-0 font-weight-bolder">Clients</Card.Title>
          </Card.Header>
          <Card.Body className="py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-3 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="startDate">
                        Start Date
                      </label>
                      <ReactDatePicker
                        id="startDate"
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="endDate">
                        End Date
                      </label>
                      <ReactDatePicker
                        id="endDate"
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="catalog">
                        Select Category
                      </label>

                      <ReactSelectSm options={clientType} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Chart
                  options={clientChartOptions}
                  series={clientSeries}
                  type="pie"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* End Client Chart */}

      {/* Start Income Chart */}
      <div className="col-lg-6 mb-4">
        <Card className="h-100">
          <Card.Header className="py-6">
            <Card.Title className="m-0 font-weight-bolder">Income</Card.Title>
          </Card.Header>
          <Card.Body className="py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-3 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="startDate">
                        Start Date
                      </label>
                      <ReactDatePicker
                        id="startDate"
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="endDate">
                        End Date
                      </label>
                      <ReactDatePicker
                        id="endDate"
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="catalog">
                        Select Category
                      </label>

                      <ReactSelectSm options={incomeType} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Chart
                  options={incomeChartOptions}
                  series={incomeSeries}
                  type="pie"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* End Income Chart */}

      {/* Start Project Leaders */}
      <div className="col-lg-6 mb-4">
        <Card className="h-100">
          <Card.Header className="py-6">
            <Card.Title className="m-0 font-weight-bolder">Projects</Card.Title>
          </Card.Header>
          <Card.Body className="py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="catalog">
                        Select Project
                      </label>

                      <ReactSelectSm options={projectType} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Chart
                  options={projectLeaderChartOptions}
                  series={projectLeaderSeries}
                  type="bar"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* End Project Leaders */}

      {/* Start Income / Expense */}
      <div className="col-lg-6 mb-4">
        <Card className="h-100">
          <Card.Header className="py-6">
            <Card.Title className="m-0 font-weight-bolder">
              Income/Expense
            </Card.Title>
          </Card.Header>
          <Card.Body className="py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="startDate">
                        Select Year
                      </label>
                      <ReactDatePicker
                        id="startDate"
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        showYearPicker
                        dateFormat="yyyy"
                        selected={new Date()}
                        onChange={(year) => console.log(year)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Chart
                  options={incomeAndExpenseChartOptions}
                  series={incomeAndExpenseSeries}
                  type="bar"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* End Income / Expense */}

      {/* Start Receivable/Received Sell */}
      <div className="col-lg-6 mb-4">
        <Card className="h-100">
          <Card.Header className="py-6">
            <Card.Title className="m-0 font-weight-bolder">
              Receivable/Received Sell
            </Card.Title>
          </Card.Header>
          <Card.Body className="py-5">
            <div className="row">
              <div className="col-lg-12">
                <Chart
                  options={receivableAndReceivedChartOptions}
                  series={receivableAndReceivedSeries}
                  type="line"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* End Receivable/Received Sell */}

      {/* Start Client Amount Calculation */}
      <div className="col-lg-6 mb-4">
        <Card className="h-100">
          <Card.Header className="py-6">
            <Card.Title className="m-0 font-weight-bolder">
              Client Amount Calculation
            </Card.Title>
          </Card.Header>
          <Card.Body className="py-5">
            <div className="row">
              <div className="col-lg-12">
                <Chart
                  options={clientAmountChartOptions}
                  series={clientAmountSeries}
                  type="bar"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* End Client Amount Calculation */}

      {/* Start Accumulated Revenue */}
      <div className="col-lg-6 mb-4">
        <Card className="h-100">
          <Card.Header className="py-6">
            <Card.Title className="m-0 font-weight-bolder">
              Accumulated Revenue
            </Card.Title>
          </Card.Header>
          <Card.Body className="py-5">
            <div className="row">
              <div className="col-lg-12">
                <Chart
                  options={accumulatedRevenueChartOptions}
                  series={accumulatedRevenueSeries}
                  type="line"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* End Accumulated Revenue */}

      {/* Start Accumulated Revenue */}
      <div className="col-lg-12 mb-4">
        <Card className="h-100">
          <Card.Header className="py-6">
            <Card.Title className="m-0 font-weight-bolder">
              Expense Typewise Analysis
            </Card.Title>
          </Card.Header>
          <Card.Body className="py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="catalog">
                        Select Category
                      </label>

                      <ReactSelectSm options={analysisType} isMultiple />
                    </div>
                  </div>
                  <div className="col-lg-3 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="startDate">
                        Start Date
                      </label>
                      <ReactDatePicker
                        id="startDate"
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 pr-lg-0">
                    <div className="form-group">
                      <label className="form-label" htmlFor="endDate">
                        End Date
                      </label>
                      <ReactDatePicker
                        id="endDate"
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Chart
                  options={expenseTypeChartOptions}
                  series={expenseTypeSeries}
                  type="bar"
                  height="450"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* End Accumulated Revenue */}
    </div>
  );
};

export default Dashboard;
