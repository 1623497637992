import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Card, Form, Dropdown, Breadcrumb, InputGroup } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

import { SimpleTable } from "../../genericComponents";
import { PATH } from "../../config";
import { AiFillDelete, AiOutlineEye, AiOutlineFilePdf, AiOutlinePlus } from "react-icons/ai";
import { IoSearchOutline } from "react-icons/io5";
import { FiSend } from "react-icons/fi";
import { BsEnvelope, BsThreeDotsVertical } from "react-icons/bs";
import { HiDuplicate } from "react-icons/hi";
import { FaEdit, FaRegBell } from "react-icons/fa";
import { BiDollarCircle } from "react-icons/bi";

const MonthlyEmplpoyeeCompensation = () => {
  const employeeCompensationColumn = [
    {
      Header: "Employee",
      accessor: "employee",
    },
    {
      Header: "Pay Schedule",
      accessor: "paySchedule",
    },
    {
      Header: "Pay Type",
      accessor: "payType",
    },
    {
      Header: "Pay Rate",
      accessor: "payRate",
    },
    {
      Header: "Benefits",
      accessor: "benefits",
    },
    {
      Header: "Deductions",
      accessor: "deductions",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: () => <DropdownThreeDotComponent />,
    },
  ];
  const employeeCompensationData = [
    {
      id: 0,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
    {
      id: 1,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
    {
      id: 2,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
    {
      id: 3,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
    {
      id: 4,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
    {
      id: 5,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
    {
      id: 6,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
    {
      id: 7,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
    {
      id: 8,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
    {
      id: 9,
      employee: "Michael Cera",
      paySchedule: "Monthly",
      payType: "Salary",
      payRate: "50000",
      benefits: "10000",
      deductions: "5000",
    },
  ]
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12 d-flex justify-content-between mb-8">
          <h2 className="align-self-center">Employee Monthly Compensations</h2>
          <div className="Fms-income">
            <Link to={PATH.ADDCOMPENSATION}>
              <button
                type="button"
                className="generic-gradient-button"
              >
                <span className="header-span">
                  <AiOutlinePlus />
                </span>
                Add Compensation
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Card className="finance-card">
        <Card.Body className="p-0">
          <div className="d-flex flex-wrap justify-content-between align-items-center pt-6 pb-2 px-6">
            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
              <InputGroup className="mb-3">
                <InputGroup.Text className="search-field-input-group" id="basic-addon1"><IoSearchOutline /></InputGroup.Text>
                <Form.Control
                  className="search-field-input-class"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="d-flex flex-wrap justify-content-end">
              <div className="d-flex flex-wrap justify-content-end">
                <div className="select_disable mr-2 mb-2">
                  <Form.Group className="mb-3">
                    <input type="date" placeholder="Date" defaultValue="Date" className="form-control finance-select-datepicker" />
                  </Form.Group>
                </div>
              </div>
              <div className="mr-2 mb-2">
                <button className="btn project_clr_btn cursor-pointer">
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 px-0">
            <SimpleTable columns={employeeCompensationColumn} data={employeeCompensationData} />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default MonthlyEmplpoyeeCompensation;

function DropdownThreeDotComponent() {
  const history = useHistory()
  const Swal = require("sweetalert2");
  function ThreeDotDropdown() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#01362c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }
  // const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Dropdown className="day-cell-dropdown text-right bg-transperant fms-button-three-dots">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="dropdown-button"
        >
          <BsThreeDotsVertical color="#666666" size={24} /></Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
          // onClick={() => setModalShow(true)}
          >
            <FaEdit className="font-size-h3 mx-2" /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={() => history.push("/employeecompensations/view")}
          >
            <AiOutlineEye className="font-size-h3 mx-2" /> View Detail
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center text-danger"
            onClick={ThreeDotDropdown}
          >
            <AiFillDelete className="font-size-h3 mx-2" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <EditIncomeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
    </>
  );
}
function DropdownComponent() {
  const [customizeAmount, setCustomizeAmount] = useState("Paid");
  function Notification() {
    toast.success("Your Status has been changed", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <React.Fragment>
      <Dropdown>
        <Dropdown.Toggle
          // variant="success"
          id="dropdown-basic"
          className={`btn-block btn-sm price-modal-dropdown ${customizeAmount === "Paid"
            ? "fms-paid"
            : customizeAmount === "Pending"
              ? "fms-pending"
              : "fms-approved"
            }`}
        >
          {customizeAmount}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            className="py-2"
            onClick={() => {
              setCustomizeAmount("Paid");
              Notification();
            }}
          >
            {"Paid "}
          </Dropdown.Item>
          <Dropdown.Item
            className="py-2"
            onClick={() => {
              setCustomizeAmount("Approved");
              Notification();
            }}
          >
            {" Approved"}
          </Dropdown.Item>
          <Dropdown.Item
            className="py-2"
            onClick={() => {
              setCustomizeAmount("Pending");
              Notification();
            }}
          >
            {" Pending"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
}
