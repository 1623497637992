import React from "react";
import { Card, Breadcrumb } from "react-bootstrap";

const AddCompensation = () => {
  const payScheduleOptions = [
    {
      name: "Option 1",
    },
    {
      name: "Option 2",
    },
  ];
  const payTypeOptions = [
    {
      name: "Pay Type 1",
    },
    {
      name: "Pay Type 2",
    },
  ];
  return (
    <>
      <div>
        <Breadcrumb className="employee-salary-breadcrumb">
          <Breadcrumb.Item>Employee Monthly Compensation</Breadcrumb.Item>
          <Breadcrumb.Item active>Add Compensation</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Card className="finance-card">
        <Card.Header>
          <h4 style={{ color: "#009eff" }}>Employee Compensation</h4>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Pay Schedule
                </label>
                <select
                  name="designationOptions"
                  className="add-form-select"
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>

                  {payScheduleOptions?.map((list, index) => {
                    return (
                      <option
                        key={index}
                        value={list.name}
                        selected={
                          list.name === "" ? true : false
                        }
                      >
                        {list.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Pay Type
                </label>
                <select
                  name="designationOptions"
                  className="add-form-select"
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>

                  {payTypeOptions?.map((list, index) => {
                    return (
                      <option
                        key={index}
                        value={list.name}
                        selected={
                          list.name === "" ? true : false
                        }
                      >
                        {list.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Pay Rate
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
          </div>

          <h4 className="mt-6" style={{ color: "#009eff" }}>Benefits</h4>
          <hr />
          <div className="row mt-8">
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Salary
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Allowance
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Medical
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Increment
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Employee Referral Bonus
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Over Time
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Performance Bonus
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Trainings
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Leave Benefits
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Project Incentive
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
          </div>

          <h4 className="mt-6" style={{ color: "#009eff" }}>Deductions</h4>
          <hr />
          <div className="row mt-8">
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Provident Fund
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Income Tax
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Eobi
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Loan
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
            <div className="col-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Others
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-8">
            <button
              className="employee-salary-back-btn mr-4"
            >
              Back
            </button>
            <button
              type="button"
              className="generic-gradient-button"
            >
              Submit
            </button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddCompensation;