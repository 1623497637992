import React from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Accordion,
  Breadcrumb,
} from "react-bootstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  Formik,
  Form as FormikForm,
  FieldArray,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { AiOutlinePlus } from "react-icons/ai";
import { useHistory } from "react-router-dom";

function Add() {
  const history = useHistory();
  const projectOptions = [
    {
      name: "Project 1",
    },
    {
      name: "Project 2",
    },
  ];
  const clientOptions = [
    {
      name: "Client 1",
    },
    {
      name: "Client 2",
    },
  ];
  const currencyType = [
    { value: "upfront", name: "Dollar" },
    { value: "recurrent", name: "PKR" },
    { value: "milestone Payment", name: "IND" },
  ];
  const languageType = [
    { value: "upfront", name: "English" },
    { value: "recurrent", name: "Urdu" },
    { value: "milestone Payment", name: "Arabic" },
    { value: "milestone Payment", name: "Turkish" },
    { value: "milestone Payment", name: "Bulgarian" },
  ];

  return (
    <>
      <div>
        <Breadcrumb className="employee-salary-breadcrumb">
          <Breadcrumb.Item onClick={() => history.push("/expense")}>Expense</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => history.push("/invoices")}>
            Invoice
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Create Invoice</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Card className="finance-card">
        <Card.Header>
          <h4 style={{ color: "#009eff" }}>Who is the invoice for?</h4>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Company Name
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Company Name' />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Client
                </label>
                <select
                  name="designationOptions"
                  className="add-form-select"
                >
                  <option value="" selected disabled>
                    Select Client
                  </option>

                  {clientOptions?.map((list, index) => {
                    return (
                      <option
                        key={index}
                        value={list.name}
                        selected={
                          list.name === "" ? true : false
                        }
                      >
                        {list.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Project
                </label>
                <select
                  name="designationOptions"
                  className="add-form-select"
                >
                  <option value="" selected disabled>
                    Select Project
                  </option>

                  {projectOptions?.map((list, index) => {
                    return (
                      <option
                        key={index}
                        value={list.name}
                        selected={
                          list.name === "" ? true : false
                        }
                      >
                        {list.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Currency
                </label>
                <select
                  name="designationOptions"
                  className="add-form-select"
                >
                  <option value="" selected disabled>
                    Select Currency
                  </option>

                  {currencyType?.map((list, index) => {
                    return (
                      <option
                        key={index}
                        value={list.name}
                        selected={
                          list.name === "" ? true : false
                        }
                      >
                        {list.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Language
                </label>
                <select
                  name="designationOptions"
                  className="add-form-select"
                >
                  <option value="" selected disabled>
                    Select Language
                  </option>

                  {languageType?.map((list, index) => {
                    return (
                      <option
                        key={index}
                        value={list.name}
                        selected={
                          list.name === "" ? true : false
                        }
                      >
                        {list.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-9 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Address
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
          </div>

          <h4 className="mt-6" style={{ color: "#009eff" }}>General Info</h4>
          <hr />
          <div className="row mt-8">
            <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Invoice ID
                </label>
                <input type="number" disabled className='add-form-input-class form-control' placeholder='Auto Generated' />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Issued On
                </label>
                <input type="date" className='add-form-input-class form-control' placeholder='Select Date' />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Due On
                </label>
                <input type="date" className='add-form-input-class form-control' placeholder='Select Date' />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-sm-3">
              <div className="select_disable mr-2 mb-2">
                <label className="form-label add-form-label" htmlFor="catalog">
                  Reference
                </label>
                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
              </div>
            </div>
          </div>

          <h4 className="mt-6" style={{ color: "#009eff" }}>Items to be Billed</h4>
          <div className="row mt-8">
            <div className="col-12">
              <Formik
                initialValues={{
                  friends: [
                    {
                      desciption: "",
                      hours: "",
                      cost: "",
                      amount: "",
                    },
                  ],
                }}
                onSubmit={(values) => {
                  setTimeout(() => {
                    console.log(JSON.stringify(values));
                  }, 500);
                }}
                render={({ values, handleChange }) => (
                  <FormikForm>
                    <div>
                      <FieldArray
                        name="friends"
                        render={(arrayHelpers) => (
                          <>
                            <Table className="invoice-create-table-array">
                              <thead>
                                <tr>
                                  <th>SrNo.</th>
                                  <th>Description</th>
                                  <th>Hours</th>
                                  <th>x Cost</th>
                                  <th>Amount</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {values.friends &&
                                  values.friends.length > 0
                                  ? values.friends.map(
                                    (friend, index) => (
                                      <tr key={index}>
                                        <td>
                                          {index + 1}
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name={`friends.${index}.desciption`}
                                            onChange={handleChange}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name={`friends.${index}.hours`}
                                            onChange={handleChange}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name={`friends.${index}.cost`}
                                            onChange={handleChange}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name={`friends.${index}.amount`}
                                            onChange={handleChange}
                                          />
                                        </td>

                                        <td>
                                          <FaRegTrashAlt
                                            className="text-danger font-size-h5 m-3 cursor-pointer"
                                            onClick={() =>
                                              arrayHelpers.remove(
                                                index
                                              )
                                            }
                                          />
                                        </td>
                                      </tr>
                                    )
                                  )
                                  : []}
                              </tbody>
                            </Table>
                            <Row>
                              <Col lg="12 d-flex justify-content-between">
                                <div>
                                  <Button
                                    size="sm"
                                    className="add-array-field-btn"
                                    onClick={() =>
                                      arrayHelpers.push("")
                                    }
                                  >
                                    <span>
                                      <AiOutlinePlus />
                                    </span>
                                    Add Item
                                  </Button>
                                </div>

                              </Col>
                            </Row>
                          </>
                        )}
                      />
                    </div>
                    <div className="container">
                      <div className="row justify-content-end">
                        <div className="col-12 col-md-3">
                          <div className="d-flex justify-content-between">
                            <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Discount%</p>
                            <input
                              type="text"
                              style={{ width: "125px" }}
                              className="form-control"
                              name="discount"
                            />
                          </div>
                          <div className="d-flex justify-content-between mt-6">
                            <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Subtotal</p>
                            <h5 className="align-self-center">
                              $4687.41
                            </h5>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Total</p>
                            <h5 className="align-self-center">
                              $3939.00
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormikForm>
                )}
              />
            </div>
          </div>

          <Accordion defaultActiveKey="" className="mt-10">
            <Card className="add-invoice-accordion">
              <Accordion.Toggle as={Card.Header} eventKey="0" className="add-invoice-accordion-toggle">
                Add a Note
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>

                  <div className="select_disable mr-2 mb-2">
                    <label className="form-label add-form-label" htmlFor="catalog">
                      Note
                    </label>
                    <textarea class="form-control add-form-input-textarea" id="exampleTextarea" placeholder="Type Here" rows="3"></textarea>
                  </div>
                  <div className="d-flex justify-content-end mt-6">
                    <button
                      type="button"
                      className="generic-gradient-button"
                    >
                      Add
                    </button>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          {/* <Accordion className="mt-5">
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  className="text-decoration-none"
                >
                  Add a Note
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      as="textarea"
                      rows={2}
                      // onChange={handleChange}
                      name="note"
                    />
                  </Form.Group>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion> */}

          <div className="d-flex justify-content-end mt-8">
            <button
              type="button"
              className="generic-gradient-button"
            >
              Create Invoice
            </button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default Add;
