import React, { useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import { BsEye, BsFillEyeSlashFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { MANAGER_ROLE } from "../../../config";
// import { FieldError, Notification } from "../../../assets";
// import { MANAGER_ROLE } from "../../../config";
// import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
// import { getEmployeeData } from "../../../redux/actions";
// import {
//   addManageUser,
//   getManageUserList,
// } from "../../../redux/actions/manageUserAction";
// import { FieldError } from "components/util-components/ErrorMessage";
// import { MANAGER_ROLE } from "config";

export default function AddUserModal(props) {
  const { userHandleClose, userShow } = props;
  return (
    <Modal
      className="p-0"
      aria-labelledby="contained-modal-title-vcenter"
      id="user-welcome-card"
      centered
      show={userShow}
      onHide={() => userHandleClose()}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header
        className="mx-4 pb-3 px-0 d-flex align-items-end"
        closeButton
      >
        <h5 className="generic-heading-level-3">Add User</h5>
      </Modal.Header>
      <ModalBody {...props} />
    </Modal>
  );
}
function ModalBody(props) {
  // const { register, handleSubmit, errors, watch } = useForm();
  let dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  // let auth = useAuth();
  // let token = auth.user.token;

  function moveToNext() {
    // let info = {};
    // dispatch(getEmployeeData(info, auth.user.organizationId, auth.user.token));
    // props.userHandleClose();
  }
  function onSubmit(data) {
    // let finalData = {
    //   userId: 0,
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    //   email: data.email,
    //   password: data.password,
    //   userRoleId: parseInt(data.roleId),
    //   organizationId: auth.user?.organizationId,
    //   createdById: auth.user.userId,
    //   photo: null,
    //   departmentId: data.departmentId,
    // };
    // dispatch(addManageUser(finalData, token, moveToNext, Notification));
  }
  const password = useRef({});
  // password.current = watch("password", "");
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <form>
      <Modal.Body className="py-0 px-2">
        <div className="px-3 b7">
          <Row className="mb-2 mt-3">
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"First Name"}</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                />
                {/* {errors.firstName && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Last Name"}</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                />
                {/* {errors.lastName && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>

            <Col lg="12">
              <Form.Group>
                <label className="salary-labels">{"Email"}</label>
                <Form.Control
                  className="mb-2 w-100"
                  type="email"
                  name="email"
                  placeholder="Email"
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                />
                {/* {errors.email && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Role"}</label>
                <Form.Control
                  className="mb-2"
                  as="select"
                  name="roleId"
                  // ref={register({ required: true })}
                >
                  <option value="">Select Role</option>
                  {MANAGER_ROLE.map((item) => {
                    return <option value={item.id}>{item.title}</option>;
                  })}
                </Form.Control>
                {/* {errors.roleId && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Department"}</label>
                <Form.Control
                  className="mb-2"
                  as="select"
                  name="departmentId"
                  // ref={register({ required: true })}
                >
                  <option value="">Select Department</option>
                  <option value={34}>Augmentation</option>
                  <option value={35}>Bid</option>
                </Form.Control>
                {/* {errors.roleId && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Password"}</label>
                <InputGroup className="mb-3 position-relative ">
                  <Form.Control
                    className="mb-2"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    // ref={register({
                    //   required: true,
                    //   pattern:
                    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    // })}
                  />
                  <div className="position-absolute user_email_Icon-signIn h-100">
                    {passwordShown === false ? (
                      <BsFillEyeSlashFill
                        className="f-16 cursor-pointer"
                        onClick={togglePasswordVisiblity}
                      />
                    ) : (
                      <BsEye
                        className="f-16 cursor-pointer"
                        onClick={togglePasswordVisiblity}
                      />
                    )}
                  </div>
                </InputGroup>
                {/* {errors.password && (
                  <FieldError
                    message={
                      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                    }
                  />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Confirm Password"}</label>
                <InputGroup>
                  <Form.Control
                    className="mb-2"
                    type={confirmPasswordShown ? "text" : "password"}
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    // ref={register({
                    //   validate: (value) =>
                    //     value === password.current ||
                    //     "The passwords does not match",
                    // })}
                  />
                  <div className="position-absolute user_email_Icon-signIn h-100">
                    {confirmPasswordShown === false ? (
                      <BsFillEyeSlashFill
                        className="f-16 cursor-pointer"
                        onClick={toggleConfirmPasswordVisiblity}
                      />
                    ) : (
                      <BsEye
                        className="f-16 cursor-pointer"
                        onClick={toggleConfirmPasswordVisiblity}
                      />
                    )}
                  </div>
                </InputGroup>
                {/* {errors.confirmPassword && (
                  <FieldError message="Password must be same" />
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Button
                type="submit"
                className="mt-4 py-2 mt-3 mb-4 w-100 button-sizes Home-buttons"
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </form>
  );
}
