import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { HiDuplicate } from "react-icons/hi";
import { IoSearchOutline } from "react-icons/io5";
import { BiDownload, BiUpload } from "react-icons/bi";
import { AiFillDelete, AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import { Card, Button, Dropdown, Modal, Form, InputGroup } from "react-bootstrap";
import { SimpleTable } from "../../genericComponents/Tables";
import ReactDatePicker from "react-datepicker";
import { ReactSelectSm } from "../../genericComponents";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { PATH } from "../../config";
import { BsThreeDotsVertical } from "react-icons/bs";
import AddExpenseModal from "./AddExpenseModal";

const Expense = () => {

  const expenseColumn = [
    {
      Header: "Income Description",
      accessor: "incomeDescription",
    },
    {
      Header: "Expense Type",
      accessor: "expenseType",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Paid By",
      accessor: "paidBy",
    },
    {
      Header: "Paid To",
      accessor: "paidTo",
    },
    {
      Header: "Amount (USD)",
      accessor: "amount",
    },
    {
      Header: "Payment Type",
      accessor: "paymentType",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Payment Status",
      accessor: "paymentStatus",
    },
    {
      Header: "Invoice Status",
      accessor: "invoiceStatus",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];
  const expenseData = [
    {
      id: 0,
      incomeDescription: "developer needed for creating...",
      expenseType: "Safety",
      category: "Health",
      paidBy: "Ali",
      paidTo: "Zain",
      amount: "290,000",
      paymentType: "Cash",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 1,
      incomeDescription: "designer creating custom website in Telemedicine",
      expenseType: "Medical",
      category: "Fuel",
      paidBy: "Zain",
      paidTo: "Abdullah",
      amount: "310,000",
      paymentType: "Credit Card",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 2,
      incomeDescription: "seller needed for the purpose of shop details...",
      expenseType: "Internal",
      category: "Funds",
      paidBy: "Abdullah",
      paidTo: "Waqas",
      amount: "373,000",
      paymentType: "Cash",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 3,
      incomeDescription: "Customer needed for the purpose of bank...",
      expenseType: "Safety",
      category: "Fuel",
      paidBy: "Waqas",
      paidTo: "Hassan",
      amount: "187,000",
      paymentType: "Credit Card",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 4,
      incomeDescription: "developer needed for creating...",
      expenseType: "Medical",
      category: "Health",
      paidBy: "Ali",
      paidTo: "Zain",
      amount: "798,000",
      paymentType: "Cash",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 5,
      incomeDescription: "designer creating custom website in Telemedicine",
      expenseType: "Internal",
      category: "Funds",
      paidBy: "Zain",
      paidTo: "Abdullah",
      amount: "112,000",
      paymentType: "Credit Card",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 6,
      incomeDescription: "seller needed for the purpose of shop details...",
      expenseType: "Safety",
      category: "Health",
      paidBy: "Abdullah",
      paidTo: "Waqas",
      amount: "310,000",
      paymentType: "Cash",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 7,
      incomeDescription: "Customer needed for the purpose of bank...",
      expenseType: "Medical",
      category: "Fuel",
      paidBy: "Waqas",
      paidTo: "Hassan",
      amount: "872,000",
      paymentType: "Credit Card",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 8,
      incomeDescription: "developer needed for creating...",
      expenseType: "Internal",
      category: "Funds",
      paidBy: "Ali",
      paidTo: "Bashir",
      amount: "111,000",
      paymentType: "Cash",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 9,
      incomeDescription: "designer creating custom website in Telemedicine",
      expenseType: "Safety",
      category: "Fuel",
      paidBy: "Waqas",
      paidTo: "Hassan",
      amount: "31,000",
      paymentType: "Credit Card",
      date: "5-7-2021",
      paymentStatus: <DropdownComponent />,
      invoiceStatus: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },

  ]
  const expenseType = [
    {
      name: "Expense 1",
    },
    {
      name: "Expense 2",
    },
  ];
  const invoiceOptions = [
    {
      name: "Invoice 1",
    },
    {
      name: "Invoice 2",
    },
  ];
  const statusOptions = [
    {
      name: "Status 1",
    },
    {
      name: "Status 2",
    },
  ];
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12 d-flex flex-wrap justify-content-between mb-8">
          <h2 className="align-self-center">Expense</h2>
          <div className="Fms-income d-flex flex-wrap justify-content-end">
            <Link to={PATH.INVOICE}>
              <button type="button" className="generic-gradient-button-inverted mb-2">
                View Invoice
              </button>
            </Link>
            <button
              type="button"
              className="generic-gradient-button ml-3 mb-2"
              onClick={() => setModalShow(true)}
            >
              Add Expense
            </button>
          </div>
        </div>
      </div>

      <Card className="finance-card">
        <Card.Body className="p-0">
          <div className="d-flex flex-wrap justify-content-between align-items-center py-8 px-6">
            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
              <InputGroup className="mb-3">
                <InputGroup.Text className="search-field-input-group" id="basic-addon1"><IoSearchOutline /></InputGroup.Text>
                <Form.Control
                  className="search-field-input-class"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="d-flex flex-wrap justify-content-end">
              <div className="d-flex flex-wrap justify-content-end">
                <div className="select_disable mr-4 mb-2 align-self-center">
                  <div className="d-flex cursor-pointer">
                    <BiDownload className="align-self-center mr-1" />
                    <p className="no-wrap">Import from Excel</p>
                  </div>
                </div>
                <div className="select_disable mr-4 mb-2 align-self-center">
                  <div className="d-flex cursor-pointer">
                    <BiUpload className="align-self-center mr-1" />
                    <p className="no-wrap">Export to Excel</p>
                  </div>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Expense Type
                    </option>

                    {expenseType?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Payment Status
                    </option>

                    {statusOptions?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Invoice Status
                    </option>

                    {invoiceOptions?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <Form.Group className="mb-3">
                    <input type="date" placeholder="Date" value="01-11-23" className="form-control finance-select-datepicker" />
                  </Form.Group>
                </div>
              </div>
              <div className="mr-2 mb-2">
                <button className="btn project_clr_btn cursor-pointer">
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 px-0">
            <SimpleTable columns={expenseColumn} data={expenseData} />
          </div>
        </Card.Body>
      </Card>
      <AddExpenseModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Expense;

function DropdownComponent() {
  const [customizeAmount, setCustomizeAmount] = useState("Paid");
  function Notification() {
    toast.success("Your Status has been changed", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <React-fragment>
      <Dropdown className="table-dropdown-status">
        <Dropdown.Toggle
          // variant="success"
          id="dropdown-basic"
          className={`btn-block price-modal-dropdown ${customizeAmount === "Paid" ? "fms-paid" : "fms-pending"
            }`}
        >
          {customizeAmount}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Paid");
              Notification();
            }}
          >
            {"Paid "}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Pending");
              Notification();
            }}
          >
            {" Pending"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React-fragment>
  );
}
function DropdownThreeDotComponent() {
  const Swal = require("sweetalert2");
  function ThreeDotDropdown() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#01362c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Dropdown className="day-cell-dropdown text-right bg-transperant fms-button-three-dots">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="dropdown-button"
        >
          <BsThreeDotsVertical color="#666666" size={24} /></Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={ThreeDotDropdown}
          >
            <HiDuplicate className="font-size-h3 mx-2" /> Duplicate
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
            onClick={() => setModalShow(true)}
          >
            <FaEdit className="font-size-h3 mx-2" /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center text-danger"
            onClick={ThreeDotDropdown}
          >
            <AiFillDelete className="font-size-h3 mx-2" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <EditIncomeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <AddExpenseModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
function EditIncomeModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          EDIT INCOME
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-5">
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Income Description
              </label>
              <input
                type="text"
                class="form-control h-35px"
                placeholder="Enter Description"
              />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Select Income Type
              </label>
              <ReactSelectSm options={[]} />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Select Client
              </label>
              <ReactSelectSm options={[]} />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Select Project
              </label>
              <ReactSelectSm options={[]} />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Amount
              </label>
              <input
                type="number"
                class="form-control h-35px"
                placeholder="Enter Amount"
              />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Select Status
              </label>
              <ReactSelectSm className="" options={[]} />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <label className="form-label" htmlFor="startDate">
              Start Date
            </label>
            <ReactDatePicker
              id="endDate"
              placeholderText="mm/dd/yyyy"
              className="w-365px  form-control form-control-sm"
              selected={new Date()}
            />
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
            <div className="form-group">
              <label for="formFileSm" class="form-label">
                Add Files
              </label>
              <input
                class="form-control form-control-sm"
                id="formFileSm"
                type="file"
              />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
            <div class="checkbox-list">
              <label class="checkbox">
                <input type="checkbox" name="Checkboxes1" />
                <span></span>
                Recurring Increament
              </label>
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Recursion Team
              </label>
              <ReactSelectSm className="" options={[]} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary">Save</Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
