import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import {
  BsBell,
  BsFillGrid3X3GapFill,
  BsFillPeopleFill,
  BsGear,
  BsSearch,
} from "react-icons/bs";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { DropDownListData } from "./DropdDownList";

function UpdatedHeader() {
  return (
    <div className="new-app-header">
      <Navbar
        expand="lg"
        className="d-flex justify-content-between new-app-headers"
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <Navbar.Brand href="#home" className="ms-auto title-resources">
            <BsFillPeopleFill className="mb-1" /> Finance Management System
          </Navbar.Brand>
          </div>
          <div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
        </div>

        <div className="d-flex">
          <Navbar.Collapse id="basic-navbar-nav">
            <DropDownListData />
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
}

export default UpdatedHeader;
