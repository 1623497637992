import React from 'react';
import { MdClose } from "react-icons/md"
import { Modal } from 'react-bootstrap';

function AddLoanModal(props) {
    const { setModalShow } = props;
    const reasonOptions = [
        {
            name: "Reason 1",
        },
        {
            name: "Reason 2",
        },
    ];
    const incomeOptions = [
        {
            name: "Income 1",
        },
        {
            name: "Income 2",
        },
    ];
    const loanTypeOptions = [
        {
            name: "Medical",
        },
        {
            name: "Financial",
        },
        {
            name: "Other",
        },
    ];
    const statusOptions = [
        {
            name: "Paid",
        },
        {
            name: "unpaid",
        },
    ];
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="d-flex justify-content-between mb-5">
                    <h2>Add Loan</h2>
                    <MdClose onClick={() => setModalShow(false)} className="align-self-center cursor-pointer" size={30} />
                </div>
                <hr />
                <div className="row mt-7 mb-5">
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Name
                            </label>
                            <input type="text" className='add-form-input-class form-control' placeholder='Name' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Phone Number
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='Phone Number' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Email
                            </label>
                            <input type="email" className='add-form-input-class form-control' placeholder='Email' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Amount
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='Amount' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Reason
                            </label>
                            <input type="text" className='add-form-input-class form-control' placeholder='Reason' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Paid Date
                            </label>
                            <input type="date" className='add-form-input-class form-control' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Due Date
                            </label>
                            <input type="date" className='add-form-input-class form-control' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Loan Type
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Loan Type
                                </option>

                                {loanTypeOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Status
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Status
                                </option>

                                {statusOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <button
                            type="button"
                            className="generic-gradient-button w-100"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddLoanModal