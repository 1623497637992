import React from "react";
import { Modal } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";

export function ConfirmationModal(props) {
  return (
    <Modal size="md" centered show={props.show} onHide={props.onHide}>
      <Modal.Header className="border-0 pb-0 px-3" closeButton></Modal.Header>
      <Modal.Body>
        <div className="pt-0 pb-3 px-3 text-center">
          <BsTrash className="delete-modal modal-delete-icon-styling mt-4 mb-3" />

          {/* <h5 className="delete-modal my-2">{props.heading}</h5> */}
          <h6 className="body-heading-style">
            Are you sure you want to delete <br /> {props.title}?
          </h6>
          <div className="row mt-4">
            <div className="col-6 text-right pr-2">
              <button
                className="vc-generic-btn-light rounded w-100"
                variant="light"
                onClick={props.onHide}
              >
                {"Cancel"}
              </button>
            </div>
            <div className="col-6 text-right pl-2">
              <button
                className="vc-generic-btn-danger rounded mr-1 w-100"
                onClick={() => {
                  // props.removeFunc();
                  props.onHide();
                }}
              >
                {"Delete"}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
