//  const baseURL = process.env.REACT_APP_API_URL;
const baseURL = "https://fmsapi.visioncollab.com";

export const API_PATH = {
  PAYCODE_API: {
    getAllPayCode: `${baseURL}/api/OrganizationPaycodes/GetAll`,
    addPayCode: `${baseURL}/api/OrganizationPaycodes/Add`,
    updatePayCode: `${baseURL}/api/OrganizationPaycodes/Update`,
    deletePayCode: `${baseURL}/api/OrganizationPaycodes/Delete/{id}`,
  },
};
