import React from 'react'
import { Breadcrumb, Card } from 'react-bootstrap'

function ViewEmployeeCompensationDetails() {
    return (
        <>
            <div>
                <Breadcrumb className="employee-salary-breadcrumb">
                    <Breadcrumb.Item>Compensations</Breadcrumb.Item>
                    <Breadcrumb.Item active>Ali Ahmed Compensation</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <Card className="finance-card">
                <Card.Header>
                    <h4 style={{ color: "#009eff" }}>Employee Compensation</h4>
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Pay Schedule</p>
                                <h5 className="align-self-center">
                                    Annual
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Pay Type</p>
                                <h5 className="align-self-center">
                                    Salary
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Pay Rate</p>
                                <h5 className="align-self-center">
                                    5000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Pay Code Type</p>
                                <h5 className="align-self-center">
                                    Benefit
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Pay Code</p>
                                <h5 className="align-self-center">
                                    012
                                </h5>
                            </div>
                        </div>
                    </div>

                    <h4 className="mt-6" style={{ color: "#009eff" }}>Earnings</h4>
                    <hr />
                    <div className="row mt-8">
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Salary</p>
                                <h5 className="align-self-center">
                                    50000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Allowance</p>
                                <h5 className="align-self-center">
                                    20000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Medical</p>
                                <h5 className="align-self-center">
                                    5000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Increment</p>
                                <h5 className="align-self-center">
                                    20000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Employee Referral Bonus</p>
                                <h5 className="align-self-center">
                                    1000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Overtime</p>
                                <h5 className="align-self-center">
                                    5000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Performace Bonus</p>
                                <h5 className="align-self-center">
                                    20000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Trainings</p>
                                <h5 className="align-self-center">
                                    10000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Leave Benefits</p>
                                <h5 className="align-self-center">
                                    5000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Project Incentive</p>
                                <h5 className="align-self-center">
                                    20000 PKR
                                </h5>
                            </div>
                        </div>
                    </div>

                    <h4 className="mt-6" style={{ color: "#009eff" }}>Deductions</h4>
                    <hr />
                    <div className="row">
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Provident Fund</p>
                                <h5 className="align-self-center">
                                    50000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Income Tax</p>
                                <h5 className="align-self-center">
                                    20000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Eobi</p>
                                <h5 className="align-self-center">
                                    3000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Loan</p>
                                <h5 className="align-self-center">
                                    20000 PKR
                                </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-sm-10">
                            <div className='d-flex justify-content-between'>
                                <p className="align-self-center m-0" style={{ fontSize: "16px", color: "#666" }}>Others</p>
                                <h5 className="align-self-center">
                                    10000 PKR
                                </h5>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default ViewEmployeeCompensationDetails