import React from "react";
import ReactSelect from "react-select";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 33,
    minHeight: 33,
    borderRadius: 6,
  }),
  placeholder: (base) => ({
    ...base,
    top: "45%",
  }),
  singleValue: (base) => ({
    ...base,
    top: "45%",
  }),
};

export const ReactSelectSm = (props) => (
  <ReactSelect {...props} styles={customStyles} />
);

export const Select = (props) => <ReactSelect {...props} />;
