import React from 'react';
import { MdClose } from "react-icons/md"
import { Modal } from 'react-bootstrap';

function AddCategoryModal(props) {
    const { setModalShow } = props;
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="d-flex justify-content-between mb-5">
                    <h2>Add Cash Category</h2>
                    <MdClose onClick={() => setModalShow(false)} className="align-self-center cursor-pointer" size={30} />
                </div>
                <hr />
                <div className="row mt-7 mb-5">
                    <div className="col-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Cash Category Name
                            </label>
                            <input type="text" className='add-form-input-class form-control' placeholder='Cash Category Name' />
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <button
                            type="button"
                            className="generic-gradient-button w-100"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddCategoryModal