import { request, success, failure, PAYCODE_ACTIONS } from "./utilities";
import {
  getAllPayCodeApi,
  addPayCodeApi,
  updatePayCodeApi,
  deletePayCodeApi,
} from "../api/payCodeApi";

export const fetchPayCode = () => {
  return (dispatch) => {
    dispatch(request(PAYCODE_ACTIONS.FETCH_PAY_CODE_REQUEST));
    getAllPayCodeApi()
      .then((response) => {
        if (response.data.succeeded === true) {
          console.log(response.data.data);
          dispatch(
            success(PAYCODE_ACTIONS.FETCH_PAY_CODE_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              PAYCODE_ACTIONS.FETCH_PAY_CODE_FAILURE,
              response.data.message
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          failure(PAYCODE_ACTIONS.FETCH_PAY_CODE_FAILURE, error.message)
        );
      });
  };
};

export const addPayCode = (data) => {
  debugger;
  return (dispatch) => {
    dispatch(request(PAYCODE_ACTIONS.ADD_PAY_CODE_REQUEST));
    addPayCodeApi(data)
      .then((response) => {
        debugger;
        console.log(response.data);
        dispatch(success(PAYCODE_ACTIONS.ADD_PAY_CODE_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failure(PAYCODE_ACTIONS.ADD_PAY_CODE_FAILURE, error.message));
      });
  };
};

export const updatePayCode = (data) => {
  return (dispatch) => {
    dispatch(request(PAYCODE_ACTIONS.UPDATE_PAY_CODE_REQUEST));

    updatePayCodeApi(data)
      .then((response) => {
        console.log(response.data);
        dispatch(
          success(PAYCODE_ACTIONS.UPDATE_PAY_CODE_SUCCESS, response.data)
        );
      })
      .catch((error) => {
        dispatch(
          failure(PAYCODE_ACTIONS.UPDATE_PAY_CODE_FAILURE, error.message)
        );
      });
  };
};

export const deletePayCode = (data) => {
  return (dispatch) => {
    dispatch(request(PAYCODE_ACTIONS.DELETE_PAY_CODE_REQUEST));

    deletePayCodeApi(data)
      .then((response) => {
        console.log(response.data);
        dispatch(
          success(PAYCODE_ACTIONS.DELETE_PAY_CODE_SUCCESS, response.data)
        );
      })
      .catch((error) => {
        dispatch(
          failure(PAYCODE_ACTIONS.DELETE_PAY_CODE_FAILURE, error.message)
        );
      });
  };
};
