import React, { useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { Card, Dropdown, Form, InputGroup } from "react-bootstrap";
import { SimpleTable } from "../../genericComponents/Tables";
import { toast, ToastContainer } from "react-toastify";
import { FiEdit } from "react-icons/fi";
import AddLoanModal from "./AddLoanModal";

const Loan = () => {

  const loanColumn = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Phone Number",
      accessor: "phoneNumber",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Reason",
      accessor: "reason",
    },
    {
      Header: "Paid Date",
      accessor: "paidDate",
    },
    {
      Header: "Due Date",
      accessor: "dueDate",
    },
    {
      Header: "Loan Type",
      accessor: "loanType",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (props) => <DropdownThreeDotComponent />,
    },
  ];
  const loanData = [
    {
      id: 0,
      name: "Ali",
      phoneNumber: "03017566078",
      email: "ali@gmail.com",
      amount: "100000",
      reason: "Financial",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "I owe to others",
      status: <DropdownComponent />,
    },
    {
      id: 1,
      name: "Ahmed",
      phoneNumber: "0325165165",
      email: "ahmed@gmail.com",
      amount: "300000",
      reason: "Medical",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "They owe me",
      status: <DropdownComponent />,
    },
    {
      id: 2,
      name: "Usman",
      phoneNumber: "03216584986",
      email: "usman@gmail.com",
      amount: "400000",
      reason: "Financial",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "I owe to others",
      status: <DropdownComponent />,
    },
    {
      id: 3,
      name: "Noman",
      phoneNumber: "0568416189",
      email: "noman@gmail.com",
      amount: "100000",
      reason: "Medical",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "They owe me",
      status: <DropdownComponent />,
    },
    {
      id: 4,
      name: "Ali",
      phoneNumber: "02056416510",
      email: "ali@gmail.com",
      amount: "200000",
      reason: "Financial",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "I owe to others",
      status: <DropdownComponent />,
    },
    {
      id: 5,
      name: "Ahmed",
      phoneNumber: "032186645785",
      email: "ahmed@gmail.com",
      amount: "600000",
      reason: "Medical",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "They owe me",
      status: <DropdownComponent />,
    },
    {
      id: 6,
      name: "Usman",
      phoneNumber: "03017566078",
      email: "usman@gmail.com",
      amount: "100000",
      reason: "Financial",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "I owe to others",
      status: <DropdownComponent />,
    },
    {
      id: 7,
      name: "Noman",
      phoneNumber: "03268464655",
      email: "noman@gmail.com",
      amount: "900000",
      reason: "Medical",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "They owe me",
      status: <DropdownComponent />,
    },
    {
      id: 8,
      name: "Ali",
      phoneNumber: "02056468654",
      email: "ali@gmail.com",
      amount: "100000",
      reason: "Financial",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "I owe to others",
      status: <DropdownComponent />,
    },
    {
      id: 9,
      name: "Golshifteh",
      phoneNumber: "03017566078",
      email: "golshifteh@gmail.com",
      amount: "800000",
      reason: "Medical",
      paidDate: "20-04-2022",
      dueDate: "20-04-2022",
      loanType: "They owe me",
      status: <DropdownComponent />,
    },
  ]
  const loanTypes = [
    {
      name: "Loan Type 1",
    },
    {
      name: "Loan Type 2",
    },
  ];
  const statusOptions = [
    {
      name: "Status 1",
    },
    {
      name: "Status 2",
    },
  ];
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12 d-flex flex-wrap justify-content-between mb-8">
          <h2 className="align-self-center">Loan</h2>
          <div className="Fms-income">
            <button
              type="button"
              className="generic-gradient-button"
              onClick={() => setModalShow(true)}
            >
              Add Loan
            </button>
          </div>
        </div>
      </div>

      <Card className="finance-card">
        <Card.Body className="p-0">
          <div className="d-flex flex-wrap justify-content-between align-items-center py-8 px-6">
            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
              <InputGroup className="mb-3">
                <InputGroup.Text className="search-field-input-group" id="basic-addon1"><IoSearchOutline /></InputGroup.Text>
                <Form.Control
                  className="search-field-input-class"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="d-flex flex-wrap justify-content-end">
              <div className="d-flex flex-wrap justify-content-end">
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Loan Type
                    </option>

                    {loanTypes?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Status
                    </option>

                    {statusOptions?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <Form.Group className="mb-3">
                    <input type="date" placeholder="Date" value="01-11-23" className="form-control finance-select-datepicker" />
                  </Form.Group>
                </div>
              </div>
              <div className="mr-2 mb-2">
                <button className="btn project_clr_btn cursor-pointer">
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 px-0">
            <SimpleTable columns={loanColumn} data={loanData} />
          </div>
        </Card.Body>
      </Card>
      <AddLoanModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Loan;

function DropdownComponent() {
  const [customizeAmount, setCustomizeAmount] = useState("Paid");
  function Notification() {
    toast.success("Your Status has been changed", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <React-fragment>
      <Dropdown className="table-dropdown-status">
        <Dropdown.Toggle
          // variant="success"
          id="dropdown-basic"
          className={`btn-block price-modal-dropdown ${customizeAmount === "Paid" ? "fms-paid" : "fms-pending"
            }`}
        >
          {customizeAmount}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Paid");
              Notification();
            }}
          >
            {"Paid "}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Pending");
              Notification();
            }}
          >
            {" Pending"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React-fragment>
  );
}
function DropdownThreeDotComponent() {
  const Swal = require("sweetalert2");
  function ThreeDotDropdown() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#01362c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="d-flex justify-content-end p-4">
      <div className="mr-4">
        <FiEdit
          size={18}
          onClick={() => setModalShow(true)}
          className="text-primary cursor-pointer" />
      </div>
      <div className="">
        <FaRegTrashAlt
          size={18}
          className="text-danger cursor-pointer" />
      </div>
      {/* <EditPayCodeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <AddLoanModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}
// function EditIncomeModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           EDIT INCOME
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="row mb-5">
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Income Description
//               </label>
//               <input
//                 type="text"
//                 class="form-control h-35px"
//                 placeholder="Enter Description"
//               />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Income Type
//               </label>
//               <ReactSelectSm options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Client
//               </label>
//               <ReactSelectSm options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Project
//               </label>
//               <ReactSelectSm options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 class="form-control h-35px"
//                 placeholder="Enter Amount"
//               />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Status
//               </label>
//               <ReactSelectSm className="" options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <label className="form-label" htmlFor="startDate">
//               Start Date
//             </label>
//             <ReactDatePicker
//               id="endDate"
//               placeholderText="mm/dd/yyyy"
//               className="w-365px  form-control form-control-sm"
//               selected={new Date()}
//             />
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div className="form-group">
//               <label for="formFileSm" class="form-label">
//                 Add Files
//               </label>
//               <input
//                 class="form-control form-control-sm"
//                 id="formFileSm"
//                 type="file"
//               />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div class="checkbox-list">
//               <label class="checkbox">
//                 <input type="checkbox" name="Checkboxes1" />
//                 <span></span>
//                 Recurring Increament
//               </label>
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Recursion Team
//               </label>
//               <ReactSelectSm className="" options={[]} />
//             </div>
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="primary">Save</Button>
//         <Button variant="secondary" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
