import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Modal,
  Pagination,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiBriefcase, BiUserPlus } from "react-icons/bi";
import { IoBriefcaseOutline, IoSearchOutline } from "react-icons/io5";
import { MdRestore } from "react-icons/md";
import { RiDeleteBin6Line, RiGroupLine } from "react-icons/ri";
import { ConfirmationModal } from "../../../_metronic/layout/components/ConfirmationModal";
import { RestoreConfirmationModal } from "../../../_metronic/layout/components/RestoreConfirmationModal";
import { EmptyTrashConfirmationModal } from "../../../_metronic/layout/components/EmptyTrashConfirmationModal";

// import {
//   ConfirmationModal,
//   RestoreConfirmationModal,
//   ParentComponentWithSideBar,
// } from "../../assets/genericComponents";
// import { HiOutlineUserGroup } from "react-icons/hi";
// import { ConfirmationModal } from "components/util-components/ConfirmationModal";
// import { EmptyTrashConfirmationModal } from "components/util-components/EmptyTrashConfirmationModal";
// import { RestoreConfirmationModal } from "components/util-components/RestoreConfirmationModal";
// import { EmptyTrashConfirmationModal } from "../../assets/genericComponents/EmptyTrashConfirmationModal";

export default function Trash() {
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const [show, setShow] = useState(false);
  useEffect(() => {
    document.title = "Trash | Covis";
  }, []);
  const [open, setOpen] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editShow, setEditShow] = useState(false);
  const editHandleClose = () => setEditShow(false);
  const editHandleShow = () => setEditShow(true);

  const [emptyTrashShow, setEmptyTrashShow] = useState(false);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        backgroundColor: " rgba(245, 245, 250, 0.4);",
      },
    },
    pagination: {
      pageButtonsStyle: {
        cursor: "no-drop",
        color: "#fbfbfb",
        opacity: "0.75",
        backgroundColor: "#eee",
      },
    },
  };

  const employeeColumn = [
    {
      name: "Employee",
      selector: (row) => row.EmpName,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (row) => row.Designation,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => row.Department,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div
                className="mr-2 cursor-pointer"
                style={{ color: "#009EFF" }}
                onClick={() => {
                  setEditShow(true);
                }}
              >
                <MdRestore
                  style={{ color: "#5E81F4" }}
                  className="cursor"
                  size={17}
                />
                {/* <span className="pl-2 pr-3">Restore</span> */}
              </div>
              <div
                style={{ color: "#E54545" }}
                onClick={() => {
                  setOpen(true);
                }}
                className="cursor-pointer"
              >
                <RiDeleteBin6Line className="cursor" size={17} />
                {/* <span className="pl-2 pr-3">Delete</span> */}
              </div>
            </div>
          </>
        );
      },
      sortable: false,
      allowOverflow: true,
      // width: 100,
      center: true,
    },
  ];
  const employeeProduct = [
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
    {
      EmpName: "Usama Ikram",
      Designation: "Software  Engineer",
      Department: "Development",
    },
  ];
  const projectsColumns = [
    {
      name: "Projects",
      selector: (row) => row.Projects,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.Category,
      sortable: true,
    },
    {
      name: "Action",

      cell: (row) => {
        return (
          <>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div
                className="mr-2 cursor-pointer"
                style={{ color: "#009EFF" }}
                onClick={() => {
                  setEditShow(true);
                }}
              >
                <MdRestore
                  style={{ color: "#5E81F4" }}
                  className="cursor"
                  size={17}
                />
                {/* <span className="pl-2 pr-3">Restore</span> */}
              </div>
              <div
                style={{ color: "#E54545" }}
                onClick={() => {
                  setOpen(true);
                }}
                className="cursor-pointer"
              >
                <RiDeleteBin6Line className="cursor" size={17} />
                {/* <span className="pl-2 pr-3">Delete</span> */}
              </div>
            </div>
          </>
        );
      },
      sortable: false,
      allowOverflow: true,
      width: "100px",
      center: true,
    },
  ];
  const categoryProducts = [
    {
      Projects: "Rehab Flow",
      Category: "DOMY",
    },
    {
      Projects: "Alfacare",
      Category: "Medical",
    },
    {
      Projects: "DOMY",
      Category: "Development",
    },
    {
      Projects: "Rude Ramin",
      Category: "Development",
    },
    {
      Projects: "Intely Health",
      Category: "Medical",
    },
    {
      Projects: "Covis Tech",
      Category: "Management",
    },
    {
      Projects: "iHakeem",
      Category: "Development",
    },
    {
      Projects: "Medi Clinic",
      Category: "Medical",
    },
    {
      Projects: "EduTech",
      Category: "Education",
    },
    {
      Projects: "Randox",
      Category: "Development",
    },
    {
      Projects: "Intely Health",
      Category: "Medical",
    },
    {
      Projects: "DOMY",
      Category: "Development",
    },
  ];
  const designationType = [
    {
      name: "Designation 1",
    },
    {
      name: "Designation 2",
    },
  ];
  const departmentType = [
    {
      name: "Department 1",
    },
    {
      name: "Department 2",
    },
  ];
  const projectType = [
    {
      name: "Project 1",
    },
    {
      name: "Project 2",
    },
  ];
  const categoryType = [
    {
      name: "Category 1",
    },
    {
      name: "Category 2",
    },
  ];
  const [tabConten, setTabcontent] = useState(true);
  const [tabsData, setTabsData] = useState(0);
  const [newAddButton, setNewAddButton] = useState(null);
  const [color, setColor] = useState(1);

  return (
    <div className="col-md-12 no-padding organization-screen trash-screen">
      <div className="Home-background  pb-3">
        <section className="content-header px-0 pt-1">
          <div className="container-fluid section-top-header no-padding">
            <div className="d-flex bd-highlight d-flex py-2 justify-content-between flex-wrap align-items-center">
              <div className="p-2 bd-highlight">
                <h1>{"Trash"}</h1>
              </div>
            </div>
          </div>
        </section>
        <Row className="mb-4">
          <Col lg={6} md={6} sm={8} xs={12}>
            <div className="d-flex hiring-buttons">
              <span
                className={`d-flex align-items-center cursor-pointer px-3 py-2 mb-2 responsive-btns ${
                  color === 1 ? "active-hiring-tabs" : "hiring-tabs"
                }   mr-2`}
                onClick={() => {
                  setTabsData(0);
                  setColor(1);
                }}
              >
                <RiGroupLine className="mr-2 plus-icon-btn" />
                Employees
              </span>
              <span
                className={`d-flex align-items-center cursor-pointer px-3 py-2 mb-2 responsive-btns ${
                  color === 2 ? "active-hiring-tabs" : "hiring-tabs"
                }   mr-2`}
                onClick={() => {
                  setTabsData(1);
                  setColor(2);
                }}
              >
                <IoBriefcaseOutline className="mr-2 plus-icon-btn" />
                Projects
              </span>
            </div>
          </Col>
          {tabsData == 0 ? (
            <Col lg={6} md={6} sm={4} xs={6}>
              <Button
                style={{
                  background: "#FB4D4D",
                  fontSize: "15px",
                }}
                className="px-3 py-2 d-flex align-items-center justify-content-end float-right mb-2 responsive-empty-btns"
                onClick={() => {
                  setEmptyTrashShow(true);
                }}
              >
                <span className="btn-text-center">
                  <RiDeleteBin6Line size={17} className="mr-2" />
                  Empty Trash
                </span>
              </Button>
            </Col>
          ) : null}
          {tabsData == 1 ? (
            <Col lg={6} md={6} sm={4} xs={6}>
              <Button
                style={{
                  background: "#FB4D4D",
                  fontSize: "15px",
                }}
                className="px-3 py-2 d-flex align-items-center justify-content-end float-right responsive-empty-btns"
                onClick={() => {
                  setEmptyTrashShow(true);
                }}
              >
                <span className="btn-text-center">
                  <RiDeleteBin6Line size={17} className="mr-2" />
                  Empty Trash
                </span>
              </Button>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col span={24}>
            {tabsData == 0 ? (
              <div className="mb-2 table-responsive">
                <div
                  className="mx-2 mb-3 d-flex align-items-center"
                  style={{ color: "#666666" }}
                >
                  <AiOutlineInfoCircle className="mr-2" />
                  <span>
                    Items older than 30 days are automatically deleted forever.
                  </span>
                </div>
                <React.Fragment>
                  <Card
                    className="border-0 h-100 mx-2"
                    style={{ "overflow-x": "hidden" }}
                  >
                    <Card.Body className="px-0 pb-0 ">
                      <Row>
                        <Col lg={12}>
                          <div className="d-flex flex-wrap justify-content-between align-items-center pb-3 px-4">
                            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
                              <IoSearchOutline
                                className="search-icon"
                                size={16}
                              />

                              <input
                                type="title"
                                className="form-control align-input-field"
                                id="title"
                                placeholder="Search Name"
                                name="title"
                              />
                            </div>
                            <div className="d-flex flex-wrap justify-content-end">
                              <div className="d-flex flex-wrap justify-content-end">
                                <div className="select_disable mr-2 mb-2">
                                  <select
                                    name="designationOptions"
                                    className="trash_select"
                                  >
                                    <option value="" selected disabled>
                                      Select Designation
                                    </option>

                                    {designationType?.map((list, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={list.name}
                                          selected={
                                            list.name === "" ? true : false
                                          }
                                        >
                                          {list.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className="select_disable mr-2 mb-2">
                                  <select
                                    name="departmentOptions"
                                    className="trash_select"
                                  >
                                    <option value="" selected disabled>
                                      Select Department
                                    </option>

                                    {departmentType?.map((list, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={list.name}
                                          selected={
                                            list.name === "" ? true : false
                                          }
                                        >
                                          {list.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="mr-2 mb-2">
                                <button className="btn project_clr_btn cursor-pointer">
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-2 table-responsive">
                        <DataTable
                          className="dataTables_wrapper"
                          columns={employeeColumn}
                          data={employeeProduct}
                          customStyles={customStyles}
                          highlightOnHover
                          responsive
                          striped
                          keyField="taskId"
                          pagination={true}
                        />
                      </div>
                      <EmptyTrashConfirmationModal
                        show={emptyTrashShow}
                        onHide={() => setEmptyTrashShow(false)}
                        title={"this project"}
                      />
                      <RestoreConfirmationModal
                        show={editShow}
                        onHide={() => setEditShow(false)}
                        heading={"Edit Project"}
                        title={"this employee"}
                      />

                      <ConfirmationModal
                        // removeFunc={() => removeEmp(member.userId)}
                        show={open}
                        onHide={() => setOpen(false)}
                        heading={"Delete Project"}
                        title={"this employee"}
                      />
                    </Card.Body>
                  </Card>
                </React.Fragment>
              </div>
            ) : null}

            {tabsData == 1 ? (
              <div className="mb-2 table-responsive">
                <div
                  className="mx-2 mb-3 d-flex align-items-center"
                  style={{ color: "#666666" }}
                >
                  <AiOutlineInfoCircle className="mr-2" />
                  <span>
                    Items older than 30 days are automatically deleted forever.
                  </span>
                </div>
                <React.Fragment>
                  <Card
                    className="border-0 h-100 mx-2"
                    style={{ "overflow-x": "hidden" }}
                  >
                    <Card.Body className="px-0 pb-0 ">
                      <Row>
                        <Col lg={12}>
                          <div className="d-flex flex-wrap justify-content-between align-items-center pb-3 px-4">
                            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
                              <IoSearchOutline
                                className="search-icon"
                                size={16}
                              />

                              <input
                                type="title"
                                className="form-control align-input-field"
                                id="title"
                                placeholder="Search Projects"
                                name="title"
                              />
                            </div>
                            <div className="responsive-select d-flex flex-wrap justify-content-end">
                              <div className="mr-2 flex-wrap">
                                <button className="btn project_clr_btn cursor-pointer">
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-2 table-responsive">
                        <DataTable
                          className="dataTables_wrapper"
                          columns={projectsColumns}
                          data={categoryProducts}
                          customStyles={customStyles}
                          highlightOnHover
                          responsive
                          striped
                          keyField="taskId"
                          pagination={true}
                        />
                      </div>
                      <EmptyTrashConfirmationModal
                        show={emptyTrashShow}
                        onHide={() => setEmptyTrashShow(false)}
                        title={"this project"}
                      />
                      <RestoreConfirmationModal
                        show={editShow}
                        onHide={() => setEditShow(false)}
                        heading={"Restore Project"}
                        title={"this project"}
                      />
                      <ConfirmationModal
                        // removeFunc={() => removeEmp(member.userId)}
                        show={open}
                        onHide={() => setOpen(false)}
                        // heading={"Delete Project"}
                        title={"this project"}
                      />
                    </Card.Body>
                  </Card>
                </React.Fragment>
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    </div>
  );
}
