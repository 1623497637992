import { PAYCODE_ACTIONS } from "../actions/utilities";

const INITIAL_STATE = {
  payCodeLoading: false,
  payCodeData: [],
  payCodeError: "",

  addPayCodeLoading: false,
  addPayCodeData: [],
  addPayCodeError: "",

  updatePayCodeLoading: false,
  updatePayCodeData: [],
  updatePayCodeError: "",

  deletePayCodeLoading: false,
  deletePayCodeData: [],
  deletePayCodeError: "",
};

export const payCodeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYCODE_ACTIONS.FETCH_PAY_CODE_REQUEST:
      return {
        ...state,
        payCodeLoading: true,
      };

    case PAYCODE_ACTIONS.FETCH_PAY_CODE_SUCCESS:
      return {
        ...state,
        payCodeLoading: false,
        payCodeData: action.payload,
        payCodeError: "",
      };
    case PAYCODE_ACTIONS.FETCH_PAY_CODE_FAILURE:
      return {
        ...state,
        payCodeLoading: false,
        payCodeData: [],
        payCodeError: action.payload,
      };

    case PAYCODE_ACTIONS.ADD_PAY_CODE_REQUEST:
      return {
        ...state,
        addPayCodeLoading: true,
      };

    case PAYCODE_ACTIONS.ADD_PAY_CODE_SUCCESS:
      state.payCodeData.push(action.payload);
      return {
        ...state,
        addPayCodeLoading: false,
        addPayCodeData: action.payload,
        addPayCodeError: "",
      };
    case PAYCODE_ACTIONS.ADD_PAY_CODE_FAILURE:
      return {
        ...state,
        addPayCodeLoading: false,
        addPayCodeData: [],
        addPayCodeError: action.payload,
      };
    case PAYCODE_ACTIONS.UPDATE_PAY_CODE_REQUEST:
      return {
        ...state,
        updatePayCodeLoading: true,
      };
    case PAYCODE_ACTIONS.UPDATE_PAY_CODE_SUCCESS:
      return {
        ...state,
        updatePayCodeLoading: false,
        updatePayCodeData: action.payload,
        updatePayCodeError: "",
      };
    case PAYCODE_ACTIONS.UPDATE_PAY_CODE_FAILURE:
      return {
        ...state,
        updatePayCodeLoading: false,
        updatePayCodeData: [],
        updatePayCodeError: action.payload,
      };

    case PAYCODE_ACTIONS.DELETE_PAY_CODE_REQUEST:
      return {
        ...state,
        deletePayCodeLoading: true,
      };
    case PAYCODE_ACTIONS.DELETE_PAY_CODE_SUCCESS:
      return {
        ...state,
        deletePayCodeLoading: false,
        deletePayCodeData: action.payload,
        deletePayCodeError: "",
      };
    case PAYCODE_ACTIONS.DELETE_PAY_CODE_FAILURE:
      return {
        ...state,
        deletePayCodeLoading: false,
        deletePayCodeData: [],
        deletePayCodeError: action.payload,
      };

    default:
      return state;
  }
};
