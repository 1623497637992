import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
export function HeaderDropDoenoptions({ navs }) {
  const navBarData = [
    {
      OptionsTitle: "Opions",
      internationalDestinations: null,
      europeDestinations: [
        {
          name: "Option name",
        },
        {
          name: "Option name2",
        },
      ],
    },
  ];
  return (
    <div className="sub-menu menu__content animated--fast css-qnpwi small-screen-adjust ">
      <div className="bt at_holidaycategory css-13ab30w">
        <div className="">
          <div className="0 at_submenudestination">
            <ul className="css-e04fjx pt-2">
              <>
                {navs.map((des, index) => {
                  //   if (index > 3) {
                  //     return null;
                  //   }
                  return (
                    <>
                      <li className="text-left pl-3 small-screen-padding">
                        <Link
                          to={des.path}
                          className="border-0 text-center hover-effect"
                        >
                          {des.title}
                        </Link>
                      </li>
                    </>
                  );
                })}
                {/* <li>
                            {data.tourTypeId === 300 ? (
                              <Link
                                className="opton-viewall"
                                to="#"
                              >
                                {`View All`}
                              </Link>
                            ) : data.tourTypeId === 301 ? (
                              <Link
                                className="opton-viewall"
                                to="#"
                              >
                                {`View All`}
                              </Link>
                            ) : data.tourTypeId === 302 ? (
                              <Link
                                className="opton-viewall"
                                to="#"
                              >
                                {`View All`}
                              </Link>
                            ) : data.tourTypeId === 303 ? (
                              <Link
                                className="opton-viewall"
                                to="#"
                              >
                                {`View All`}
                              </Link>
                            ) : (
                              <div />
                            )}
                          </li> */}
              </>
            </ul>
          </div>
          {/* <div className="col-md-4 col-sm-12 col-xs-12 col-12  at_submenuimage">
                      <div className="css-1tmjqcs">
                        {data &&
                          data.tourTypeImages &&
                          data.tourTypeImages.map((data, index) => {
                            if (data.type === "Adventure") {
                              return (
                                <>
                                  <div
                                    className="css-6ok6q2 css-6ok6q1"
                                    key={index}
                                  >
                                    <img
                                      alt="Honeymoon Packages"
                                      className="css-1te5b4g lazyloaded"
                                      src={data.link}
                                    />
                                  </div>
                                </>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
}
