import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { IoSearchOutline } from "react-icons/io5";
import { BsThreeDotsVertical, BsEnvelope, BsFileEarmarkPdf, BsEye } from "react-icons/bs";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { Card, Button, Dropdown, Modal, Form, InputGroup } from "react-bootstrap";
import { SimpleTable } from "../../genericComponents/Tables";
import ReactDatePicker from "react-datepicker";
import { ReactSelectSm } from "../../genericComponents";
import { toast, ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { PATH } from "../../config";
import { BiDollarCircle } from "react-icons/bi";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";


const Salaries = () => {

  const salariesColumn = [
    {
      Header: "Employee",
      accessor: "employee",
    },
    {
      Header: "Account",
      accessor: "account",
    },
    {
      Header: "Gross Salary",
      accessor: "grossSalary",
    },
    {
      Header: "Arrears",
      accessor: "arrears",
    },
    {
      Header: "Earnings",
      accessor: "earnings",
    },
    {
      Header: "Deduction",
      accessor: "deductions",
    },
    {
      Header: "Net Salary",
      accessor: "netSalary",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];
  const salariesData = [
    {
      id: 0,
      employee: "Salman Khan",
      account: "Standard Chartered",
      grossSalary: "20000",
      arrears: "100",
      earnings: "500",
      deductions: "5000",
      netSalary: "2000",
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 1,
      employee: "Ajay Devgan",
      account: "Alfalah",
      grossSalary: "40000",
      arrears: "100",
      earnings: "500123",
      deductions: "5054",
      netSalary: "20521",
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 2,
      employee: "Kriti Kharbanda",
      account: "HBL",
      grossSalary: "60000",
      arrears: "10087",
      earnings: "500415",
      deductions: "500045",
      netSalary: "200045",
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 3,
      employee: "Fardeen Khan",
      account: "Standard Chartered",
      grossSalary: "80000",
      arrears: "1008",
      earnings: "5004856",
      deductions: "500021",
      netSalary: "20009",
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 4,
      employee: "Travis Scott",
      account: "Faysal Bank",
      grossSalary: "100000",
      arrears: "10087",
      earnings: "5006",
      deductions: "500089",
      netSalary: "2000789",
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 5,
      employee: "MG Kelly",
      account: "Bank Al Habib",
      grossSalary: "120000",
      arrears: "10075",
      earnings: "5005",
      deductions: "500056",
      netSalary: "20005",
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 6,
      employee: "Katie Holmes",
      account: "Barclays",
      grossSalary: "140000",
      arrears: "100123",
      earnings: "50023",
      deductions: "500012",
      netSalary: "200065",
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 7,
      employee: "Charlize Theron",
      account: "Zarai Tarqyati",
      grossSalary: "160000",
      arrears: "1007",
      earnings: "500567",
      deductions: "500056",
      netSalary: "200012",
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 8,
      employee: "Venessa",
      account: "Sindh Bank",
      grossSalary: "180000",
      arrears: "100",
      earnings: "500",
      deductions: "5000",
      netSalary: "2000",
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 9,
      employee: "Fergie",
      account: "Allied",
      grossSalary: "200000",
      arrears: "100",
      earnings: "500",
      deductions: "5000",
      netSalary: "2000",
      actions: <DropdownThreeDotComponent />,
    },
  ]
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12 d-flex flex-wrap justify-content-between mb-8">
          <h2 className="align-self-center">Employee Salary</h2>
          <div className="Fms-income">
            <Link to={PATH.ADDSALARY}>
              <button
                type="button"
                className="generic-gradient-button"
              >
                Add Salary
              </button>
            </Link>
          </div>
        </div>
      </div>

      <Card className="finance-card" id="print_to_pdf">
        <Card.Body className="p-0">
          <div className="d-flex flex-wrap justify-content-between align-items-center py-8 px-6">
            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
              <InputGroup className="mb-3">
                <InputGroup.Text className="search-field-input-group" id="basic-addon1"><IoSearchOutline /></InputGroup.Text>
                <Form.Control
                  className="search-field-input-class"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="d-flex flex-wrap justify-content-end">
              <div className="d-flex flex-wrap justify-content-end">
                <div className="select_disable mr-2 mb-2">
                  {/* <Link to={""}> */}
                  <button type="button" className="generic-gradient-button-inner-inverted">
                    <span className="header-span">
                      <BiDollarCircle />
                    </span>
                    Generate Current Month Salaries
                  </button>
                  {/* </Link> */}
                </div>
                <div className="select_disable mr-2 mb-2">
                  <button
                    type="button"
                    className="generic-gradient-button-inner"
                  >
                    <span className="header-span">
                      <CgFileDocument />
                    </span>
                    Send Slips
                  </button>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <Form.Group className="mb-3">
                    <input type="date" placeholder="Date" value="01-11-23" className="form-control finance-select-datepicker" />
                  </Form.Group>
                </div>
              </div>
              <div className="mr-2 mb-2">
                <button className="btn project_clr_btn cursor-pointer">
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 px-0">
            <SimpleTable columns={salariesColumn} data={salariesData} />
          </div>
          <Button>
            download
          </Button>
        </Card.Body>
      </Card>
      {/* <AddIncomeModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} /> */}

    </>
  );
};

export default Salaries;

function DropdownComponent() {
  const [customizeAmount, setCustomizeAmount] = useState("Paid");
  function Notification() {
    toast.success("Your Status has been changed", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <React-fragment>
      <Dropdown className="table-dropdown-status">
        <Dropdown.Toggle
          // variant="success"
          id="dropdown-basic"
          className={`btn-block price-modal-dropdown ${customizeAmount === "Paid" ? "fms-paid" : "fms-pending"
            }`}
        >
          {customizeAmount}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Paid");
              Notification();
            }}
          >
            {"Paid "}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Pending");
              Notification();
            }}
          >
            {" Pending"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React-fragment>
  );
}
function DropdownThreeDotComponent() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const Swal = require("sweetalert2");
  function ThreeDotDropdown() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#01362c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }
  function sendSlip() {
    Swal.fire({
      title: "Slip send successfullye?",
    });
  }
  const projectOptions = [
    {
      name: "Covis",
    },
    {
      name: "Domy",
    },
    {
      name: "Redhanded",
    },
    {
      name: "RehabFlow",
    },
    {
      name: "Alfacare",
    },
    {
      name: "BreeHealth",
    },
  ];
  // const [modalShow, setModalShow] = useState(false);
  const printToPdf = () => {
    html2canvas(document.getElementById("print_to_pdf")).then((canvas) => {
      var data = canvas.toDataURL();
      // var img = canvas.toDataURL();
      var pdfExportSetting = {
        content: [
          {
            image: data,
            width: 500,
          },
        ],
      };
      pdfMake.createPdf(pdfExportSetting).download("Prescription.pdf");
    });
  };
  return (
    <>
      <Dropdown className="day-cell-dropdown text-right bg-transperant fms-button-three-dots">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="dropdown-button"
        >
          <BsThreeDotsVertical color="#666666" size={24} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
            onClick={() => history.push("/add-salary")}
          >
            <FaEdit className="font-size-h3 mx-2" onClick={handleShow} /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
            onClick={() => sendSlip()}
          >
            <CgFileDocument className="font-size-h3 mx-2" /> Send Slip
          </Dropdown.Item>
          {/* <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
          >
            <BsEnvelope className="font-size-h3 mx-2" /> Email
          </Dropdown.Item> */}
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
          >
            <BsFileEarmarkPdf className="font-size-h3 mx-2" onClick={() => printToPdf()} /> PDF
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
          >
            <BsEye className="font-size-h3 mx-2" /> View Details
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center text-danger"
            onClick={ThreeDotDropdown}
          >
            <AiFillDelete className="font-size-h3 mx-2" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <EditIncomeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
// function EditIncomeModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           EDIT INCOME
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="row mb-5">
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Income Description
//               </label>
//               <input
//                 type="text"
//                 class="form-control h-35px"
//                 placeholder="Enter Description"
//               />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Income Type
//               </label>
//               <ReactSelectSm options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Client
//               </label>
//               <ReactSelectSm options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Project
//               </label>
//               <ReactSelectSm options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 class="form-control h-35px"
//                 placeholder="Enter Amount"
//               />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Status
//               </label>
//               <ReactSelectSm className="" options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <label className="form-label" htmlFor="startDate">
//               Start Date
//             </label>
//             <ReactDatePicker
//               id="endDate"
//               placeholderText="mm/dd/yyyy"
//               className="w-365px  form-control form-control-sm"
//               selected={new Date()}
//             />
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div className="form-group">
//               <label for="formFileSm" class="form-label">
//                 Add Files
//               </label>
//               <input
//                 class="form-control form-control-sm"
//                 id="formFileSm"
//                 type="file"
//               />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div class="checkbox-list">
//               <label class="checkbox">
//                 <input type="checkbox" name="Checkboxes1" />
//                 <span></span>
//                 Recurring Increament
//               </label>
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Recursion Team
//               </label>
//               <ReactSelectSm className="" options={[]} />
//             </div>
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="primary">Save</Button>
//         <Button variant="secondary" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
