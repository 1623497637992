import React from 'react';
import { MdClose } from "react-icons/md"
import { Button, Modal } from 'react-bootstrap';

function AddIncomeModal(props) {
    const { setModalShow } = props;
    const projectOptions = [
        {
            name: "Covis",
        },
        {
            name: "Domy",
        },
        {
            name: "Redhanded",
        },
        {
            name: "RehabFlow",
        },
        {
            name: "Alfacare",
        },
        {
            name: "BreeHealth",
        },
    ];
    const incomeOptions = [
        {
            name: "Recurrent",
        },
        {
            name: "unfront",
        },
        {
            name: "Milestone",
        },
        {
            name: "other",
        },
    ];
    const clientOptions = [
        {
            name: "Jeff Moye",
        },
        {
            name: "William Chris",
        },
    ];
    const statusOptions = [
        {
            name: "Paid",
        },
        {
            name: "unPaid",
        },
    ];
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="d-flex justify-content-between mb-5">
                    <h2>Add Income</h2>
                    <MdClose onClick={() => setModalShow(false)} className="align-self-center cursor-pointer" size={30} />
                </div>
                <hr />
                <div className="row mt-7 mb-5">
                    <div className="col-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Project
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Project
                                </option>

                                {projectOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Income Type
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Income Type
                                </option>

                                {incomeOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Client
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Client
                                </option>

                                {clientOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Amount
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='$0,000,000' />
                        </div>
                    </div>
                    <div className="col-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Income Description
                            </label>
                            <textarea class="form-control add-form-input-textarea" id="exampleTextarea" rows="3"></textarea>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Status
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Status
                                </option>

                                {statusOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Due Date
                            </label>
                            <input class="form-control add-form-input-class" type="date" id="example-date-input" />
                        </div>
                    </div>
                    <div className="col-12 mb-sm-3">
                    </div>
                    <div className="col-12 mt-5">
                        <button
                            type="button"
                            className="generic-gradient-button w-100"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddIncomeModal