import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import { ConfirmationModal, Loader, Notification } from "../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSitemap,
  faLongArrowAltDown,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { Badge, Button, Card, Col, Pagination, Row } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsArrowRight, BsPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
// import { PATH } from "../../config";
import DataTable from "react-data-table-component";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
// import { useAuth } from "../../Navigation/Auth/ProvideAuth";
// import {
//   activeOrganization,
//   deleteOrganization,
//   getOrganizationList,
// } from "../../redux/actions/organiaztionAction";
// import { ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import AddOrganizationModal from "./components/addOrganizationModal";
import EditOrganizationModal from "./components/editOrganizationModal";
// import { Loader } from "components/util-components/Loader";
import { ConfirmationModal } from "../../../_metronic/layout/components/ConfirmationModal";
// import { ConfirmationModal } from "components/util-components/ConfirmationModal";

export default function Organization() {
  let organization = useSelector((state) => state.organization);
  let dispatch = useDispatch();
  // let auth = useAuth();
  // let token = auth.user.token;
  // useEffect(() => {
  //   let finalData = {
  //     userId: auth.user.userId,
  //   };
  //   dispatch(getOrganizationList(finalData, token));
  // }, [dispatch]);

  function filterOrganization(data) {
    // if (data.length > 2 || data.length == "") {
    //   let finalData = {
    //     userId: auth.user.userId,
    //     search: data,
    //   };
    //   dispatch(getOrganizationList(finalData, token));
    // }
  }
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const [open, setOpen] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const editHandleClose = () => setEditShow(false);
  const editHandleShow = () => setEditShow(true);
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationShow, setOrganizationShow] = useState(false);
  const organizationHandleClose = () => setOrganizationShow(false);
  const organizationHandleShow = () => setOrganizationShow(true);

  // function removeOrganization(organizationId) {
  //   dispatch(deleteOrganization(organizationId, token));
  // }

  const customStyles = {
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        backgroundColor: " rgba(245, 245, 250, 0.4);",
      },
    },
    pagination: {
      pageButtonsStyle: {
        cursor: "no-drop",
        color: "#fbfbfb",
        opacity: "0.75",
        backgroundColor: "#eee",
      },
    },
  };
  const columns = [
    {
      name: "Organization Name",
      selector: (row) => row.organizationName,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => GetProjectStatus(row),
      left: true,
    },

    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div className="mr-3 cursor">
                <FiEdit
                  onClick={() => {
                    editHandleShow();
                    setOrganizationId(row);
                  }}
                  size={17}
                  style={{ color: "#5E81F4" }}
                />
              </div>
              {/* <div>
                <RiDeleteBin6Line
                  className="ml-2 cursor"
                  onClick={() => {
                    setOpen(true);
                    setOrganizationId(row.organizationId);
                  }}
                  size={20}
                  style={{ color: "#E54545" }}
                />
              </div> */}
            </div>
          </>
        );
      },
      sortable: false,
      allowOverflow: true,
      width: "100px",
      center: true,
    },
  ];
  const Product = [
    {
      isActive: true,
      userName: "MubashirHussan",
      email: "Mubashir@getnada.com",

      organizationName: "h organization",

      photo: null,
    },
    {
      isActive: false,
      userName: "MubashirHussan",
      email: "Mubashir@getnada.com",
      organizationId: 105,
      organizationName: "testing",

      photo: null,
    },
    {
      isActive: true,
      userName: "MubashirHussan",
      email: "Mubashir@getnada.com",
      organizationId: 101,
      organizationName: "Shafique Org",

      photo: null,
    },
    {
      isActive: true,
      userName: "MubashirHussan",
      email: "Mubashir@getnada.com",

      organizationName: "salman Org",

      photo: null,
    },
    {
      isActive: false,
      userName: "MubashirHussan",
      email: "Mubashir@getnada.com",
      organizationId: 105,
      organizationName: "Hamad Org",

      photo: null,
    },
    {
      isActive: true,
      userName: "MubashirHussan",
      email: "Mubashir@getnada.com",
      organizationId: 101,
      organizationName: "Hussan ORG",

      photo: null,
    },
    {
      isActive: true,
      userName: "MubashirHussan",
      email: "Mubashir@getnada.com",

      organizationName: "xev organization",

      photo: null,
    },
    {
      isActive: false,
      userName: "MubashirHussan",
      email: "Mubashir@getnada.com",
      organizationId: 105,
      organizationName: "Usman org",

      photo: null,
    },
    {
      isActive: true,
      userName: "MubashirHussan",
      email: "Mubashir@getnada.com",
      organizationId: 101,
      organizationName: "Abid ORG",

      photo: null,
    },
  ];
  useEffect(() => {
    document.title = "Organization | Vision Collab";
  }, []);
  return (
    <>
      {/* <ToastContainer /> */}
      <div className="col-md-12 no-padding organization-screen">
        <div className="Home-background  pb-3">
          <section className="content-header px-0 pt-1">
            <div className="container-fluid section-top-header no-padding">
              <div className="d-flex bd-highlight d-flex py-2 justify-content-between flex-wrap align-items-center">
                <div className="p-2 bd-highlight">
                  <h1>{"Organization"}</h1>
                </div>
                <div className="mr-2">
                  <Button
                    style={{
                      background:
                        "linear-gradient(90deg, #009EFF -26.47%, #00F38D 127.21%)",
                    }}
                    onClick={() => {
                      organizationHandleShow();
                    }}
                    className="px-3 py-2"
                  >
                    Add Organization
                  </Button>
                </div>
              </div>
            </div>
          </section>
          <React.Fragment>
            <Card className="border-0 h-100 mx-2">
              <Card.Body className="px-0 pb-0 ">
                <Row>
                  <Col lg={12}>
                    <div className="d-flex flex-wrap justify-content-between align-items-center pb-3 px-4">
                      <h6 className="generic-heading-level-2 generic-theme-color mb-2 mr-2">
                        List of Organizations
                      </h6>
                      <div className="d-flex align-items-center responsive-field">
                        <IoSearchOutline className="search-icon" size={16} />
                        <input
                          type="title"
                          className="form-control align-input-field"
                          id="title"
                          placeholder="Search Organization"
                          name="title"
                          onChange={(e) => filterOrganization(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="mb-2 table-responsive">
                  <DataTable
                    className="dataTables_wrapper"
                    columns={columns}
                    data={Product}
                    customStyles={customStyles}
                    highlightOnHover
                    responsive
                    striped
                    keyField="taskId"
                    pagination={true}
                  />
                </div>

                <ConfirmationModal
                  // removeFunc={() => removeOrganization(organizationId)}
                  show={open}
                  onHide={() => setOpen(false)}
                  heading={"Delete Organization"}
                  title={"this Organization"}
                />
              </Card.Body>
            </Card>
            <AddOrganizationModal
              organizationHandleClose={organizationHandleClose}
              organizationShow={organizationShow}
            />

            <EditOrganizationModal
              editHandleClose={editHandleClose}
              editShow={editShow}
              organizationId={organizationId}
            />
          </React.Fragment>
        </div>
      </div>
    </>
  );
}
export const GetProjectStatus = (row) => {
  console.log("row", row);
  // let statBadge = "";
  // if (row.isActive) {
  //   statBadge = 1;
  // } else {
  //   statBadge = 2;
  // }
  // const [statusBadge, setStatusBadge] = useState(statBadge);
  let [cookie, setCookie, removeCookie] = useCookies([""]);
  let dispatch = useDispatch();
  // let auth = useAuth();
  // let token = auth.user.token;
  function organizationStatus(status) {
    // let organizationStatus = "";
    // if (status === 1) {
    //   organizationStatus = true;
    // } else {
    //   organizationStatus = false;
    // }
    // let data = {
    //   organizationId: row?.organizationId,
    //   userId: auth.user.userId,
    //   status: organizationStatus,
    // };
    // dispatch(activeOrganization(data, moveToNext));
  }

  // async function moveToNext(res) {
  //   if (res.data.status === 200) {
  //     if (row.organizationId === auth.user.organizationId) {
  //       // if ((row.organizationId === res.data.data.organizationId)) {
  //       let data = auth.user;
  //       data = {
  //         ...data,
  //         organizationId: res.data.data.organizationId,
  //         organization: res.data.data.organizationName,
  //       };
  //       window.location.reload(true);
  //       removeCookie("user", { path: "/organization" });
  //       await setCookie("user", data, { path: "/organization" });
  //       window.location.reload(false);
  //     }
  //   }
  //   let finalData = {
  //     userId: auth.user.userId,
  //   };
  //   dispatch(getOrganizationList(finalData, token));
  //   Notification(
  //     res.data.message,
  //     res.data.status === 200 ? "success" : "error"
  //   );
  // }

  return (
    <>
      {row.isActive === true ? (
        <Badge pill className={`py-0 pass_badge`}>
          <select
            // defaultValue={active}
            className="border-0 bg-transparent"
            onChange={(e) => {
              // setStatusBadge(parseInt(e.target.value));
              organizationStatus(parseInt(e.target.value));
              // organizationStatus();
            }}
          >
            <option className="text-black-50" value={1}>
              Active
            </option>

            <option className="text-black-50" value={2}>
              Inactive
            </option>
          </select>
        </Badge>
      ) : (
        <Badge pill className={`py-0 fail_badge`}>
          <select
            // defaultValue={active}
            className="border-0 bg-transparent"
            onChange={(e) => {
              // setStatusBadge(parseInt(e.target.value));
              organizationStatus(parseInt(e.target.value));
            }}
          >
            <option className="text-black-50" value={2}>
              Inactive
            </option>

            <option className="text-black-50" value={1}>
              Active
            </option>
          </select>
        </Badge>
      )}
    </>
  );
};

// export const GetStatusLabel = (value) => {
//   const [statusBadge, setStatusBadge] = useState(value);
//   return (
//     <>
//       {value === 1 && (
//         <Badge
//           pill
//           className={`py-0
//            ${
//              statusBadge == 1
//                ? "pass_badge"
//                : statusBadge == 2
//                ? "fail_badge"
//                : "NA_badge"
//            }`}
//         >
//           <select
//             className="border-0 bg-transparent"
//             onChange={(e) => {
//               setStatusBadge(e.target.value);
//             }}
//           >
//             <option value={1} className="text-black-50">
//               Active
//             </option>
//             <option value={3} className="text-black-50">
//               Inactive
//             </option>
//           </select>
//         </Badge>
//       )}
//       {value === 2 && (
//         <Badge
//           pill
//           className={`py-0
//            ${
//              statusBadge == 1
//                ? "pass_badge"
//                : statusBadge == 2
//                ? "fail_badge"
//                : "NA_badge"
//            }`}
//         >
//           <select
//             className="border-0 bg-transparent"
//             onChange={(e) => {
//               setStatusBadge(e.target.value);
//             }}
//           >
//             <option value={2} className="text-black-50">
//               Fail
//             </option>
//             <option value={1} className="text-black-50">
//               Pass{" "}
//             </option>
//             <option value={3} className="text-black-50">
//               N/A
//             </option>
//           </select>
//         </Badge>
//       )}
//       {value === 3 && (
//         <Badge
//           pill
//           className={`py-0
//            ${
//              statusBadge == 1
//                ? "pass_badge"
//                : statusBadge == 2
//                ? "fail_badge"
//                : "NA_badge"
//            }`}
//         >
//           <select
//             className="border-0 bg-transparent"
//             onChange={(e) => {
//               setStatusBadge(e.target.value);
//             }}
//           >
//             <option value={3} className="text-black-50">
//               N/A
//             </option>
//             <option value={1} className="text-black-50">
//               Pass
//             </option>
//             <option value={2} className="text-black-50">
//               Fail
//             </option>
//           </select>
//         </Badge>
//       )}
//     </>
//   );
// };
