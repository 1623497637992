import React, { useMemo } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import Sidebar from "./aside/Sidebar";
import UpdatedSideBar from "./AppLayout/components/UpdatedSideBar";
import UpdatedHeader from "./AppLayout/components/UpdatedHeader";
import PageContent from "./AppLayout/components/PageContent";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";

// export function Layout({ children }) {
//   const uiService = useHtmlClassService();
//   // Layout settings (cssClasses/cssAttributes)
//   const layoutProps = useMemo(() => {
//     return {
//       layoutConfig: uiService.config,
//       selfLayout: objectPath.get(uiService.config, "self.layout"),
//       asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
//       subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
//       desktopHeaderDisplay: objectPath.get(
//         uiService.config,
//         "header.self.fixed.desktop"
//       ),
//       contentCssClasses: uiService.getClasses("content", true),
//       contentContainerClasses: uiService.getClasses("content_container", true),
//       contentExtended: objectPath.get(uiService.config, "content.extended"),
//     };
//   }, [uiService]);

//   return layoutProps.selfLayout !== "blank" ? (
//     <>
//       {/*begin::Main*/}
//       <HeaderMobile />
//       <div className="d-flex flex-column flex-root">
//         {/*begin::Page*/}
//         <div className="d-flex flex-row flex-column-fluid page">
//           {layoutProps.asideDisplay && <Aside />}
//           {/*begin::Wrapper*/}
//           <div
//             className="d-flex flex-column flex-row-fluid wrapper"
//             id="kt_wrapper"
//           >
//             <Header />
//             {/*begin::Content*/}
//             <div
//               id="kt_content"
//               className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid pt-3 pb-3`}
//             >
//               {layoutProps.subheaderDisplay && <SubHeader />}
//               {/*begin::Entry*/}
//               {!layoutProps.contentExtended && (
//                 <div className="d-flex flex-column-fluid">
//                   {/*begin::Container*/}
//                   <div className={layoutProps.contentContainerClasses}>
//                     {children}
//                   </div>
//                   {/*end::Container*/}
//                 </div>
//               )}

//               {layoutProps.contentExtended && { children }}
//               {/*end::Entry*/}
//             </div>
//             {/*end::Content*/}
//             <Footer />
//           </div>
//           {/*end::Wrapper*/}
//         </div>
//         {/*end::Page*/}
//       </div>
//       <QuickUser />
//       <QuickPanel />
//       <ScrollTop />
//       {/*end::Main*/}
//       <LayoutInit />
//     </>
//   ) : (
//     // BLANK LAYOUT
//     <div className="d-flex flex-column flex-root">{children}</div>
//   );
// }
export function Layout({ children }) {
  return (
    <>
    <div className="new-app-layout">
      <div className="container-fluid px-3">
        <div className="row flex-nowrap">
          <UpdatedSideBar />
          <div className="content p-0 w-100">
            <UpdatedHeader />
            <div className="p-8 " style={{backgroundColor:"#f1f5f9"}}>
            <PageContent>{children}</PageContent>
            </div>
            
            {/* <UpdatedFooter /> */}
            <SessionExpireModal />
          </div>
        </div>
      </div>
    </div>
      {/* <Sidebar />
      <div className="wrapper-div">
        {children}
      </div> */}
    </>
  );
}
function SessionExpireModal() {
  // const dispatch = useDispatch();
  // const { sessionExpire } = useSelector((state) => state.user);
  // console.log("sessionExpire", sessionExpire);
  // const [cookies, remove] = useCookies(["user"]);

  const reLogin = () => {
    // window.localStorage.removeItem("pmsTokenExpiry");
    // remove("user");
    // dispatch(success(USER_REQUEST.SET_SESSION_EXPIRE_STATE, false));
  };

  return (
    <Modal
      size="sm"
      className="session-out-modal"
      centered
      // show={sessionExpire}
    >
      <Modal.Body closeButton className="text-center mt-3">
        <h4>
          Session Expired
          <br />
          <br />
          <span className="mt-2">
            Your current session has been expired, please login again to
            conitnue
          </span>
        </h4>
        <Button
          size="sm"
          className="mt-3"
          variant="primary"
          onClick={() => reLogin()}
        >
          Re-Login
        </Button>
      </Modal.Body>
    </Modal>
  );
}