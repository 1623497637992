import React from "react";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as GoIcons from "react-icons/go";
import * as MdIcons from "react-icons/md";
import * as ImIcons from "react-icons/im";
import * as BiIcons from "react-icons/bi";
import { HiOutlineUsers } from "react-icons/hi";
import { BsCashStack } from "react-icons/bs";
import { FcDepartment } from "react-icons/fc";
import { FiMonitor, FiSettings } from "react-icons/fi";
import { GiBassetHoundHead, GiExpense, GiReceiveMoney } from "react-icons/gi";
import { IMAGES } from "../../../../_assets/sass/images";
const PMS_PATH = 'https://pms.visioncollab.com';
const HRM_PATH = 'https://hrm.visioncollab.com/app';
const VC_TRACKER_PATH = 'https://tracker.visioncollab.com';
const FMS_PATH = '';
export const AdminSidebar = [
  // {
  //   title: "Dashboards",
  //   image: IMAGES.APPLOGO,
  //   iconClosed: <BsIcons.BsArrowLeftShort />,
  //   iconOpened: <BsIcons.BsArrowDownShort />,
  //   path: "https://pms.visioncollab.com/dashboard/ceo",
  // },
  // PMS List
  {
    title: "project management",
    image: IMAGES.APPLOGO,
    iconClosed: <BsIcons.BsArrowLeftShort />,
    iconOpened: <BsIcons.BsArrowDownShort />,
    path: "#",
    subNav: [
      {
        title: "Dashboard",
        path: `${PMS_PATH}/Dashboard/ceo`,
        icon: <AiIcons.AiOutlineDashboard />,
      },
      {
        title: "Projects",
        path: `${PMS_PATH}/projects`,
        icon: <RiIcons.RiProjectorFill />,
      },
      {
        title: "Workload",
        path: `${PMS_PATH}/workload`,
        icon: <FaIcons.FaBalanceScale />,
      },
      {
        // title: "Todo",
        title: "Day Planner",
        path: `${PMS_PATH}/ToDo`,
        icon: <BsIcons.BsListUl />,
      },
      {
        title: "Video Call",
        path: `${PMS_PATH}/VideoLinks`,
        icon: <BsIcons.BsCameraVideo />,
      },
      {
        title: "Team Scheduler",
        path: `${PMS_PATH}/team-scheduler`,
        icon: <GoIcons.GoDash />,
      },
      
      {
        title: "Human Resource",
        icon: <AiIcons.AiOutlineHome />,
        iconClosed: <BsIcons.BsArrowLeftShort />,
        iconOpened: <BsIcons.BsArrowDownShort />,
        path: "#",
        subNav: [
          {
            title: "Employees",
            path: `${PMS_PATH}/employeelist`,
            icon: <BsIcons.BsPeople />,
          },
          {
            title: "Assets",
            path: `${PMS_PATH}/assets`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Assets Allocation",
            path: `${PMS_PATH}/assets-allocation`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Daily Attendence",
            path: `${PMS_PATH}/daily-attendance`,
            icon: <AiIcons.AiFillCheckSquare />,
          },
          {
            title: "Attendence",
            path: `${PMS_PATH}/attendance`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Leave Request",
            path: `${PMS_PATH}/leave-request`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Users",
            path: `${PMS_PATH}/users`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Departments",
            path: `${PMS_PATH}/departments`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Late Coming",
            path: `${PMS_PATH}/late-coming`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Teams",
            path: `${PMS_PATH}/teams`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Suggestions",
            path: `${PMS_PATH}/suggestions`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Holidays",
            path: `${PMS_PATH}/holidays`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Events",
            path: `${PMS_PATH}/event`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "File",
            path: `${PMS_PATH}/files`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "File Category",
            path: `${PMS_PATH}/file_category`,
            icon: <GoIcons.GoDash />,
          },
        ],
      },
      {
        title: "Finance",
        icon: <FaIcons.FaHandHoldingUsd />,
        iconClosed: <BsIcons.BsArrowLeftShort />,
        iconOpened: <BsIcons.BsArrowDownShort />,
        subNav: [

          {
            title: "Dashboard",
            path: `${PMS_PATH}/finance-dashboard`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Billable Hours",
            path: `${PMS_PATH}/billablehours`,
            icon: <GoIcons.GoDash />,
            iconClosed: <BsIcons.BsArrowLeftShort />,
            iconOpened: <BsIcons.BsArrowDownShort />,
          },
          {
            title: "Create Invoice",
            path: `${PMS_PATH}/newinvoice`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Invoices",
            path: `${PMS_PATH}/invoicelist`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Income",
            path: `${PMS_PATH}/income`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Provident Fund",
            path: `${PMS_PATH}/provident-funds`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Expense",
            path: `${PMS_PATH}/expenses`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Salaries",
            path: `${PMS_PATH}/salary`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Loans",
            path: `${PMS_PATH}/loans`,
            icon: <GoIcons.GoDash />,
          },
          {
            title: "Cash Category",
            path: `${PMS_PATH}/cashcategory`,
            icon: <GoIcons.GoDash />,
          },
        ],
      },
      {
        title: "Settings",
        icon: <MdIcons.MdSettings />,
        iconClosed: <BsIcons.BsArrowLeftShort />,
        iconOpened: <BsIcons.BsArrowDownShort />,
        subNav: [
          {
            title: "Trash",
            path: `${PMS_PATH}/trash`,
            icon: <BsIcons.BsTrashFill />,
          },
          {
            title: "User Role",
            path: `${PMS_PATH}/user_roles`,
            icon: <FaIcons.FaUserAlt />,
          },
          {
            title: "Manage Menus",
            path: `${PMS_PATH}/ManageMenu`,
            icon: <BiIcons.BiFoodMenu />,
          },
          {
            title: "Assign Role Based Menus",
            path: `${PMS_PATH}/rolebasedmenus`,
            icon: <FaIcons.FaUserAlt />,
          },
          {
            title: "Organizations",
            path: `${PMS_PATH}/organization`,
            icon: <GoIcons.GoOrganization />,
          },
          {
            title: "New User",
            path: `${PMS_PATH}/Manage-User`,
            icon: <FaIcons.FaUserAlt />,
          },
          {
            title: "Clients",
            path: `${PMS_PATH}/client`,
            icon: <FaIcons.FaUserAlt />,
          },
          {
            title: "Project Settings",
            path: `${PMS_PATH}/projectsettings`,
            icon: <MdIcons.MdSettings />,
          },
        ],
      },
    ]
  },
  // HRM List
  {
    title: "Human Resources",
    image: IMAGES.APPLOGO,
    iconClosed: <BsIcons.BsArrowLeftShort />,
    iconOpened: <BsIcons.BsArrowDownShort />,
    path: "#",
    subNav: [
      {
        title: "Dashboard",
        path: `${HRM_PATH}/Dashboard`,
        icon: <AiIcons.AiOutlineDashboard />,
      },
      {
        title: "Hiring",
        path: `${HRM_PATH}/hiring`,
        icon: <HiOutlineUsers />,
      },
      {
        title: "Applicants",
        path: `${HRM_PATH}/applicants`,
        icon: <HiOutlineUsers />,
      },
      {
        title: "HIRING",
        icon: <AiIcons.AiOutlineHome />,
        iconClosed: <BsIcons.BsArrowLeftShort />,
        iconOpened: <BsIcons.BsArrowDownShort />,
        path: "#",
        subNav: [
          {
            title: "Recruitment",
            icon: <AiIcons.AiOutlineHome />,
            iconClosed: <BsIcons.BsArrowLeftShort />,
            iconOpened: <BsIcons.BsArrowDownShort />,
            path: "#",
            subNav: [
              {
                title: "Recruitment Dashboard",
                path: `${HRM_PATH}/recruitment/Dashboard`,
                icon: <AiIcons.AiOutlineDashboard />,
              },
              {
                title: "Jobs",
                path: `${HRM_PATH}/recruitment/jobs/listing`,
                icon: <FaIcons.FaUserTie />,
              },
              {
                title: "Candidates",
                path: `${HRM_PATH}/recruitment/candidates/listing`,
                icon: <HiOutlineUsers />,
              },
            ]
          },
          {
            title: "Employee",
            path: `${HRM_PATH}/people`,
            icon: <FaIcons.FaUsers />,
          },
          {
            title: "My Info",
            path: `${HRM_PATH}/myInfo`,
            icon: <ImIcons.ImInfo />,
          },
          {
            title: "Assets",
            path: `${HRM_PATH}/assets`,
            icon: <GiBassetHoundHead />,
          },
        ]
      },
      {
        title: "ATTENDANCE",
        icon: <HiOutlineUsers />,
        iconClosed: <BsIcons.BsArrowLeftShort />,
        iconOpened: <BsIcons.BsArrowDownShort />,
        path: "#",
        subNav: [
          {
            title: "Attendance",
            icon: <HiOutlineUsers />,
            iconClosed: <BsIcons.BsArrowLeftShort />,
            iconOpened: <BsIcons.BsArrowDownShort />,
            path: "#",
            subNav: [
              {
                title: "Attendance Dashboard",
                path: `${HRM_PATH}/attendance/AttendanceHistory/AttendanceDashboard`,
                icon: <AiIcons.AiOutlineDashboard />,
              },
              {
                title: "Daily Attendance",
                path: `${HRM_PATH}/attendance/AttendanceHistory/DailyAttendance`,
                icon: <HiOutlineUsers />,
              },
              {
                title: "Leave Request",
                path: `${HRM_PATH}/attendance/AttendanceHistory/LeaveRequest`,
                icon: <HiOutlineUsers />,
              },
            ]
          },
          {
            title: "Events",
            path: `${HRM_PATH}/events`,
            icon: <MdIcons.MdEventNote />,
          },
          {
            title: "Departments",
            path: `${HRM_PATH}/departments`,
            icon: <FcDepartment />,
          },
          {
            title: "Teams",
            path: `${HRM_PATH}/teams`,
            icon: <FaIcons.FaUsers />,
          },
        ]
      },
    ],
  },
  // VC Tracker List
  {
    title: "Resource Tracking",
    image: IMAGES.APPLOGO,
    iconClosed: <BsIcons.BsArrowLeftShort />,
    iconOpened: <BsIcons.BsArrowDownShort />,
    path: "#",
    subNav: [
      {
        title: "Real Time Insights",
        path: `${VC_TRACKER_PATH}/Ceo/Dashboard`,
        icon: <AiIcons.AiOutlineDashboard />,
      },
      {
        title: "Employees",
        path: `${VC_TRACKER_PATH}/Admin/Employee`,
        icon: <FaIcons.FaUsers />,
      },
      {
        title: "Screen Shots",
        path: `${VC_TRACKER_PATH}/Admin/ScreenShots`,
        icon: <BsIcons.BsImage />,
      },
      {
        title: "Apps And Websites",
        path: `${VC_TRACKER_PATH}/Admin/TopAppsAndWebsites`,
        icon: <FiMonitor />,
      },
      {
        title: "Activities",
        path: `${VC_TRACKER_PATH}/Admin/Activities`,
        icon: <BsIcons.BsMenuButtonWideFill />,
      },
      {
        title: "Settings",
        path: `${VC_TRACKER_PATH}/Admin/Settings`,
        icon: <FiSettings />,
      },
    ],
  },
  // FMS List
  {
    title: "Financial System",
    image: IMAGES.APPLOGO,
    iconClosed: <BsIcons.BsArrowLeftShort />,
    iconOpened: <BsIcons.BsArrowDownShort />,
    path: "#",
    subNav: [
      {
        title: "Dashboard",
        path: `${FMS_PATH}/dashboard`,
        icon: <AiIcons.AiOutlineDashboard />,
      },
      {
        title: "Income",
        path: `${FMS_PATH}/income`,
        icon: <GiReceiveMoney />,
      },
      {
        title: "Expenses",
        path: `${FMS_PATH}/expense`,
        icon: <GiExpense />,
      },
      {
        title: "Pay Codes",
        path: `${FMS_PATH}/pay-code`,
        icon: <GiReceiveMoney />,
      },
      // {
      //   title: "Invoices",
      //   path: `${FMS_PATH}/invoices`,
      //   icon: <FaIcons.FaFileInvoice />,
      // },
      {
        title: "Loan",
        path: `${FMS_PATH}/loan`,
        icon: <GiReceiveMoney />,
      },
      {
        title: "Salaries",
        path: `${FMS_PATH}/salaries`,
        icon: <BsCashStack />,
      },
      {
        title: "Provident Fund",
        path: `${FMS_PATH}/providentfund`,
        icon: <GiReceiveMoney />,
      },
      {
        title: "Cash Category",
        path: `${FMS_PATH}/cashcategory`,
        icon: <BsCashStack />,
      },
      {
        title: "Billable Hours",
        path: `${FMS_PATH}/billablehours`,
        icon: <RiIcons.Ri24HoursFill />,
      },
      {
        title: "Monthly Employee Compensations",
        path: `${FMS_PATH}/monthlyemployeecompensation`,
        icon: <BsCashStack />,
      },
    ],
  },
  {
    title: "Payroll",
    image: IMAGES.APPLOGO,
    iconClosed: <BsIcons.BsArrowLeftShort />,
    iconOpened: <BsIcons.BsArrowDownShort />,
    path: "#",
    subNav: [
      {
        title: "Invoices",
        path: `${FMS_PATH}/invoices`,
        icon: <FaIcons.FaFileInvoice />,
      },
    ],
  },
  {
    title: "File Cloud",
    path: `${PMS_PATH}/ReportFiles`,
    image: IMAGES.APPLOGO,
  },
];
