import React, { Fragment, useEffect } from "react";
import { useTable, useExpanded, usePagination } from "react-table";
import { Button, Table as BsTable } from "react-bootstrap";
import {
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";
// import { preLoaderGif } from "../../_metronic/assets";

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    // <BsTable striped bordered hover size="sm" {...getTableProps()}>
    <BsTable hover bordered {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </BsTable>
  );
}

function SimpleTable({ columns, data, customClass }) {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <BsTable
        hover
        bordered
        striped
        responsive
        {...getTableProps()}
        className={`simple-table-custom ${customClass && customClass}`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.length > 0 ? (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={visibleColumns.length} className="bg-white">
                <div className="d-flex justify-content-center align-items-center font-weight-boldest font-size-h4 h-100px">
                  No Record Found
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </BsTable>

      <div className="pagination w-100 customPagination justify-content-between px-8 py-5">
        <span>
          <span className="mr-3 d-flex align-items-center">
            Show
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="mx-2 custom-select custom-select-sm form-control form-control-sm"
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            entries
          </span>
        </span>

        <div>
          <span className="mr-3">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <Button
            size="sm"
            variant="light"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FaAngleDoubleLeft />
          </Button>{" "}
          <Button
            size="sm"
            variant="light"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <FaAngleLeft />
          </Button>{" "}
          <Button
            size="sm"
            variant="light"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <FaAngleRight />
          </Button>{" "}
          <Button
            size="sm"
            variant="light"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <FaAngleDoubleRight />
          </Button>{" "}
        </div>
      </div>
    </>
  );
}

function ExtendableTable({ columns, data, renderRowSubComponent }) {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useExpanded,
    usePagination
  );

  // Render the UI for your table
  return (
    // <BsTable striped bordered hover size="sm" {...getTableProps()}>
    <>
      <BsTable hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  width={column.width ? column.width : null}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
                {row.isExpanded && renderRowSubComponent ? (
                  <tr>
                    <td colSpan={visibleColumns.length} className="bg-white">
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </BsTable>

      <div className="pagination w-100 customPagination justify-content-between">
        <span>
          <span className="mr-3 d-flex align-items-center">
            Show
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="mx-2 custom-select custom-select-sm form-control form-control-sm"
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            entries
          </span>
        </span>

        <div>
          <span className="mr-3">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <Button
            size="sm"
            variant="light"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FaAngleDoubleLeft />
          </Button>{" "}
          <Button
            size="sm"
            variant="light"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <FaAngleLeft />
          </Button>{" "}
          <Button
            size="sm"
            variant="light"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <FaAngleRight />
          </Button>{" "}
          <Button
            size="sm"
            variant="light"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <FaAngleDoubleRight />
          </Button>{" "}
        </div>
      </div>
    </>
  );
}

function ServerSideTable({
  columns,
  data,
  renderRowSubComponent,
  totalCount,
  isLoading,
  //Custom State
  config,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: config.state.tableState.PageNumber - 1,
        pageSize: config.state.tableState.PageSize,
      },
      manualPagination: true,
      pageCount: totalCount
        ? Math.ceil(totalCount / config.state.tableState.PageSize)
        : null,
      autoResetPage: true,
    },
    useExpanded,
    usePagination
  );

  useEffect(() => {
    let newObj = {
      ...config.state,
      tableState: {
        ...config.state.tableState,
        PageNumber: pageIndex + 1,
      },
    };
    config.setState(newObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    gotoPage(0);
  }, [gotoPage, config.state.filters]);

  // Render the UI for your table
  return (
    // <BsTable striped bordered hover size="sm" {...getTableProps()}>
    <>
      <BsTable hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  width={column.width ? column.width : null}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 && !isLoading ? (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={String(i) + String(i)}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && renderRowSubComponent ? (
                    <tr>
                      <td colSpan={visibleColumns.length} className="bg-white">
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </Fragment>
              );
            })
          ) : page.length === 0 && !isLoading ? (
            <tr>
              <td colSpan={visibleColumns.length} className="bg-white">
                <div className="d-flex justify-content-center align-items-center font-weight-boldest font-size-h4 h-400px">
                  No Record Found
                </div>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={visibleColumns.length} className="bg-white">
                <div className="d-flex justify-content-center align-items-center font-weight-boldest font-size-h4 h-400px">
                  {/* <img src={preLoaderGif} alt="Loading...." /> */}
                  Loading...
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </BsTable>

      <div className="pagination w-100 customPagination justify-content-between">
        <span>
          <span className="mr-3 d-flex align-items-center">
            Show
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));

                let newObj = {
                  ...config.state,
                  tableState: {
                    ...config.state.tableState,
                    PageSize: Number(e.target.value),
                  },
                };
                config.setState(newObj);
              }}
              className="mx-2 custom-select custom-select-sm form-control form-control-sm"
              disabled={isLoading}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            entries
          </span>
        </span>

        <div>
          <span className="mr-3">
            Page{" "}
            <strong>
              {page.length === 0 ? 0 : pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
            (Total: {totalCount})
          </span>
          <Button
            size="sm"
            variant="light"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage || isLoading}
          >
            <FaAngleDoubleLeft />
          </Button>{" "}
          <Button
            size="sm"
            variant="light"
            onClick={() => previousPage()}
            disabled={!canPreviousPage || isLoading}
          >
            <FaAngleLeft />
          </Button>{" "}
          <Button
            size="sm"
            variant="light"
            onClick={() => nextPage()}
            disabled={!canNextPage || isLoading}
          >
            <FaAngleRight />
          </Button>{" "}
          <Button
            size="sm"
            variant="light"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage || isLoading}
          >
            <FaAngleDoubleRight />
          </Button>{" "}
        </div>
      </div>
    </>
  );
}

export { Table, SimpleTable, ExtendableTable, ServerSideTable };
