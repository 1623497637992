import React from "react";
// import { IMAGES } from "../../../images";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useEffect, useState } from "react";
// import {
//   AddScheduleMeeting,
//   EditScheduleMeeting,
// } from "../../../../components/VideoCall";

function PageContent({ children }) {
  return (
    <div className="child-custom-layout">
      <div>
        <div className=" p-0 w-100 h-100">
          <div className="site-layout-background">{children}</div>
        </div>
      </div>
    </div>
  );
}
export default PageContent;
