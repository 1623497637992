import React, { useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import JsonToForm from "./AutoForm";
import { useFormik } from "formik";
import { addPayCode } from "../../../redux/actions/payCodeActions";
import { useDispatch } from "react-redux";
import ReactDatePicker from "react-datepicker";
const Compensation = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      payCodeType: null,
      payCode: "",
    },
    onSubmit: (values) => {
      values = { ...values, payCodeType: +values.payCodeType };
      console.log(values);
      dispatch(addPayCode(values));
      handleClose();
    },
  });
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Header className="px-5 py-2 p-0 d-flex justify-content-between align-items-center">
              <Card.Title className="font-weight-boldest my-5">
                Employee Monthly Compensations
              </Card.Title>
              <Form.Group
                className="ml-auto mr-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select Month</Form.Label>
                <ReactDatePicker
                  id="startDate"
                  placeholderText="Start Date"
                  size="sm"
                  className="form-control form-control-sm"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </Form.Group>
              <Button size="sm" onClick={handleShow}>
                Add Pay Code
              </Button>
            </Card.Header>
            <Card.Body className="py-5">
              <Row>
                <Col lg="12">
                  <JsonToForm />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <AddPayCode show={show} handleClose={handleClose} formik={formik} />
    </>
  );
};

export default Compensation;

const AddPayCode = ({ show, handleClose, formik }) => (
  <Modal show={show} onHide={handleClose}>
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>Pay Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg="12">
            <Form.Group controlId="companyName">
              <Form.Label>Pay code type</Form.Label>
              <Form.Control
                as="select"
                name="payCodeType"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.payCodeType}
              >
                <option value="1">Benefit</option>
                <option value="2">Deduction</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg="12">
            <Form.Group controlId="companyName" className="mb-0">
              <Form.Label>Pay code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Pay code"
                name="payCode"
                onChange={formik.handleChange}
                value={formik.values.payCode}
              />
            </Form.Group>
          </Col>
          <Col className="mt-3">
            <label>
              <input type="checkbox" /> Make Installments
            </label>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" type="submit">
          Save Changes
        </Button>
      </Modal.Footer>
    </Form>
  </Modal>
);
