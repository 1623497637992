export const getMonthsSelectList = () => {
    let listOfMonths = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"]

    let selectList = listOfMonths.map((item, key) => {
        return {
            label: item,
            value: key + 1
        }
    })
    return selectList
}


export const getCurrentMonthInSelect = () => {
    return { label: new Date().toLocaleString('default', { month: 'long' }), value: new Date().getMonth() + 1 }
}