import React from "react";
import { Card, Col, Row, Button, Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PATH } from "../../config";
import { SimpleTable } from "../../genericComponents";
import { FaEdit } from "react-icons/fa";
function EmployeeCompensations() {
  const cashColumn = [
    {
      Header: "Employee Name",
      accessor: "name",
    },
    {
      Header: "Action",
      accessor: "hello",
    },
  ];

  const cashData = [
    {
      id: 1,
      name: "Obaid",
      hello: <DropdownThreeDotComponent />,
    },
    {
      id: 2,
      name: "Fahad",
      hello: <DropdownThreeDotComponent />,
    },
  ];

  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Header className="px-5 p-0 d-flex justify-content-between align-items-center">
              <Card.Title className="font-weight-boldest my-5">
                Employee Compensations
              </Card.Title>
              <Link to={PATH.ADDCOMPENSATION}>
                <Button
                  type="button"
                  size="sm"
                  className="submit_btn fms-income"
                >
                  Add
                </Button>
              </Link>
            </Card.Header>

            <Row>
              <Col>
                <Card.Body>
                  <SimpleTable
                    columns={cashColumn}
                    data={cashData}
                    customClass="cashCategoryTable"
                  />
                </Card.Body>
              </Col>
              <Col lg="6" className="m-8">
                <Card className="border tabdata">
                  <Card.Body className="p-0">
                    <Col lg="12">
                      <Row>
                        <Col lg="6" className="pr-0">
                          <Table>
                            <thead>
                              <td>Benefits</td>
                            </thead>
                            <tbody className="border-right text-success">
                              <tr>
                                <td>Allowance</td>
                                <td>5000</td>
                              </tr>
                              <tr>
                                <td>Allowance</td>
                                <td>5000</td>
                              </tr>
                              <tr>
                                <td>Allowance</td>
                                <td>5000</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col lg="6" className="pr-0">
                          <Table>
                            <thead>
                              <td>Deductions</td>
                            </thead>
                            <tbody className="text-danger">
                              <tr>
                                <td>Loan</td>
                                <td>10000</td>
                              </tr>
                              <tr>
                                <td>Loan</td>
                                <td>10000</td>
                              </tr>
                              <tr>
                                <td>Loan</td>
                                <td>10000</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default EmployeeCompensations;
function DropdownThreeDotComponent() {
  return (
    <>
      <Dropdown className="day-cell-dropdown bg-transperant fms-button-three-dots">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="dropdown-button"
        ></Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="px-2 d-flex align-items-center"
            value="sadsdfsd"
          >
            <FaEdit className="font-size-h3 mx-2" /> Edit
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
