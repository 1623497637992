import React, { useState } from "react";

import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Form, Table } from "react-bootstrap";

import NotificationTable from "./NotificationTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotificationSettings = () => {
  //   const [playSound, setPlaySound] = useState(false);
  const notificationList = [
    {
      name: "Overall Notifications",
      email: false,
      sms: false,
      push: false,
    },
    // {
    //   name: "New items on card you follow",
    //   email: false,
    //   sms: false,
    //   push: false,
    // },
    // {
    //   name: "Comment added in the card you follow",
    //   email: false,
    //   sms: false,
    //   push: false,
    // },
    // {
    //   name: "You've been mentioned in a comment",
    //   email: false,
    //   sms: false,
    //   push: false,
    // },
    // {
    //   name: "New task assigned",
    //   email: false,
    //   sms: false,
    //   push: false,
    // },
    {
      name: "Invite to a new project",
      email: false,
      sms: false,
      push: false,
    },
  ];

  return (
    <section className="content-section">
      <HeaderSection
        titleIcon={faClipboardList}
        title={"Notification Settings"}
      />
      <div className="">
        <div className="row mt-3">
          <div className="col-12">
            <Card>
              <Card.Body>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      //   label="Check this switch"
                    />
                  </div>

                  {/* <div className="col-10">
                      {
                        "Play a sound when receiving new notifications and messages on my desktop"
                      }
                    </div>
                    <div className="col-2">
                    
                      {playSound ? (
                        <FontAwesomeIcon
                          icon={faToggleOn}
                          onClick={() => setPlaySound(!playSound)}
                          size="lg"
                        
                          className="float-right"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faToggleOff}
                          onClick={() => setPlaySound(!playSound)}
                          size="lg"
                          className="float-right"
                        />
                      )}
                    </div> */}
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <Table size="sm" responsive="lg" bordered>
                      <thead>
                        <tr>
                          <th className="w-75"></th>
                          <th className="text-center">{"EMAIL"}</th>
                          <th className="text-center">{"SMS"}</th>
                          <th className="text-center">{"PUSH"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notificationList &&
                          notificationList.map((list, idx) => {
                            return (
                              <NotificationTable key={idx} listItem={list} />
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-end">
                      <Button
                        style={{
                          background:
                            "linear-gradient(90deg, #009EFF -26.47%, #00F38D 127.21%)",
                        }}
                        className="py-2"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotificationSettings;

const HeaderSection = (props) => {
  return (
    <div>
      <section className="content-header">
        <div className="section-top-header container-fluid no-padding">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12 d-flex align-items-end">
              <h1>
                {props.titleIcon && <FontAwesomeIcon icon={props.titleIcon} />}{" "}
                {props.title}
              </h1>
            </div>
            {(props.icon || props.btnTitle) && (
              <div className="d-flex justify-content-end align-items-end text-right pb-2 expense-helper-btn col-md-8 col-sm-8 col-xs-12">
                <Button
                  bsPrefix="vc-generic-btn"
                  size="sm"
                  type="button"
                  onClick={() => {
                    props.handleClick(true);
                  }}
                  className="py-1 px-2 rounded f-14"
                >
                  {props.icon && (
                    <FontAwesomeIcon
                      className="header-title-icon-left"
                      icon={props.icon}
                    />
                  )}
                  {props.btnTitle}
                </Button>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
