import React from "react";
import { Card, Breadcrumb } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const AddSalary = () => {
    const history = useHistory();
    const employeeOptions = [
        {
            name: "Employee 1",
        },
        {
            name: "Employee 2",
        },
    ];
    const paymentOptions = [
        {
            name: "Credit card",
        },
        {
            name: "Debit card",
        },
        {
            name: "Cash",
        },
        {
            name: "Chaque",
        },
    ];
    return (
        <>
            <div>
                <Breadcrumb className="employee-salary-breadcrumb">
                    <Breadcrumb.Item onClick={() => history.push("/salaries")}>Finance</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => history.push("/salaries")}>
                        Employee Salary
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Add Salary</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Card className="finance-card">
                <Card.Header>
                    <h4 style={{ color: "#009eff" }}>Candidate Detail</h4>
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Select Employee
                                </label>
                                <select
                                    name="designationOptions"
                                    className="add-form-select"
                                >
                                    <option value="" selected disabled>
                                        Select Employee
                                    </option>

                                    {employeeOptions?.map((list, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={list.name}
                                                selected={
                                                    list.name === "" ? true : false
                                                }
                                            >
                                                {list.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Gross Salary
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Arrears
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Payment Via
                                </label>
                                <select
                                    name="designationOptions"
                                    className="add-form-select"
                                >
                                    <option value="" selected disabled>
                                        Select Payment
                                    </option>

                                    {paymentOptions?.map((list, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={list.name}
                                                selected={
                                                    list.name === "" ? true : false
                                                }
                                            >
                                                {list.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <h4 className="mt-6" style={{ color: "#009eff" }}>Earnings</h4>
                    <hr />
                    <div className="row mt-8">
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Basic
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    DA (40%)
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    HRA (15%)
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Conveyance
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Allowance
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Medical Allowance
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Bonus
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Total
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                    </div>
                    <h4 className="mt-6" style={{ color: "#009eff" }}>Deductions</h4>
                    <hr />
                    <div className="row mt-8">
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    TDS
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    ESI
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    PF
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Leave
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Income Tax
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Labour Welfare
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Advance from Cash
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Total
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                    </div>
                    <h4 className="mt-6" style={{ color: "#009eff" }}>Others</h4>
                    <hr />
                    <div className="row mt-8">
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Taxable Salary
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Working Hours
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Extra Work
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                        <div className="col-3 mb-sm-3">
                            <div className="select_disable mr-2 mb-2">
                                <label className="form-label add-form-label" htmlFor="catalog">
                                    Net Salary
                                </label>
                                <input type="number" className='add-form-input-class form-control' placeholder='Type Here' />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-8">
                        <button
                            className="employee-salary-back-btn mr-4"
                        >
                            Back
                        </button>
                        <button
                            type="button"
                            className="generic-gradient-button"
                        >
                            Submit
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default AddSalary;