import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { HiDuplicate } from "react-icons/hi";
import { IoSearchOutline } from "react-icons/io5";
import { BiDownload, BiUpload } from "react-icons/bi";
import { AiFillDelete, AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import { Card, Row, Col, Dropdown, Modal, Form, InputGroup } from "react-bootstrap";
import { SimpleTable } from "../../genericComponents/Tables";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { PATH } from "../../config";
import AddIncomeModal from "./AddIncomeModal";
import { BsThreeDotsVertical } from "react-icons/bs";

const Income = () => {
  const incomeColumn = [
    {
      Header: "Income Description",
      accessor: "incomeDescription",
    },
    {
      Header: "Projects",
      accessor: "projects",
    },
    {
      Header: "Income Type",
      accessor: "incomeType",
      Cell: (props) => {
        return (
          <div className={props?.value === "Recurrent" ? "text-warning" : props?.value === "Milestone Payment" ? "text-success" : "text-primary"}>
            {props?.value}
          </div>
        )
      }

    },
    {
      Header: "Client",
      accessor: "client",
    },
    {
      Header: "Amount (USD)",
      accessor: "amount",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];
  const incomeData = [
    {
      id: 0,
      incomeDescription: "developer needed for creating...",
      incomeType: "Recurrent",
      projects: "Web Development",
      client: "Ali",
      amount: "290,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 1,
      incomeDescription: "designer creating custom website in Telemedicine",
      incomeType: "Upfront",
      projects: "Custom Website",
      client: "Zain",
      amount: "310,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 2,
      incomeDescription: "seller needed for the purpose of shop details...",
      incomeType: "Milestone Payment",
      projects: "Wordpress Site",
      client: "Abdullah",
      amount: "373,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 3,
      incomeDescription: "Customer needed for the purpose of bank...",
      incomeType: "Recurrent",
      projects: "Web Development",
      client: "Waqas",
      amount: "187,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 4,
      incomeDescription: "developer needed for creating...",
      incomeType: "Upfront",
      projects: "Custom Website",
      client: "Ali",
      amount: "798,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 5,
      incomeDescription: "designer creating custom website in Telemedicine",
      incomeType: "Milestone Payment",
      projects: "Wordpress Site",
      client: "Zain",
      amount: "112,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 6,
      incomeDescription: "seller needed for the purpose of shop details...",
      incomeType: "Recurrent",
      projects: "Web Development",
      client: "Abdullah",
      amount: "310,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 7,
      incomeDescription: "Customer needed for the purpose of bank...",
      incomeType: "Upfront",
      projects: "Custom Website",
      client: "Waqas",
      amount: "872,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 8,
      incomeDescription: "developer needed for creating...",
      incomeType: "Milestone Payment",
      projects: "Wordpress Site",
      client: "Ali",
      amount: "111,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
    {
      id: 9,
      incomeDescription: "designer creating custom website in Telemedicine",
      incomeType: "Recurrent",
      projects: "Web Development",
      client: "Zain",
      amount: "31,000",
      date: "5-7-2021",
      status: <DropdownComponent />,
      actions: <DropdownThreeDotComponent />,
    },
  ]
  const clientOptions = [
    {
      name: "Client 1",
    },
    {
      name: "Client 2",
    },
  ];
  const projectOptions = [
    {
      name: "Project 1",
    },
    {
      name: "Project 2",
    },
  ];
  const incomeOptions = [
    {
      name: "Income 1",
    },
    {
      name: "Income 2",
    },
  ];
  const statusOptions = [
    {
      name: "Status 1",
    },
    {
      name: "Status 2",
    },
  ];
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12 d-flex flex-wrap justify-content-between mb-8">
          <h2 className="align-self-center">Income</h2>
          <div className="Fms-income d-flex flex-wrap justify-content-end">
            <Link to={PATH.EXPENSE}>
              <button type="button" className="generic-gradient-button-inverted mb-2">
                View Expense
              </button>
            </Link>
            <button
              type="button"
              className="generic-gradient-button ml-3 mb-2"
              onClick={() => setModalShow(true)}
            >
              Add Income
            </button>
          </div>
        </div>
      </div>

      <Row>
        <Col xs="12" sm="6" md="6" lg="3" xl="3">
          <div className="card card-custom gutter-b text-white" style={{ background: "#00F38D" }}>
            <div className="card-body d-flex justify-content-between finance-tiles">
              <div>
                <h3 className="subtext">
                  Received
                </h3>
                <h3 className="usd-subtext">
                  (USD)
                </h3>
              </div>
              <div className="align-self-center">
                <h1 className="maintext">
                  51.2M
                </h1>
              </div>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="3" xl="3">
          <div className="card card-custom gutter-b text-white" style={{ background: "#009EFF" }}>
            <div className="card-body d-flex justify-content-between finance-tiles">
              <div>
                <h3 className="subtext">
                  Receivables
                </h3>
                <h3 className="usd-subtext">
                  (USD)
                </h3>
              </div>
              <div className="align-self-center">
                <h1 className="maintext">
                  91.9M
                </h1>
              </div>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="3" xl="3">
          <div className="card card-custom gutter-b text-white" style={{ background: "#E54545" }}>
            <div className="card-body d-flex justify-content-between finance-tiles">
              <div>
                <h3 className="subtext">
                  Pending
                </h3>
                <h3 className="usd-subtext">
                  (USD)
                </h3>
              </div>
              <div className="align-self-center">
                <h1 className="maintext">
                  40.7M
                </h1>
              </div>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="3" xl="3">
          <div className="card card-custom showing-card gutter-b text-white" style={{ background: "#B2B5B8" }}>
            <div className="card-body finance-tiles">
              <h5 className="mb-2 showing-name">Showing</h5>
              <h2 className="showing-date">20 Feb - 20 Mar</h2>
            </div>
          </div>
        </Col>
      </Row>

      <Card className="finance-card">
        <Card.Body className="p-0">
          <div className="d-flex flex-wrap justify-content-between align-items-center py-8 px-6">
            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
              <InputGroup className="mb-3">
                <InputGroup.Text className="search-field-input-group" id="basic-addon1"><IoSearchOutline /></InputGroup.Text>
                <Form.Control
                  className="search-field-input-class"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="d-flex flex-wrap justify-content-end">
              <div className="d-flex flex-wrap justify-content-end">
                <div className="select_disable mr-4 mb-2 align-self-center">
                  <div className="d-flex cursor-pointer">
                    <BiDownload className="align-self-center mr-1" />
                    <p className="no-wrap">Import from Excel</p>
                  </div>
                </div>
                <div className="select_disable mr-4 mb-2 align-self-center">
                  <div className="d-flex cursor-pointer">
                    <BiUpload className="align-self-center mr-1" />
                    <p className="no-wrap">Export to Excel</p>
                  </div>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Client
                    </option>

                    {clientOptions?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Project
                    </option>

                    {projectOptions?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Income Type
                    </option>

                    {incomeOptions?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Status
                    </option>

                    {statusOptions?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <Form.Group className="mb-3">
                    <input type="date" placeholder="Date" value="01-11-23" className="form-control finance-select-datepicker" />
                  </Form.Group>
                </div>
              </div>
              <div className="mr-2 mb-2">
                <button className="btn project_clr_btn cursor-pointer">
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 px-0">
            <SimpleTable columns={incomeColumn} data={incomeData} />
          </div>
        </Card.Body>
      </Card>

      <AddIncomeModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Income;

function DropdownComponent(props) {
  console.log("props", props)
  const [customizeAmount, setCustomizeAmount] = useState("Paid");
  function Notification() {
    toast.success("Your Status has been changed", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <React-fragment>
      <Dropdown className="table-dropdown-status">
        <Dropdown.Toggle
          // variant="success"
          id="dropdown-basic"
          className={`btn-block price-modal-dropdown ${customizeAmount === "Paid" ? "fms-paid" : "fms-pending"
            }`}
        >
          {customizeAmount}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Paid");
              Notification();
            }}
          >
            {"Paid "}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Pending");
              Notification();
            }}
          >
            {" Pending"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React-fragment>
  );
}
function DropdownThreeDotComponent() {
  const Swal = require("sweetalert2");
  function ThreeDotDropdown() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#01362c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Dropdown className="day-cell-dropdown text-right bg-transperant fms-button-three-dots">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="dropdown-button"
        >
          <BsThreeDotsVertical color="#666666" size={24} /></Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={ThreeDotDropdown}
          >
            <HiDuplicate className="font-size-h3 mx-2" /> Duplicate
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
            onClick={() => setModalShow(true)}
          >
            <FaEdit className="font-size-h3 mx-2" /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center text-danger"
            onClick={ThreeDotDropdown}
          >
            <AiFillDelete className="font-size-h3 mx-2" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <EditIncomeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <AddIncomeModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

// function EditIncomeModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           EDIT INCOME
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="row mb-5">
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Income Description
//               </label>
//               <input
//                 type="text"
//                 class="form-control h-35px"
//                 placeholder="Enter Description"
//               />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Income Type
//               </label>
//               <ReactSelectSm options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Client
//               </label>
//               <ReactSelectSm options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Project
//               </label>
//               <ReactSelectSm options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 class="form-control h-35px"
//                 placeholder="Enter Amount"
//               />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Select Status
//               </label>
//               <ReactSelectSm className="" options={[]} />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3">
//             <label className="form-label" htmlFor="startDate">
//               Start Date
//             </label>
//             <ReactDatePicker
//               id="endDate"
//               placeholderText="mm/dd/yyyy"
//               className="w-365px  form-control form-control-sm"
//               selected={new Date()}
//             />
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div className="form-group">
//               <label for="formFileSm" class="form-label">
//                 Add Files
//               </label>
//               <input
//                 class="form-control form-control-sm"
//                 id="formFileSm"
//                 type="file"
//               />
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div class="checkbox-list">
//               <label class="checkbox">
//                 <input type="checkbox" name="Checkboxes1" />
//                 <span></span>
//                 Recurring Increament
//               </label>
//             </div>
//           </div>
//           <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
//             <div className="form-group">
//               <label className="form-label" htmlFor="catalog">
//                 Recursion Team
//               </label>
//               <ReactSelectSm className="" options={[]} />
//             </div>
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="primary">Save</Button>
//         <Button variant="secondary" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
