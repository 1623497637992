import React, { useState } from "react";
import { Dropdown, Nav, Button } from "react-bootstrap";
import { faCaretDown, faExclamationCircle, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddModal from "./AddModal";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { SignOut } from "./SignOut";
import { IMAGES } from "../../../../_assets/sass/images";
import Navbar from 'react-bootstrap/Navbar'
const NavBar = ({ showSidebar, getInitials }) => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <Navbar className="navbar-wrapper" collapseOnSelect expand="lg">
        <Navbar.Brand>
          <img className="logo-img" src={IMAGES.APPLOGO} />
        </Navbar.Brand>

        <div>
          <Button name="menuBtn" variant="link" bsPrefix="menu-btn" onClick={showSidebar}>
            {"Show Menu"}
          </Button>
        </div>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="justify-content-between align-items-center" id="responsive-navbar-nav">
          <Nav>
            <button className="navbar-xeven-btn" onClick={() => setShow(true)}>
              {"Xeven Solution "}
              <span>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </button>
            <AddModal modalOpen={show} handleClose={() => setShow(false)} />
          </Nav>

          {/* {
            history.location.pathname.startsWith("/Task/") ? <h4 className="m-0">{project.currentProject && project.currentProject.projectName}</h4> : ""
          } */}

          <Nav>
            {/* {
              history.location.pathname.startsWith("/Task/")
              &&
              <Button onClick={() => dispatch(request(TASK_REQUEST.OPEN_INVITE_MODAL))} variant="link" bsPrefix="menu-btn mr-3 px-2">
                {"Invite"}
              </Button>
            } */}
            <Dropdown className="align-self-center">
              <Dropdown.Toggle id="dropdown-custom-components" as={NotificationButton}>
                <FontAwesomeIcon
                  className="cursor-pointer mr-3"
                  icon={faBell}
                  style={{
                    fontSize: "24px"
                  }}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ minWidth: "23rem", left: "-23rem" }} className="no-padding" align="right">
                <NotificationSection />
              </Dropdown.Menu>
            </Dropdown>
            {/* {
              history.location.pathname.startsWith("/Task/")
              &&
              <Dropdown className="align-self-center">
                <NavDropdown
                  alignRight
                  title={<FontAwesomeIcon className="icon-nav" icon={faCog} />}
                  id="collasible-nav-dropdown">
                  <NavDropdown.Item onClick={() => openTaskBoardModal()}>
                    {"Create Board "}
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">{"Builds"}</NavDropdown.Item>
                  <NavDropdown.Item href="#">{"Edit Project"}</NavDropdown.Item>
                  <NavDropdown.Item href="#">{"Move to Trash"}</NavDropdown.Item>
                </NavDropdown>
              </Dropdown>
            } */}
            <Dropdown className="align-self-center">
              <Dropdown.Toggle id="dropdown-custom-components" as={NotificationButton}>
                <div className="userPanel pr-0">
                  <div className="UserImage">
                    <p className="initial-name">{getInitials}</p>
                  </div>
                  <div className="ml-2">
                    <FontAwesomeIcon className="text-dark" icon={faCaretDown} />
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ minWidth: "18rem", left: "-18rem" }} align="right">
                <MyProfile getInitials={getInitials} />
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

const NotificationButton = React.forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    {children}
  </a>
));

const NotificationSection = (props) => {
  const notifications = [
    {
      title: "Notification 1",
      date: new Date().toDateString()
    },
    {
      title: "Notification 1",
      date: new Date().toDateString()
    },
    {
      title: "Notification 1",
      date: new Date().toDateString()
    },
    {
      title: "Notification 1",
      date: new Date().toDateString()
    },
    {
      title: "Notification 1",
      date: new Date().toDateString()
    },
    {
      title: "Notification 1",
      date: new Date().toDateString()
    },
    {
      title: "Notification 1",
      date: new Date().toDateString()
    },
    {
      title: "Notification 1",
      date: new Date().toDateString()
    },
  ];

  return (
    <>
      <div className="notification-panel-header">
        <div className="row">
          <div className="col-12">
            {"365 Notifications"}
          </div>
        </div>
      </div>
      <div className="container-fluid notification-panel-body">
        <div className="w-100">
          {
            notifications
            &&
            notifications.map((notification, idx) => {
              return (
                <div className="cursor-pointer notif-hover" key={idx}>
                  <SingleNotification notification={notification} />
                  {
                    idx !== notifications.length - 1
                    &&
                    <hr className="my-1" />
                  }
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="notification-panel-footer">
        <div className="row">
          <div className="col-12 text-right">
            {/* <Link to={PATH.NOTIFICATIONSETTINGS}> */}
            <button className="vc-generic-btn font-200">
              {"Notification Settings"}
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  )
}

const SingleNotification = (props) => {
  return (
    <>
      <div className="row mb-1">
        <div className="col-1 align-self-center">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="primary-color"
            style={{
              fontSize: '22px'
            }}
          />
        </div>
        <div className="col-11">
          <p className="no-margin">
            {props.notification.title}
          </p>
          <p className="no-margin font-200 text-muted text-right">
            {props.notification.date}
          </p>
        </div>
      </div>
    </>
  )
}

const MyProfile = (props) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="UserImage my-profile-img">
        <p className="initial-name">{props.getInitials}</p>
      </div>
      <div className="border-bottom w-100 text-center">
        <h5 className="text-dark mt-3">
          {`${'Irfan'} ${'Malik'}`}
        </h5>
        <p className="f-14">
          {`Member Since Jan 31, 2020`}
        </p>
      </div>
      <div className="d-flex justify-content-around w-100 mt-3">
        <Button variant="light" className="border">
          {"Profile"}
        </Button>
        <Button variant="light" className="border">
          <SignOut className="text-dark" />
        </Button>
      </div>
    </div>
  )
}


export default NavBar;
