import React, { useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { Card, Form, InputGroup } from "react-bootstrap";
import { SimpleTable } from "../../genericComponents/Tables";
import { ToastContainer } from "react-toastify";
import { FiEdit } from "react-icons/fi";
import AddCategoryModal from "./AddCategoryModal";

const CashCategory = () => {

  const cashCategoryColumn = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (props) => <DropdownThreeDotComponent />,
    },
  ];
  const cashCategoryData = [
    {
      id: 0,
      name: "Salman Khan",
    },
    {
      id: 1,
      name: "Ajay Devgan",
    },
    {
      id: 2,
      name: "Kriti Kharbanda",
    },
    {
      id: 3,
      name: "Fardeen Khan",
    },
    {
      id: 4,
      name: "Travis Scott",
    },
    {
      id: 5,
      name: "MG Kelly",
    },
    {
      id: 6,
      name: "Katie Holmes",
    },
    {
      id: 7,
      name: "Charlize Theron",
    },
    {
      id: 8,
      name: "Venessa",
    },
    {
      id: 9,
      name: "Fergie",
    },
  ]
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12 d-flex justify-content-between mb-8">
          <h2 className="align-self-center">Cash Category</h2>
          <div className="Fms-income">
            <button
              type="button"
              className="generic-gradient-button"
              onClick={() => setModalShow(true)}
            >
              Add Category
            </button>
          </div>
        </div>
      </div>

      <Card className="finance-card">
        <Card.Body className="p-0">
          <div className="d-flex flex-wrap justify-content-between align-items-center py-8 px-6">
            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
              <InputGroup className="mb-3">
                <InputGroup.Text className="search-field-input-group" id="basic-addon1"><IoSearchOutline /></InputGroup.Text>
                <Form.Control
                  className="search-field-input-class"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
          </div>
          <div className="col-lg-12 mb-4 px-0">
            <SimpleTable columns={cashCategoryColumn} data={cashCategoryData} />
          </div>
        </Card.Body>
      </Card>
      <AddCategoryModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default CashCategory;

function DropdownThreeDotComponent() {
  const Swal = require("sweetalert2");
  function ThreeDotDropdown() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#01362c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="d-flex justify-content-end p-4">
      <div className="mr-4">
        <FiEdit
          size={18}
          onClick={() => setModalShow(true)}
          className="text-primary cursor-pointer" />
      </div>
      <div className="">
        <FaRegTrashAlt
          size={18}
          className="text-danger cursor-pointer" />
      </div>
      {/* <EditPayCodeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <AddCategoryModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}
