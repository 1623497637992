import React from "react";

const EditSalary = () => {

    return (
        <>
            <h3>hello</h3>
        </>
    );
};

export default EditSalary;