import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import {
  Form,
  TextField,
  SelectField,
  LoanBlock,
  SubmitButton,
} from "./FormElements";

function JsonToForm(props) {
  const [formData, setFormData] = useState({});
  const [validationSchema, setValidationSchema] = useState({});

  const formSchema = [
    {
      label: "Sallary",
      type: "text",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Allowance",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Medical",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Increment",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Employee Referral Bonus",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Over Time",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Performance Bonus",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Trainings",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Leave Benefits",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Project Incentive ",
      required: true,
      catalog: "benefits",
    },
    {
      type: "text",
      label: "Provident Fund",
      required: true,
      catalog: "deduction",
    },
    {
      type: "text",
      label: "Income Tax",
      required: true,
      catalog: "deduction",
    },
    {
      type: "text",
      label: "Eobi",
      required: true,
      catalog: "deduction",
    },
    {
      type: "text",
      label: "Others",
      required: true,
      catalog: "deduction",
    },
    {
      type: "text",
      label: "Loan",
      required: true,
      catalog: "deduction",
      // options: [
      //   {
      //     label: "Admin",
      //     value: "admin",
      //   },
      //   {
      //     label: "User",
      //     value: "user",
      //   },
      // ],
    },
    {
      type: "loanBlock",
      catalog: "deduction",
    },
  ];

  // const formSchema = {
  //   firstName: {
  //     type: "text",
  //     label: "First name",
  //     required: true,
  //     catalog: "benefits",
  //   },
  //   lastName: {
  //     type: "text",
  //     label: "Last name",
  //     required: true,
  //     catalog: "benefits",
  //   },
  //   city: {
  //     type: "text",
  //     label: "City",
  //     required: true,
  //     catalog: "benefits",
  //   },
  //   email: {
  //     type: "email",
  //     label: "Email",
  //     required: true,
  //     catalog: "deduction",
  //   },
  //   role: {
  //     type: "select",
  //     label: "Role",
  //     required: true,
  //     catalog: "deduction",
  //     options: [
  //       {
  //         label: "Admin",
  //         value: "admin",
  //       },
  //       {
  //         label: "User",
  //         value: "user",
  //       },
  //     ],
  //   },
  //   loanDetails: {
  //     type: "loanBlock",
  //     catalog: "deduction",
  //   },
  // };

  const initForm = (formSchema) => {
    let _formData = {};

    let _validationSchema = {};

    for (var key of Object.keys(formSchema)) {
      _formData[key] = "";

      if (formSchema[key].type === "text") {
        _validationSchema[key] = Yup.string();
      } else if (formSchema[key].type === "email") {
        _validationSchema[key] = Yup.string().email();
      } else if (formSchema[key].type === "select") {
        _validationSchema[key] = Yup.string().oneOf(
          formSchema[key].options.map((o) => o.value)
        );
      } else if (formSchema[key].type === "loanBlock") {
        _validationSchema[key] = Yup.object().shape({
          totalAmout: Yup.string(),
          dueDate: Yup.string(),
          makeInstallments: Yup.boolean(),
          noOfInstallments: Yup.number().max("50", "Max 50 only"),
          installments: Yup.array()
            .of(
              Yup.object().shape({
                amount: Yup.string()
                  .required("*Required")
                  .nullable(),
                date: Yup.string()
                  .required("*Required")
                  .nullable(),
              })
            )
            .nullable(),
        });
      }

      if (formSchema[key].required) {
        _validationSchema[key] = _validationSchema[key].required("Required");
      }
    }

    setFormData(_formData);
    setValidationSchema(Yup.object().shape({ ..._validationSchema }));
    console.log({ _validationSchema, _formData });
  };

  const getFormElement = (elementName, elementSchema) => {
    const props = {
      name: elementName,
      label: elementSchema.label,
      options: elementSchema.options,
    };

    if (elementSchema.type === "text" || elementSchema.type === "email") {
      return <TextField {...props} />;
    }

    if (elementSchema.type === "select") {
      return <SelectField {...props} />;
    }

    if (elementSchema.type === "loanBlock") {
      return <LoanBlock />;
    }
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    console.log(values);

    setSubmitting(false);
  };

  useEffect(() => {
    initForm(formSchema);
  }, []);

  return (
    <Form
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={onSubmit}
    >
      <Row>
        <Col lg="12">
          <h4 className="text-primary">Benefits</h4>
        </Col>
        {Object.keys(formSchema).map((key, ind) => {
          // console.log(formSchema[key]);
          if (formSchema[key].catalog !== "benefits") return;
          return getFormElement(key, formSchema[key]);
        })}

        {/* Start: Divider */}
        <Col lg="12">
          <div class="separator separator-dashed mb-7"></div>
        </Col>
        {/* End: Divider */}

        <Col lg="12">
          <h4 className="text-primary">Deduction</h4>
        </Col>

        {Object.keys(formSchema).map((key, ind) => {
          // console.log(formSchema[key]);
          if (formSchema[key].catalog !== "deduction") return;
          return getFormElement(key, formSchema[key]);
        })}

        <Col lg="12">
          <SubmitButton title="Submit" />
        </Col>
      </Row>
    </Form>
  );
}

export default JsonToForm;
