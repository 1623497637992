import React, { useState } from "react";
import { FaChevronDown, FaEdit } from "react-icons/fa";
import { HiDuplicate } from "react-icons/hi";
import { IoSearchOutline } from "react-icons/io5";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { Card, Row, Col, Button, Dropdown, Modal, Form, InputGroup } from "react-bootstrap";
import { SimpleTable } from "../../genericComponents/Tables";
import ReactDatePicker from "react-datepicker";
import { ReactSelectSm } from "../../genericComponents";
import { toast, ToastContainer } from "react-toastify";
import { BsThreeDotsVertical } from "react-icons/bs";

const BillableHours = () => {

  const billableColumns = [
    {
      Header: "Resources",
      accessor: "resources",
    },
    {
      Header: "Module",
      accessor: "module",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Task title",
      accessor: "task",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Estimated Hours",
      accessor: "estimatedHours",
    },
    {
      Header: "Logged Hours",
      accessor: "loggedHours",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];
  const billableData = [
    {
      id: 0,
      resources: "Salman Khan",
      module: "Database",
      type: "Bug",
      task: "UI Design",
      date: "24-04-23",
      estimatedHours: "3",
      loggedHours: "3",
      status: <DropdownComponent />,
    },
    {
      id: 1,
      resources: "Ajay Devgan",
      module: "QA",
      type: "Story",
      task: "API Design",
      date: "24-04-23",
      estimatedHours: "37",
      loggedHours: "31",
      status: <DropdownComponentUnpaid />,
    },
    {
      id: 2,
      resources: "Kriti Kharbanda",
      module: "UAT",
      type: "Task",
      task: "UI Design",
      date: "24-04-23",
      estimatedHours: "33",
      loggedHours: "34",
      status: <DropdownComponent />,
    },
    {
      id: 3,
      resources: "Fardeen Khan",
      module: "Database",
      type: "Bug",
      task: "APII Design",
      date: "24-04-23",
      estimatedHours: "73",
      loggedHours: "33",
      status: <DropdownComponentUnpaid />,
    },
    {
      id: 4,
      resources: "Travis Scott",
      module: "QA",
      type: "Story",
      task: "UI Design",
      date: "24-04-23",
      estimatedHours: "34",
      loggedHours: "63",
      status: <DropdownComponent />,
    },
    {
      id: 5,
      resources: "MG Kelly",
      module: "UAT",
      type: "Task",
      task: "API Design",
      date: "24-04-23",
      estimatedHours: "53",
      loggedHours: "63",
      status: <DropdownComponentUnpaid />,
    },
    {
      id: 6,
      resources: "Katie Holmes",
      module: "Database",
      type: "Bug",
      task: "UI Design",
      date: "24-04-23",
      estimatedHours: "23",
      loggedHours: "33",
      status: <DropdownComponent />,
    },
    {
      id: 7,
      resources: "Charlize Theron",
      module: "QA",
      type: "Story",
      task: "API Design",
      date: "24-04-23",
      estimatedHours: "30",
      loggedHours: "13",
      status: <DropdownComponentUnpaid />,
    },
    {
      id: 8,
      resources: "Venessa",
      module: "UAT",
      type: "Task",
      task: "UI Design",
      date: "24-04-23",
      estimatedHours: "3",
      loggedHours: "3",
      status: <DropdownComponent />,
    },
    {
      id: 9,
      resources: "Fergie",
      module: "UAT",
      type: "Story",
      task: "API Design",
      date: "24-04-23",
      estimatedHours: "80",
      loggedHours: "30",
      status: <DropdownComponentUnpaid />,
    },
    {
      id: 10,
      resources: "Ice Cube",
      module: "QA",
      type: "Bug",
      task: "UI Design",
      date: "24-04-23",
      estimatedHours: "80",
      loggedHours: "30",
      status: <DropdownComponent />,
    },
  ]
  const statusOptions = [
    {
      name: "Status 1",
    },
    {
      name: "Status 2",
    },
  ];
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12 d-flex flex-wrap justify-content-between mb-8">
          <h2 className="align-self-center">Billable Hours</h2>
          <div className="Fms-income">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="dropdown-gradient-btn">
                Select Project
                <span className="ml-5 header-span">
                  <FaChevronDown size={12} />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>DOMY</Dropdown.Item>
                <Dropdown.Item>RehabFlow</Dropdown.Item>
                <Dropdown.Item>COVIS</Dropdown.Item>
                <Dropdown.Item>Redhandes</Dropdown.Item>
                <Dropdown.Item>Redox</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <Row>
        <Col xs="12" sm="6" md="6" lg="4" xl="2">
          <div className="card card-custom gutter-b text-white" style={{ background: "#009EFF" }}>
            <div className="card-body billable-tile p-6">
              <h4 className="billable-name">
                Last 24 Hours
              </h4>
              <h1 className="mt-1 billable-time">
                5:39 h
              </h1>
              <p>
                Hasn't Worked Yet
              </p>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="4" xl="2">
          <div className="card card-custom gutter-b text-white" style={{ background: "#2AAC4E" }}>
            <div className="card-body billable-tile p-6">
              <h4 className="billable-name">
                This Week
              </h4>
              <h1 className="mt-1 billable-time">
                32:46 h
              </h1>
              <p>
                of 40hrs limit
              </p>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="4" xl="2" className="h-100">
          <div className="card card-custom gutter-b text-white" style={{ background: "#E54545" }}>
            <div className="card-body billable-tile p-6">
              <h4 className="billable-name">
                Last Week
              </h4>
              <h1 className="mt-1 mb-6 billable-time">
                30:58 h
              </h1>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="4" xl="2" className="h-100">
          <div className="card card-custom gutter-b text-white" style={{ background: "#B2B5B8" }}>
            <div className="card-body billable-tile p-6">
              <h4 className="billable-name">
                Since Start
              </h4>
              <h1 className="mt-1 mb-6 billable-time">
                5305:58 h
              </h1>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="4" xl="2" className="h-100">
          <div className="card card-custom gutter-b text-white" style={{ background: "#AD00FF" }}>
            <div className="card-body billable-tile p-6">
              <h4 className="billable-name">
                Paid Hours
              </h4>
              <h1 className="mt-1 mb-6 billable-time">
                4172:58 h
              </h1>
            </div>
          </div>
        </Col>

        <Col xs="12" sm="6" md="6" lg="4" xl="2" className="h-100">
          <div className="card card-custom gutter-b text-white" style={{ background: "#EE9208" }}>
            <div className="card-body billable-tile p-6">
              <h4 className="billable-name">
                Unpaid Hours
              </h4>
              <h1 className="mt-1 mb-6 billable-time">
                4172:58 h
              </h1>
            </div>
          </div>
        </Col>
      </Row>

      <Card className="finance-card">
        <Card.Body className="p-0">
          <div className="d-flex flex-wrap justify-content-between align-items-center py-8 px-6">
            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
              <InputGroup className="mb-3">
                <InputGroup.Text className="search-field-input-group" id="basic-addon1"><IoSearchOutline /></InputGroup.Text>
                <Form.Control
                  className="search-field-input-class"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="d-flex flex-wrap justify-content-end">
              <div className="d-flex flex-wrap justify-content-end">
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Status
                    </option>

                    {statusOptions?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <Form.Group className="mb-3">
                    <input type="date" placeholder="Date" value="01-11-23" className="form-control finance-select-datepicker" />
                  </Form.Group>
                </div>
              </div>
              <div className="mr-2 mb-2">
                <button className="btn project_clr_btn cursor-pointer">
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 px-0">
            <SimpleTable columns={billableColumns} data={billableData} />
          </div>
        </Card.Body>
      </Card>
      {/* <AddIncomeModal show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} /> */}
    </>
  );
};

export default BillableHours;

function DropdownComponent() {
  const [customizeAmount, setCustomizeAmount] = useState("Paid");
  function Notification() {
    toast.success("Your Status has been changed", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <React-fragment>
      <Dropdown className="table-dropdown-status">
        <Dropdown.Toggle
          // variant="success"
          id="dropdown-basic"
          className={`btn-block price-modal-dropdown ${customizeAmount === "Paid" ? "fms-paid" : "fms-pending"
            }`}
        >
          {customizeAmount}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Paid");
              Notification();
            }}
          >
            {"Paid "}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Pending");
              Notification();
            }}
          >
            {" Pending"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React-fragment>
  );
}

function DropdownComponentUnpaid() {
  const [customizeAmount, setCustomizeAmount] = useState("Unpaid");
  function Notification() {
    toast.success("Your Status has been changed", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <React-fragment>
      <Dropdown className="table-dropdown-status">
        <Dropdown.Toggle
          // variant="success"
          id="dropdown-basic"
          className={`btn-block price-modal-dropdown ${customizeAmount === "Unpaid" ? "fms-paid" : "fms-pending"
            }`}
        >
          {customizeAmount}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Paid");
              Notification();
            }}
          >
            {"Paid "}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCustomizeAmount("Pending");
              Notification();
            }}
          >
            {" Pending"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React-fragment>
  );
}
function DropdownThreeDotComponent() {
  const Swal = require("sweetalert2");
  function ThreeDotDropdown() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#01362c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Dropdown className="day-cell-dropdown text-right bg-transperant fms-button-three-dots">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="dropdown-button"
        >
          <BsThreeDotsVertical color="#666666" size={24} /></Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={ThreeDotDropdown}
          >
            <HiDuplicate className="font-size-h3 mx-2" /> Duplicate
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
            onClick={() => setModalShow(true)}
          >
            <FaEdit className="font-size-h3 mx-2" /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center text-danger"
            onClick={ThreeDotDropdown}
          >
            <AiFillDelete className="font-size-h3 mx-2" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <EditIncomeModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
function EditIncomeModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          EDIT INCOME
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-5">
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Income Description
              </label>
              <input
                type="text"
                class="form-control h-35px"
                placeholder="Enter Description"
              />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Select Income Type
              </label>
              <ReactSelectSm options={[]} />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Select Client
              </label>
              <ReactSelectSm options={[]} />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Select Project
              </label>
              <ReactSelectSm options={[]} />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Amount
              </label>
              <input
                type="number"
                class="form-control h-35px"
                placeholder="Enter Amount"
              />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Select Status
              </label>
              <ReactSelectSm className="" options={[]} />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3">
            <label className="form-label" htmlFor="startDate">
              Start Date
            </label>
            <ReactDatePicker
              id="endDate"
              placeholderText="mm/dd/yyyy"
              className="w-365px  form-control form-control-sm"
              selected={new Date()}
            />
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
            <div className="form-group">
              <label for="formFileSm" class="form-label">
                Add Files
              </label>
              <input
                class="form-control form-control-sm"
                id="formFileSm"
                type="file"
              />
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
            <div class="checkbox-list">
              <label class="checkbox">
                <input type="checkbox" name="Checkboxes1" />
                <span></span>
                Recurring Increament
              </label>
            </div>
          </div>
          <div className="col-xl-6  col-sm-12 mb-sm-3 FMS-income-search-btn">
            <div className="form-group">
              <label className="form-label" htmlFor="catalog">
                Recursion Team
              </label>
              <ReactSelectSm className="" options={[]} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary">Save</Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
