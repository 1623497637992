import axios from "axios";
import { API_PATH } from "../config";

export function getAllPayCodeApi() {
  return axios.get(API_PATH.PAYCODE_API.getAllPayCode);
}
export function addPayCodeApi(data) {
  return axios.post(API_PATH.PAYCODE_API.addPayCode, data);
}
export function updatePayCodeApi(data) {
  return axios.put(API_PATH.PAYCODE_API.updatePayCode, data);
}
export function deletePayCodeApi(data) {
  return axios.post(API_PATH.PAYCODE_API.deletePayCode, data);
}
