import React from 'react';
import { MdClose } from "react-icons/md"
import { Modal } from 'react-bootstrap';

function AddProvidentFundModal(props) {
    const { setModalShow } = props;
    const employeeOptions = [
        {
            name: "Select Employee 1",
        },
        {
            name: "Select Employee 2",
        },
    ];
    const providentFundOptions = [
        {
            name: "Provident Fund Type 1",
        },
        {
            name: "Provident Fund Type 2",
        },
    ];
    const statusOptions = [
        {
            name: "Status 1",
        },
        {
            name: "Status 2",
        },
    ];
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="d-flex justify-content-between mb-5">
                    <h2>Add Income</h2>
                    <MdClose onClick={() => setModalShow(false)} className="align-self-center cursor-pointer" size={30} />
                </div>
                <hr />
                <div className="row mt-7 mb-5">
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Select Employee
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Employee
                                </option>

                                {employeeOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Provident Fund Type
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Type
                                </option>

                                {providentFundOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Employee Share (Amount)
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='$0,000,000' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Company Share (Amount)
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='$0,000,000' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Employee Share (%)
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='%' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Company Share (%)
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='%' />
                        </div>
                    </div>
                    <div className="col-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Status
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Status
                                </option>

                                {statusOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <button
                            type="button"
                            className="generic-gradient-button w-100"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddProvidentFundModal