import React, { useState } from "react";
import {
  BsBell,
  BsCloudUpload,
  BsFillGrid3X3GapFill,
  BsGraphUp,
  BsJoystick,
  BsReply,
} from "react-icons/bs";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
// import { IMAGES } from "../../../images";
import {
  AiOutlineCaretLeft,
  AiOutlineCheckCircle,
  AiOutlineDollarCircle,
  AiOutlineHome,
  AiOutlineSetting,
  AiOutlineTeam,
  AiOutlineUser,
} from "react-icons/ai";
import { Button, Dropdown, Form, NavDropdown } from "react-bootstrap";
// import { getInitials } from "../../../genericAction";
// import { useAuth } from "../../../../Navigation/Auth/ProvideAuth";
import { useRef } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
// import { SignOut } from "../../SignOut";
// import { TASK_REQUEST, request } from "../../../../../redux/actions/utilities";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { PATH } from "../../../../config";
import { Link } from "react-router-dom";
import { MdOutlineLogout } from "react-icons/md";
import { IMAGES } from "../../../../_assets/sass/images";
import { SignOut } from "../SignOut";
import { PATH } from "../../../../../app/config";
import { VscGraphLine } from "react-icons/vsc";
import { FaCheckCircle } from "react-icons/fa";

function UpdatedSideBar() {
  const dispatch = useDispatch();
  let history = useHistory();
  console.log("history", history);
  const [clicked, setClicked] = useState(false);

  // const auth = useAuth();
  const purchasedSecRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const upDateStateAction = () => {
    setClicked(!clicked);
  };
  const handleClickOutside = (e) => {
    if (
      purchasedSecRef.current !== null &&
      !purchasedSecRef?.current?.contains(e.target)
    ) {
      setClicked(false);
    }
  };
  const NotificationButton = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  const goToPage = (link) => {
    window.location.href = link;
  };

  function getInitials(name) {
    let removeExtraSpaces = /\s{2,}/g;
    var words = name
      .replace(removeExtraSpaces, " ")
      .trim()
      .split(" ");
    var initials = "";
    words.forEach((word) => {
      initials += word[0].toUpperCase();
    });

    return initials;
  }

  // const openTaskBoardModal = () => {
  //   dispatch(request(TASK_REQUEST.OPEN_ADD_BOARD_MODAL));
  // };
  let authuseruserRoleId = "1";
  let params = useParams();
  // const { currentProject } = useSelector((state) => state.project);

  return (
    <div className={`px-sm-2 px-0 updated-sidebar maxWidth-sidebar`}>
      <div className="d-flex flex-column align-items-center justify-content-between pt-2 text-white h-100">
        <ul
          className="nav nav-pills flex-column align-items-center align-items-sm-center"
          id="menu"
        >
          <li className="mt-0">
            <img
              className="img-fluid app-logo-width"
              src={IMAGES.APPLOGO}
              alt=""
            />
          </li>
          <li
            className="mt-4 side-link"
            onClick={() => goToPage("https://covis.ai/home")}
          >
            {console.log(
              "history?.location?.pathname",
              history?.location?.pathname
            )}

            <AiOutlineHome className="sideNave-icon icons " size={20} />
          </li>
          <li
            className={`mt-3 position-relative-li side-link ${clicked &&
              "active"}`}
            onClick={() => {
              setClicked(!clicked);
            }}
          >
            {clicked ? (
              <>
                <BsFillGrid3X3GapFill
                  size={20}
                  className="sideNave-icon icons side-link custom-style-padding"
                />
                <AiOutlineCaretLeft className="sideNave-icon arrw-icon-styling" />
              </>
            ) : (
              <BsFillGrid3X3GapFill
                size={20}
                className="sideNave-icon icons"
                data-tut="reactour__logo"
              />
            )}
            {clicked && (
              <div
                className="sidebar-item-clicked bg-white test_this"
                ref={purchasedSecRef}
              >
                <div className="backdrop bg-white pb-5 px-5">
                  <span
                    style={{
                      color: "#6c757d",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    My Apps
                  </span>
                  <div className="d-flex justify-content-between mt-2">
                    <div
                      className="human-resourses p-1 font-size-10"
                      style={{ background: "#3EB5FE" }}
                      onClick={() => goToPage("https://covis.ai/dashboard/pm")}
                    >
                      <div className="p-2">
                        <img src={IMAGES.PROJECT_MANAGEMENT_ICON} alt="pmi" />
                        <br />
                      </div>
                      <span className="font-size-10 mb-2 text-white">
                        Project Management
                      </span>
                    </div>
                    <div
                      className="human-resourses font-size-10"
                      style={{ background: "#0AF090" }}
                      onClick={() =>
                        goToPage("https://hrm.visioncollab.com/app")
                      }
                    >
                      <div className="p-2">
                        <img src={IMAGES.HRMANAGER_ICON} alt="pmi" />
                        <br />
                      </div>
                      <span className="font-size-10 mb-2 ">
                        Human Resource Management
                      </span>
                    </div>
                    <div
                      className="human-resourses p-1 feedback-system font-size-10"
                      style={{ background: "#A27DC0" }}
                      onClick={() =>
                        goToPage("https://tracker.visioncollab.com")
                      }
                    >
                      <div className="p-2">
                        <img src={IMAGES.RESOURCE_TRACKER_ICON} alt="pmi" />
                        <br />
                      </div>
                      <span className="font-size-10 mb-2">
                        Resource Tracker
                      </span>
                    </div>
                  </div>

                  <div className="d-flex mt-5 justify-content-between">
                    <Link
                      to={PATH.DASHBOARD}
                      className="text-decoration-none text-white"
                    >
                      <div
                        className="human-resourses font-size-10 p-1"
                        style={{ background: "#FE8858" }}
                        onClick={() => {
                          setClicked(!clicked);
                        }}
                      >
                        <div className="p-2">
                          <img src={IMAGES.FINANCIAL_SYSTEM_ICON} alt="pmi" />
                          <br />
                        </div>
                        <span className="font-size-10 mb-2">
                          Financial System
                        </span>
                      </div>
                    </Link>
                    <div
                      className="human-resourses p-1 font-size-10"
                      style={{ background: "#FDD84F" }}
                      // onClick={() => {
                      //   history.push(PATH.REPORTFILES);
                      //   setClicked(false);
                      // }}
                      onClick={() => goToPage("https://covis.ai/ReportFiles")}
                    >
                      <div className="p-2">
                        <img src={IMAGES.FILE_CLOUD_ICON} alt="pmi" />
                        <br />
                      </div>
                      <span className="font-size-10 mb-2">File Cloud</span>
                    </div>
                    <div
                      className="human-resourses p-1 font-size-10"
                      style={{ background: "rgb(137 137 137)" }}
                      onClick={() => {
                        goToPage(
                          "https://businessmanagement.visioncollab.com/augmentation-dashboard"
                        );
                      }}
                    >
                      <div className="px-2 pt-2 pb-1">
                        <VscGraphLine size={33} />
                        <br />
                      </div>
                      <span className="font-size-10 mb-2  text-white">
                        Marketing Management
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li className="mt-3 side-link ">
            <Dropdown className="align-self-center project-options">
              <Dropdown.Toggle
                id="dropdown-custom-components"
                as={NotificationButton}
                className="side-link"
              >
                <BsBell
                  className={`icon-nav sideNave-icon icons side-link p-3`}
                  data-tut="reactour__copy"
                  size={18}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{ minWidth: "23rem" }}
                className="no-padding"
                align="right"
              >
                <NotificationSection />
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li
            onClick={() => history.push(PATH.PRICING)}
            className={`mt-3 side-link ${history?.location?.pathname ===
              PATH.PRICING && "active"}`}
          >
            <AiOutlineDollarCircle
              size={22}
              data-tut="reactour__goTo"
              className="sideNave-icon icons"
            />
          </li>
          <li className="mt-3 side-link">
            <Dropdown className="align-self-center project-options p-0 ">
              <NavDropdown
                className="new-setting-item m-0"
                alignRight
                title={
                  <AiOutlineSetting
                    className="sideNave-icon icons"
                    size={22}
                    data-tut="reactour__scroll"
                  />
                }
                id=""
              >
                <NavDropdown.Item onClick={() => history.push(PATH.TRASH)}>
                  Trash
                </NavDropdown.Item>
                {/* <NavDropdown.Item onClick={() => history.push(PATH.USER_ROLES)}>
                  User Role
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item
                        onClick={() => history.push(PATH.MANAGEMENU)}
                      >
                        Manage Menus
                      </NavDropdown.Item> */}
                {/* <NavDropdown.Item
                        onClick={() => history.push(PATH.ROLEBASEDMENUS)}
                      >
                        Assign Role Based Menus
                      </NavDropdown.Item> */}
                <NavDropdown.Item
                  onClick={() => history.push(PATH.ORGANIZATION)}
                >
                  Organizations
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => history.push(PATH.MANAGE_USER)}
                >
                  Users
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push(PATH.CLIENTS)}>
                  Clients
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                        onClick={() => history.push(PATH.PROJECTSETTINGS)}
                      >
                        Project Settings
                      </NavDropdown.Item> */}
              </NavDropdown>
            </Dropdown>
          </li>
          <li className="mt-4 " data-tut="reactour__scroll--hidden">
            <Dropdown className="align-self-center">
              <Dropdown.Toggle
                id="dropdown-custom-components"
                as={NotificationButton}
              >
                <div className="btn-hi">
                  {getInitials(`${"Irfan"} ${"Malik"}`)}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{ minWidth: "18rem" }}
                align="right"
                className="sign-out-menu"
              >
                <div className="d-flex flex-column align-items-center">
                  <div className="d-flex mx-2 align-items-lg-end">
                    <div className="UserImage my-profile-img">
                      <p className="initial-name">
                        {getInitials(`${"Irfan"} ${"Malik"}`)}
                      </p>
                    </div>
                    <div className="w-100 ml-2 d-flex flex-column align-items-start">
                      <h5 className="text-dark mt-3 text-left fw9">
                        {`${"Irfan"} ${"Malik"}`}
                      </h5>
                      <p className="sign-out-Profile">{`Member Since 29 Feb, 2022`}</p>
                    </div>
                  </div>
                  <div className="border-bottom w-100 mt-3"></div>
                  <div className="w-100 mt-3 pl-4">
                    <div
                      variant="light"
                      className="border-0 d-flex align-content-center mb-2"
                    >
                      <AiOutlineUser
                        className="mr-2 user-profile-out"
                        size={18}
                      />
                      <span className="sign-out-Profile ">View Profile</span>
                    </div>
                    <div
                      variant="light"
                      className="border-0 d-flex align-content-center align-item-center mb-1 mt-2"
                    >
                      <MdOutlineLogout
                        className="mr-2 user-profile-out"
                        size={18}
                      />
                      <SignOut className="text-dark sign-out-Profile" />
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <ul
          className="nav nav-pills flex-column align-items-center mb-10"
          id="menu"
        >
          <li className="xeven-side-link">
            <Dropdown className="align-self-center project-options p-0 organization-dropdown">
              <NavDropdown
                className="new-setting-item m-0"
                alignRight
                title={
                  <div className="xeven-logo-size">
                    <img
                      src={IMAGES.APPLOGO1}
                      alt="Xeven Logo"
                      style={{
                        width: "29px",
                        height: "29px",
                        margin: "2px 1px",
                      }}
                    />
                  </div>
                }
                id=""
              >
                <NavDropdown.Item className="justify-content-between selected">
                  Xeven Solutions <FaCheckCircle fill="green" />{" "}
                </NavDropdown.Item>
                <NavDropdown.Item>Covis</NavDropdown.Item>
                <NavDropdown.Item>Xeven Health</NavDropdown.Item>
              </NavDropdown>
            </Dropdown>
          </li>
        </ul>
      </div>
    </div>
    // <div className={` px-sm-2 px-0 updated-sidebar maxWidth-sidebar`}>
    //   <div className="d-flex flex-column align-items-center align-items-sm-center  pt-2 text-white min-vh-100">
    //     <div>
    //       <ul
    //         className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-center"
    //         id="menu"
    //       >
    //         <li className="mt-0 ">

    //           <img className="img-fluid app-logo-width" src={IMAGES.APPLOGO} />
    //         </li>
    //         <li className="mt-3 side-link">
    //           {/* <Link to={PATH.HOME}> */}
    //           <AiOutlineHome
    //             className="sideNave-icon icons"
    //             size={22}
    //             data-tut="reactour__iso"
    //           />
    //           {/* </Link> */}
    //         </li>
    //         <li className="mt-3 position-relative-li side-link"

    //         >
    //           {clicked ? (
    //             <>
    //               <BsFillGrid3X3GapFill className="after-click-icn sideNave-icon icons" size={25} />
    //               <AiOutlineCaretLeft
    //                 className=" arrw-icon-styling"

    //               // size={25}
    //               />
    //             </>
    //           ) : (
    //             <BsFillGrid3X3GapFill
    //               size={22}
    //               className="sideNave-icon icons"
    //               onClick={() => upDateStateAction()}
    //               data-tut="reactour__logo"
    //             />
    //           )}
    //           {clicked && (
    //             <div
    //               className="sidebar-item-clicked bg-white test_this"
    //               ref={purchasedSecRef}
    //             >
    //               <div className="backdrop bg-white pb-3 px-3">
    //                 <span className="text-muted my-purchased-text ">
    //                   My Purchased App’s
    //                 </span>
    //                 <div className="d-flex mt-2">
    //                   <div className="human-resourses bg-warning font-size-10">
    //                     <div className=" circle-div p-2">
    //                       <BsJoystick className="text-warning" />
    //                       <br />
    //                     </div>
    //                     <span className="font-size-10 mb-2">
    //                       Project management
    //                     </span>
    //                   </div>
    //                   <Link to={PATH.DASHBOARD} className="d-flex">
    //                     <div
    //                       className="human-resourses bg-success font-size-10 mx-3"
    //                     >
    //                       <div className=" circle-div p-2 mb-3">
    //                         <AiOutlineTeam className="text-success" />
    //                         <br />
    //                       </div>
    //                       <span className="font-size-10 mb-2 text-white">HRM</span>
    //                     </div>
    //                   </Link>
    //                   <div
    //                     className="human-resourses feedback-system font-size-10 "
    //                     onClick={() =>
    //                       goToPage("https://tracker.visioncollab.com")
    //                     }
    //                   >
    //                     <div className=" circle-div p-2">
    //                       <BsReply className="feedback-system-icon-color" />
    //                       <br />
    //                     </div>
    //                     <span className="font-size-10 mb-2">
    //                       Resources Tracker
    //                     </span>
    //                   </div>
    //                 </div>
    //                 <div className="d-flex mt-3 justify-content-center">
    //                   <div
    //                     className="human-resourses bg-danger font-size-10 mr-3"
    //                     onClick={() => goToPage("https://fms.visioncollab.com")}
    //                   >
    //                     <div className=" circle-div p-2">
    //                       <BsGraphUp className="text-danger" />
    //                       <br />
    //                     </div>
    //                     <span className="font-size-10 mb-2">
    //                       Finance Management
    //                     </span>
    //                   </div>

    //                   <div
    //                     className="human-resourses settings-bg-color font-size-10"
    //                   // onClick={() => {
    //                   //   history.push(PATH.REPORTFILES);
    //                   //   setClicked(false);
    //                   // }}
    //                   >
    //                     <div className=" circle-div p-2 mb-3">
    //                       <BsCloudUpload className="text-info" />
    //                       <br />
    //                     </div>
    //                     <span className="font-size-10 mb-2">File Cloud</span>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           )}
    //         </li>
    //         <li>

    //         </li>
    //         <li className="mt-3 side-link">
    //           <Dropdown className="align-self-center">
    //             <Dropdown.Toggle
    //               id="dropdown-custom-components"
    //               as={NotificationButton}
    //             >
    //               <BsBell className=" sideNave-icon icons" size={22} data-tut="reactour__copy"

    //               />
    //             </Dropdown.Toggle>
    //             <Dropdown.Menu
    //               style={{ minWidth: "23rem" }}
    //               className="no-padding"
    //               align="right"
    //             >
    //               <NotificationSection />
    //             </Dropdown.Menu>
    //           </Dropdown>
    //         </li>
    //         {/* {history.location.pathname.startsWith("/Task/") && (
    //           <li className="mt-2">
    //             <Dropdown className="align-self-center project-options">
    //               <NavDropdown
    //                 alignRight
    //                 title={
    //                   // <FontAwesomeIcon className="icon-nav" icon={faCog} />
    //                   <IoIosOptions color="white" size={25} />
    //                 }
    //                 id="collasible-nav-dropdown"
    //               >
    //                 <NavDropdown.Item onClick={() => openTaskBoardModal()}>
    //                   {"Create Board "}
    //                 </NavDropdown.Item>
    //                 <NavDropdown.Item href="#">{"Builds"}</NavDropdown.Item>
    //                 <NavDropdown.Item
    //                   onClick={() =>
    //                     history.push({
    //                       pathname: PATH.PROJECTSETTING.replace(
    //                         ":projectId",
    //                         params?.id
    //                       ),
    //                       state: {
    //                         value: currentProject,
    //                       },
    //                     })
    //                   }
    //                 >
    //                   {"Edit Project"}
    //                 </NavDropdown.Item>
    //                 <NavDropdown.Item href="#">
    //                   {"Move to Trash"}
    //                 </NavDropdown.Item>
    //                 <NavDropdown.Item
    //                   onClick={() =>
    //                     dispatch(request(TASK_REQUEST.OPEN_INVITE_MODAL))
    //                   }
    //                 >
    //                   {"Invite"}
    //                 </NavDropdown.Item>
    //               </NavDropdown>
    //             </Dropdown>
    //           </li>
    //         )} */}
    //       </ul>
    //     </div>
    //     {/* <div class="div-rotation d-flex align-items-center xeven-checkbox">
    //       <Form>
    //         <Form.Check type="switch" id="custom-switch" label="" />
    //       </Form>
    //       <h6 className="mb-0 tenant-name">XEVEN SOLUTIONS</h6>
    //     </div> */}
    //     <div>
    //       <ul
    //         className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-center"
    //         id="menu"
    //       >
    //         <>
    //           <li
    //             // onClick={() => history.push(PATH.PRICING)}
    //             className={`mt-3 side-link ${history?.location?.pathname === "/dashboard" && "active"
    //               }`}
    //           >
    //             <AiOutlineDollarCircle
    //               size={20}
    //               data-tut="reactour__goTo"
    //               className="sideNave-icon icons "
    //             />
    //           </li>
    //           <li className="mt-3 side-link">
    //             <Dropdown className="align-self-center project-options p-0 ">
    //               <NavDropdown
    //                 className="new-setting-item"
    //                 alignRight
    //                 title={
    //                   <AiOutlineSetting
    //                     className="sideNave-icon icons"
    //                     size={22}
    //                     data-tut="reactour__scroll"
    //                   />
    //                 }
    //                 id=""
    //               >
    //                 <NavDropdown.Item
    //                   // onClick={() => history.push(PATH.TRASH)}
    //                   trash
    //                 >
    //                   Trash
    //                 </NavDropdown.Item>
    //                 <NavDropdown.Item
    //                 // onClick={() => history.push(PATH.USER_ROLES)}
    //                 >
    //                   User Role
    //                 </NavDropdown.Item>
    //                 {/* <NavDropdown.Item
    //                     onClick={() => history.push(PATH.MANAGEMENU)}
    //                   >
    //                     Manage Menus
    //                   </NavDropdown.Item> */}
    //                 {/* <NavDropdown.Item
    //                     onClick={() => history.push(PATH.ROLEBASEDMENUS)}
    //                   >
    //                     Assign Role Based Menus
    //                   </NavDropdown.Item> */}
    //                 <NavDropdown.Item
    //                 // onClick={() => history.push(PATH.ORGANIZATION)}
    //                 >
    //                   Organizations
    //                 </NavDropdown.Item>
    //                 <NavDropdown.Item
    //                 // onClick={() => history.push(PATH.MANAGEUSER)}
    //                 >
    //                   Users
    //                 </NavDropdown.Item>
    //                 <NavDropdown.Item
    //                 // onClick={() => history.push(PATH.CLIENTS)}
    //                 >
    //                   Clients
    //                 </NavDropdown.Item>
    //                 {/* <NavDropdown.Item
    //                     onClick={() => history.push(PATH.PROJECTSETTINGS)}
    //                   >
    //                     Project Settings
    //                   </NavDropdown.Item> */}
    //               </NavDropdown>
    //             </Dropdown>
    //           </li>
    //         </>
    //         <li className="mt-4 " data-tut="reactour__scroll--hidden">
    //           <Dropdown className="align-self-center">
    //             <Dropdown.Toggle
    //               id="dropdown-custom-components"
    //               as={NotificationButton}
    //             >
    //               <div className="btn-hi">

    //               </div>
    //             </Dropdown.Toggle>
    //             <Dropdown.Menu
    //               style={{ minWidth: "18rem" }}
    //               align="right"
    //               className="sign-out-menu"
    //             >
    //               <div className="d-flex flex-column align-items-center">
    //                 <div className="d-flex mx-2 align-items-lg-end">
    //                   <div className="UserImage my-profile-img">
    //                     <p className="initial-name">
    //                       IF
    //                     </p>
    //                   </div>
    //                   <div className="w-100 ml-2 d-flex flex-column align-items-start">
    //                     <h5 className="text-dark mt-3 text-left fw9">
    //                       Irfan
    //                     </h5>
    //                     <p className="sign-out-Profile">Member Since </p>
    //                   </div>
    //                 </div>
    //                 <div className="border-bottom w-100 mt-3"></div>
    //                 <div className="w-100 mt-3 pl-4">
    //                   <div
    //                     variant="light"
    //                     className="border-0 d-flex align-content-center mb-2"
    //                   >
    //                     <AiOutlineUser className="mr-2 user-profile-out f-18" />
    //                     <span className="sign-out-Profile">
    //                       View Profile
    //                     </span>
    //                   </div>
    //                   <div
    //                     variant="light"
    //                     className="border-0 d-flex align-content-center align-item-center mb-1 mt-2"
    //                   >
    //                     <span className="">
    //                       <img src={IMAGES.SIGNOUTIMG} className="mr-2 " />
    //                     </span>
    //                     <SignOut className="text-dark sign-out-Profile" />
    //                   </div>
    //                 </div>
    //               </div>
    //             </Dropdown.Menu>
    //           </Dropdown>
    //         </li>
    //         {/* <li className="xeven-side-link">
    //           <Dropdown className="align-self-center project-options p-0 ">
    //             <NavDropdown
    //               className="new-setting-item"
    //               alignRight
    //               title={
    //                 <div className="xeven-logo-size">
    //                   <img
    //                     // src={IMAGES.XEVEN_LOGO}
    //                     alt="Xeven Logo"
    //                     style={{
    //                       width: "29px",
    //                       height: "28px",
    //                       margin: "2px 1px",
    //                     }}
    //                   />
    //                 </div>
    //               }
    //               id=""
    //             >
    //               <NavDropdown.Item>Xeven Solutions</NavDropdown.Item>
    //               <NavDropdown.Item>Covis</NavDropdown.Item>
    //               <NavDropdown.Item>Xeven Health</NavDropdown.Item>
    //             </NavDropdown>
    //           </Dropdown>
    //         </li> */}
    //       </ul>
    //     </div>
    //     <div></div>
    //   </div>
    // </div>
  );
}

export default UpdatedSideBar;

const NotificationSection = (props) => {
  const notifications = [
    {
      image: IMAGES.NOTIFICATION1,
      title: "New Request",
      tinyhead: "Iftar riaz send you a request",
      date: "Today",
    },
    {
      image: IMAGES.NOTIFICATION2,
      title: "Request Approved",
      tinyhead: "Eisa Malik send you a request",
      date: "Yesterday",
    },
    {
      image: IMAGES.NOTIFICATION3,
      title: "New Message",
      tinyhead: "Eisa Malik send you a request",
      date: new Date().toDateString(),
    },
    {
      image: IMAGES.NOTIFICATION4,
      title: "New Message",
      tinyhead: "Ali approved request",
      date: new Date().toDateString(),
    },
    {
      image: IMAGES.NOTIFICATION5,
      title: "New Message",
      tinyhead: "Eisa Malik send you a request",
      date: new Date().toDateString(),
    },
  ];
  const handleCloseDropdown = () => {
    const dropdownButton = document.querySelector(".dropdown");
    dropdownButton.click();
  };
  return (
    <>
      <div className="notification-panel-header">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-baseline">
              <h5 className="Notifications-center">Notifications</h5>
              <div className="d-flex align-items-center">
                <p className="Mark-read mr-2">Mark all as read</p>
                <AiOutlineCheckCircle className="Mark-read" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid notification-panel-body">
        <div className="w-100">
          {notifications &&
            notifications.map((notification, idx) => {
              return (
                <div className="cursor-pointer notif-hover" key={idx}>
                  <SingleNotification notification={notification} />
                  {idx !== notifications.length - 1 && (
                    <hr className="mb-3 mt-2" />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="notification-panel-footer py-6">
        <div className="row">
          <div className="col-12 justify-content-center d-flex">
            <Link to={PATH.NOTIFICATION_SETTING}>
              <button
                className="all-notification"
                onClick={handleCloseDropdown}
              >
                {"See All Notifications"}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const SingleNotification = (props) => {
  return (
    <>
      <div className="row mb-1">
        <div className="align-self-center">
          <img
            src={props.notification.image}
            icon={faExclamationCircle}
            className="Notification-images"
            style={{
              fontSize: "22px",
            }}
            alt=""
          />
        </div>
        <div className="col-10">
          <div className="d-flex justify-content-between align-item-center">
            <div className="">
              <p className="no-margin massages_history">
                {props.notification.title}
              </p>
              <p className="messsage_request">{props.notification.tinyhead}</p>
            </div>
            <p className="no-margin font-200 text-muted text-right">
              {props.notification.date}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
