import React from 'react';
import { MdClose } from "react-icons/md"
import { Button, Modal } from 'react-bootstrap';

function AddExpenseModal(props) {
    const { setModalShow } = props;
    const expenseOptions = [
        {
            name: "Expense 1",
        },
        {
            name: "Expense 2",
        },
    ];
    const subTypeOptions = [
        {
            name: "Sub 1",
        },
        {
            name: "Sub 2",
        },
    ];
    const invoiceStatusOptions = [
        {
            name: "Paid",
        },
        {
            name: "Unpaid",
        },
    ];
    const paymentStatusOptions = [
        {
            name: "Pending",
        },
        {
            name: "Progress",
        },
        {
            name: "Paid",
        },
    ];
    const paymentTypeOptions = [
        {
            name: "Safety",
        },
        {
            name: "internal",
        },
        {
            name: "Health",
        },
        {
            name: "Other",
        },
    ];
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="d-flex justify-content-between mb-5">
                    <h2>Add Expense</h2>
                    <MdClose onClick={() => setModalShow(false)} className="align-self-center cursor-pointer" size={30} />
                </div>
                <hr />
                <div className="row mt-7 mb-5">
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Expense Type
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Expense Type
                                </option>

                                {expenseOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Sub Type
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Sub Type
                                </option>

                                {subTypeOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Paid By
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='Paid By' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Paid To
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='Paid To' />
                        </div>
                    </div>
                    <div className="col-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Amount
                            </label>
                            <input type="number" className='add-form-input-class form-control' placeholder='$0,000,000' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Invoice Status
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Invoice Status
                                </option>

                                {invoiceStatusOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Payment Status
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Payment Status
                                </option>

                                {paymentStatusOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Payment Type
                            </label>
                            <select
                                name="designationOptions"
                                className="add-form-select"
                            >
                                <option value="" selected disabled>
                                    Select Payment Type
                                </option>

                                {paymentTypeOptions?.map((list, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={list.name}
                                            selected={
                                                list.name === "" ? true : false
                                            }
                                        >
                                            {list.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Due Date
                            </label>
                            <input class="form-control add-form-input-class" type="date" id="example-date-input" />
                        </div>
                    </div>
                    <div className="col-12 mb-sm-3">
                        <div className="select_disable mr-2 mb-2">
                            <label className="form-label add-form-label" htmlFor="catalog">
                                Income Description
                            </label>
                            <textarea class="form-control add-form-input-textarea" id="exampleTextarea" rows="3"></textarea>
                        </div>
                    </div>
                    <div className="col-12 mb-sm-3">
                    </div>
                    <div className="col-12 mt-5">
                        <button
                            type="button"
                            className="generic-gradient-button w-100"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddExpenseModal