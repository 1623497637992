import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { MANAGER_ROLE } from "../../../config";
// import { FieldError, Notification } from "../../../assets";
// import { MANAGER_ROLE } from "../../../config";
// import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
// import {
//   editManageUser,
//   getManageUserList,
// } from "../../../redux/actions/manageUserAction";
// import { FieldError } from "components/util-components/ErrorMessage";
// import { MANAGER_ROLE } from "config";

export default function EditUserModal(props) {
  const { editHandleClose, editShow, editUser } = props;
  return (
    <Modal
      className="p-0"
      aria-labelledby="contained-modal-title-vcenter"
      id="user-welcome-card"
      centered
      show={editShow}
      onHide={() => editHandleClose()}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header
        className="mx-4 pb-3 px-0 d-flex align-items-end"
        closeButton
      >
        <h5 className="generic-heading-level-3">Edit User</h5>
      </Modal.Header>
      <ModalBody {...props} />
    </Modal>
  );
}
function ModalBody(props) {
  console.log("propsTest", props);
  // const { register, handleSubmit, errors } = useForm();
  let dispatch = useDispatch();
  // let auth = useAuth();
  // let token = auth.user.token;
  function moveToNext() {
    props.editHandleClose();
  }
  function onSubmit(data) {
    // let finalData = {
    //   userId: parseInt(props.editUser.userId),
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    //   email: data.email,
    //   password: data.password,
    //   userRoleId: parseInt(data.roleId),
    //   organizationId: props?.editUser?.organizationId,
    //   createdById: auth.user.userId,
    //   departmentId: parseInt(data.departmentId),
    // };
    // dispatch(editManageUser(finalData, token, moveToNext, Notification));
  }
  return (
    <form>
      <Modal.Body className="py-0 px-2">
        <div className="px-3 b7">
          <Row className="mb-2 mt-3">
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"First Name"}</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                  defaultValue={props.editUser.firstName}
                />
                {/* {errors.firstName && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Last Name"}</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                  defaultValue={props.editUser.lastName}
                />
                {/* {errors.lastName && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>

            <Col lg="12">
              <Form.Group>
                <label className="salary-labels">{"Email"}</label>
                <Form.Control
                  className="mb-2 w-100"
                  type="email"
                  name="email"
                  placeholder="Email"
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                  defaultValue={props.editUser.email}
                />
                {/* {errors.email && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Role"}</label>
                <Form.Control
                  className="mb-2"
                  as="select"
                  name="roleId"
                  // ref={register({ required: true })}
                  defaultValue={props.editUser.userRoleId}
                >
                  {MANAGER_ROLE.map((item) => {
                    return <option value={item.id}>{item.title}</option>;
                  })}
                </Form.Control>
                {/* {errors.roleId && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Department"}</label>
                <Form.Control
                  className="mb-2"
                  as="select"
                  name="departmentId"
                  // ref={register({ required: true })}
                  defaultValue={props.editUser?.departmentId}
                >
                  <option value="">Select Department</option>
                  <option value={34}>Augmentation</option>
                  <option value={35}>Bid</option>
                  {/* {MANAGER_ROLE.map((item) => {
                      return <option value={item.id}>{item.title}</option>;
                    })} */}
                </Form.Control>
                {/* {errors.roleId && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Password"}</label>
                <Form.Control
                  className="mb-2"
                  type="password"
                  placeholder="Password"
                  name="password"
                  defaultValue={props.editUser.password}
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                />
                {/* {errors.password && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Confirm Password"}</label>
                <Form.Control
                  className="mb-2"
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                  defaultValue={props.editUser.password}
                />
                {/* {errors.confirmPassword && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Button
                type="submit"
                className="mt-4 py-2 mt-3 mb-4 w-100 button-sizes Home-buttons"
              >
                Update
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </form>
  );
}
