import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Col, Row, Card, Pagination } from "react-bootstrap";
// import { getAllClients, removeClient } from "../../redux/actions";
// import { ConfirmationModal, Loader } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
// import { Notification } from "../../assets";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import DataTable from "react-data-table-component";
import { IoSearchOutline } from "react-icons/io5";
import EditClientsModal from "./components/editClientModal";
// import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import AddClientModal from "./components/addClientModal";
// import { ConfirmationModal } from "components/util-components/ConfirmationModal";
// import { Loader } from "components/util-components/Loader";
import { ConfirmationModal } from "../../../_metronic/layout/components/ConfirmationModal";
export default function Clients() {
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    document.title = "Manage Clients | Covis";
  }, []);
  const [open, setOpen] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editClient, setEditClient] = useState(null);
  const editHandleClose = () => setEditShow(false);
  const editHandleShow = () => setEditShow(true);
  const [organizationShow, setOrganizationShow] = useState(false);
  const organizationHandleClose = () => setOrganizationShow(false);
  const organizationHandleShow = () => setOrganizationShow(true);
  const [clientId, setClientId] = useState(null);
  let clients = useSelector((state) => state.clients);

  let dispatch = useDispatch();
  // let auth = useAuth();
  const customStyles = {
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        backgroundColor: " rgba(245, 245, 250, 0.4);",
      },
    },
    pagination: {
      pageButtonsStyle: {
        cursor: "no-drop",
        color: "#fbfbfb",
        opacity: "0.75",
        backgroundColor: "#eee",
      },
    },
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div className="cursor">
                <FiEdit
                  onClick={() => {
                    editHandleShow();
                    setEditClient(row);
                  }}
                  style={{ color: "#5E81F4" }}
                  size={17}
                />
              </div>
              <div>
                <RiDeleteBin6Line
                  className="cursor ml-2"
                  onClick={() => {
                    setOpen(true);
                    setClientId(row.userId);
                  }}
                  style={{ color: "#E54545" }}
                  size={17}
                />
              </div>
            </div>
          </>
        );
      },
      sortable: false,
      allowOverflow: true,
      width: "100px",
      center: true,
    },
  ];
  const Product = [
    {
      name: "Mubashir",
      email: "mubashir@xeven.com",
    },
    {
      name: "Usman",
      email: "usman@xeven.com",
    },
    {
      name: "Abid",
      email: "abid@xeven.com",
    },
    {
      name: "Shafiq",
      email: "shafiq@xeven.com",
    },
    {
      name: "Salman",
      email: "salman@xeven.com",
    },
    {
      name: "Mahid",
      email: "mahid@xeven.com",
    },
    {
      name: "Hamad",
      email: "hamad@xeven.com",
    },
    {
      name: "Zain",
      email: "zain@xeven.com",
    },
  ];

  function filterClient(name) {
    // if (name.length > 2 || name === "") {
    //   let data = {
    //     // pageSize: 1,
    //     // pageNum: 10,
    //     search: name,
    //     organizationId: auth?.user?.organizationId,
    //   };
    //   dispatch(getAllClients(data));
    // }
  }
  // useEffect(() => {
  //   let data = {
  //     // pageSize: 1,
  //     // pageNum: 10,
  //     // "search": "string",
  //     organizationId: auth?.user?.organizationId,
  //   };
  //   dispatch(getAllClients(data));
  // }, [dispatch]);
  return (
    <div className="col-md-12 no-padding organization-screen">
      <div className="Home-background  pb-3">
        <section className="content-header px-0 pt-1">
          <div className="container-fluid section-top-header no-padding">
            <div className="d-flex bd-highlight d-flex py-2 justify-content-between flex-wrap align-items-center">
              <div className="p-2 bd-highlight">
                <h1>{"Manage Clients"}</h1>
              </div>
              <div>
                <Button
                  style={{
                    background:
                      "linear-gradient(90deg, #009EFF -26.47%, #00F38D 127.21%)",
                  }}
                  onClick={() => {
                    organizationHandleShow();
                  }}
                  className="px-3 py-2 mr-2"
                >
                  Add Client
                </Button>
              </div>
            </div>
          </div>
        </section>
        <React.Fragment>
          <Card className="border-0 h-100 mx-2">
            <Card.Body className="px-0 pb-0 ">
              <Row>
                <Col lg={12}>
                  <div className="d-flex flex-wrap justify-content-between align-items-center pb-3 px-4">
                    <h6 className="generic-heading-level-2 generic-theme-color mb-2 mr-2">
                      Client List
                    </h6>
                    <div className="d-flex align-items-center responsive-field">
                      <IoSearchOutline className="search-icon" size={16} />

                      <input
                        type="title"
                        className="form-control align-input-field"
                        id="title"
                        placeholder="Search Client"
                        name="search"
                        onChange={(e) => filterClient(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="mb-2 table-responsive">
                <DataTable
                  className="dataTables_wrapper"
                  columns={columns}
                  data={Product}
                  customStyles={customStyles}
                  highlightOnHover
                  responsive
                  striped
                  keyField="taskId"
                  pagination={true}
                />
              </div>

              <ConfirmationModal
                // removeFunc={() => remClient(clientId)}
                show={open}
                onHide={() => setOpen(false)}
                heading={"Delete Project"}
                title={"this client"}
              />
            </Card.Body>
          </Card>
          <AddClientModal
            organizationShow={organizationShow}
            organizationHandleClose={organizationHandleClose}
          />
          <EditClientsModal
            editClient={editClient}
            editShow={editShow}
            editHandleClose={editHandleClose}
          />
        </React.Fragment>
      </div>
    </div>
  );
}
