import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
// import { FieldError, Notification } from "../../../assets";
// import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
// import { editClient } from "../../../redux/actions";
// import { FieldError } from "components/util-components/ErrorMessage";

export default function EditClientsModal(props) {
  const { editShow, editHandleClose, editClient } = props;
  return (
    <Modal
      className="p-0 add_new_project"
      aria-labelledby="contained-modal-title-vcenter"
      id="user-welcome-card"
      centered
      show={editShow}
      onHide={() => editHandleClose()}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header
        className="mx-4 pb-3 px-0 d-flex align-items-end"
        closeButton
      >
        <h5 className="generic-heading-level-3">Edit Client</h5>
      </Modal.Header>
      <ModalBody {...props} />
    </Modal>
  );
}
function ModalBody(props) {
  // const { register, handleSubmit, errors } = useForm();
  let dispatch = useDispatch();
  // let auth = useAuth();
  function moveToNext() {
    props.editHandleClose();
  }
  function onSubmit(data) {
    // let finalData = {
    //   userId: props.editClient?.userId,
    //   name: data.clientName,
    //   email: data.email,
    //   // file: "string",
    //   createdById: auth.user.userId,
    //   organizationId: auth?.user?.organizationId,
    //   // filePath: "string",
    //   // fileName: "string",
    // };
    // dispatch(editClient(finalData, moveToNext, Notification));
  }
  return (
    <form>
      <Modal.Body className="py-0 px-2">
        <div className="px-3 b7">
          <Row className="mb-2 mt-3">
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Name"}</label>
                <Form.Control
                  className="mb-2"
                  type="Text"
                  placeholder="Name"
                  name="clientName"
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                  defaultValue={props.editClient.name}
                />
                {/* {errors.clientName && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="salary-labels">{"Email"}</label>
                <Form.Control
                  className="mb-2"
                  type="email"
                  placeholder="Email"
                  name="email"
                  defaultValue={props.editClient.email}
                  // ref={register({ required: true, pattern: /[^\s]+/ })}
                />
                {/* {errors.email && (
                  <FieldError message={"This field is required."} />
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Button
                type="submit"
                className="mt-4 py-2 mt-3 mb-4 w-100 button-sizes Home-buttons"
              >
                Update
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </form>
  );
}
