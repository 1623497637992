export const PATH = {
  DASHBOARD: "/dashboard",
  FINANCE_DASHBOARD: "/financeDashboard",
  INCOME: "/income",
  EXPENSE: "/expense",
  LOAN: "/loan",
  SALARIES: "/salaries",
  ADDSALARY: "/add-salary",
  EDITSALARY: "/edit-salary",
  TRASH: "/trash",
  MANAGE_USER: "/manage-user",
  ORGANIZATION: "/organization",
  CLIENTS: "/clients",
  INVOICE: "/invoices",
  ADD_INVOICE: "/invoice/add",
  NOTIFICATION_SETTING: "/notification/setting",
  PROVIDENTFUND: "/providentfund",
  CASHCATEGORY: "/cashcategory",
  BILLABLEHOURS: "/billablehours",
  EMPLOYEECOMPENSATIONS: "/employeecompensations",
  VIEW_EMPLOYEE_COMPENSATION_DETAILS: "/employeecompensations/view",
  ADDCOMPENSATION: "/employeecompensations/addcompensation",
  MONTHLYEMPLOYEECOMPENSATION: "/monthlyemployeecompensation",
  COMPENSATION: "/monthlyemployeecompensation/monthlycompensation",
  PAY_CODE: "/pay-code",
};
const MANAGER_ROLE = [
  { id: 15, title: "Admin" },
  { id: 14, title: "Manager" },
  { id: 2, title: "Employee" },
];
export { MANAGER_ROLE };
