import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
// import { success, USER_REQUEST } from "../../redux/actions/utilities";
export function SignOut({ className }) {
  const dispatch = useDispatch();

  const [cookies, removeCookie] = useCookies(["user"]);
  // function moveBackToHome() {
  //   dispatch(success(USER_REQUEST.SET_SESSION_EXPIRE_STATE, false));
  //   window.localStorage.removeItem("pmsTokenExpiry");
  // }
  return (
    <span
      className={`${className ? className : "sign-out"}`}
      onClick={() => {
        removeCookie("user");
        // moveBackToHome();
      }}
    >
      {"Sign Out"}
    </span>
  );
}
