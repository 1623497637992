import React from "react";
import { Row, Col, Button, Card } from "react-bootstrap";

import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  useFormikContext,
  FieldArray,
} from "formik";

export function Form(props) {
  return (
    <Formik {...props}>
      <FormikForm className="needs-validation" noValidate>
        {props.children}
      </FormikForm>
    </Formik>
  );
}

export function TextField(props) {
  const { name, label, placeholder, ...rest } = props;

  return (
    <Col lg="3" className="mb-5">
      {label && <label htmlFor={name}>{label}</label>}

      <Field
        className="form-control"
        type="text"
        name={name}
        id={name}
        placeholder={placeholder || ""}
        {...rest}
      />

      <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
      />
    </Col>
  );
}

export function SelectField(props) {
  const { name, label, options } = props;

  return (
    <Col lg="3" className="mb-5">
      {label && <label htmlFor={name}>{label}</label>}

      <Field as="select" id={name} name={name} className="form-control">
        <option value="">Choose...</option>

        {(options || []).map((optn, index) => (
          <option
            key={index}
            value={optn.value}
            label={optn.label || optn.value}
          />
        ))}
      </Field>

      <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
      />
    </Col>
  );
}

export function SubmitButton(props) {
  const { title, ...rest } = props;

  const { isSubmitting } = useFormikContext();

  return (
    <button
      type="submit"
      className="btn btn-primary"
      {...rest}
      disabled={isSubmitting}
    >
      {title}
    </button>
  );
}

export function LoanBlock(props) {
  const { values, setValues } = useFormikContext();

  return (
    <>
      <Col lg="12">
        <Card className="p-5 mb-8">
          <Row>
            <Col lg="3" className="mb-5">
              <label htmlFor="loanDetails.totalAmout">Loan Amount</label>

              <Field
                className="form-control"
                type="text"
                name="loanDetails.totalAmout"
                id="loanDetails.totalAmout"
                placeholder="Loan amount"
              />

              <ErrorMessage
                name="loanDetails.totalAmout"
                render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
              />
            </Col>

            <Col lg="3" className="mb-5">
              <label htmlFor="loanDetails.dueDate">Due date</label>

              <Field
                className="form-control"
                type="date"
                name="loanDetails.dueDate"
                id="loanDetails.dueDate"
                placeholder="Loan amount"
              />

              <ErrorMessage
                name="loanDetails.dueDate"
                render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
              />
            </Col>

            <Col lg="3" className="mb-5 d-flex align-items-end">
              <label>
                <Field type="checkbox" name="loanDetails.makeInstallments" />{" "}
                Make Installments
              </label>

              <ErrorMessage
                name="loanDetails.makeInstallments"
                render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
              />
            </Col>

            {values.loanDetails && values.loanDetails.makeInstallments && (
              <Col lg="3" className="mb-5">
                <label htmlFor="loanDetails.noOfInstallments">
                  Select installments
                </label>

                <Field
                  className="form-control"
                  type="number"
                  name="loanDetails.noOfInstallments"
                  id="loanDetails.noOfInstallments"
                  placeholder="Select installments"
                  onKeyUp={(e) => {
                    e.preventDefault();
                    if (e.keyCode !== 13) return;
                    if (!values.loanDetails.noOfInstallments) return;

                    const noOfInstallment = Array(
                      +values.loanDetails.noOfInstallments
                    )
                      .fill(1)
                      .map(() => {
                        return { amount: "", date: "" };
                      });

                    setValues({
                      ...values,
                      loanDetails: {
                        ...values.loanDetails,
                        installments: noOfInstallment,
                      },
                    });
                  }}
                />

                <ErrorMessage
                  name="loanDetails.noOfInstallments"
                  render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                />
              </Col>
            )}

            {values.loanDetails && values.loanDetails.makeInstallments && (
              <FieldArray
                name="loanDetails.installments"
                render={(arrayHelpers) => (
                  <Col lg="12 my-5">
                    <Card className="p-5">
                      <Row>
                        {values.loanDetails &&
                        values.loanDetails.installments &&
                        values.loanDetails.installments.length > 0
                          ? values.loanDetails.installments.map(
                              (friend, index) => (
                                <Col lg="6" className="mb-5" key={index}>
                                  <Row className="align-items-end position-relative">
                                    <Col xs="6">
                                      <label
                                        htmlFor={`loanDetails.installments.${index}.amount`}
                                      >
                                        Amount
                                      </label>

                                      <Field
                                        className="form-control mb-1"
                                        type="number"
                                        name={`loanDetails.installments.${index}.amount`}
                                        id={`loanDetails.installments.${index}.amount`}
                                        placeholder="Amount"
                                      />

                                      <ErrorMessage
                                        name={`loanDetails.installments.${index}.amount`}
                                        render={(msg) => (
                                          <div
                                            style={{
                                              color: "red",
                                              marginBottom: "0.75rem",
                                            }}
                                          >
                                            {msg}
                                          </div>
                                        )}
                                      />
                                    </Col>

                                    <Col xs="6">
                                      <label
                                        htmlFor={`loanDetails.installments.${index}.date`}
                                      >
                                        Date
                                      </label>

                                      <Field
                                        className="form-control mb-1"
                                        type="date"
                                        name={`loanDetails.installments.${index}.date`}
                                        id={`loanDetails.installments.${index}.date`}
                                        placeholder="Date"
                                      />

                                      <ErrorMessage
                                        name={`loanDetails.installments.${index}.date`}
                                        render={(msg) => (
                                          <div
                                            style={{
                                              color: "red",
                                              marginBottom: "0.75rem",
                                            }}
                                          >
                                            {msg}
                                          </div>
                                        )}
                                      />
                                    </Col>
                                    <Button
                                      size="sm"
                                      variant="danger"
                                      className="btn-icon font-weight-bold mr-5 position-absolute top-0 right-0"
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      X
                                    </Button>
                                  </Row>
                                </Col>
                              )
                            )
                          : null}
                        <Col lg="12">
                          <Button
                            size="sm"
                            className="mx-auto"
                            onClick={() =>
                              arrayHelpers.push({ amount: "", date: "" })
                            }
                          >
                            Add installments
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )}
              />
            )}
          </Row>
        </Card>
      </Col>
    </>
  );
}
