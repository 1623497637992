import React, { useEffect, useState } from "react";
// import {
//   ConfirmationModal,
//   Loader,
//   Notification,
//   ParentComponentWithSideBar,
// } from "../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  Table,
  Modal,
  Badge,
  Row,
  Col,
  Form,
  Pagination,
} from "react-bootstrap";
import AddNewUserModel from "./component/AddNewUserModel";
import AlertModel from "./component/AlertNewUserModel";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
// import {
//   changeUserStatus,
//   deleteManageUser,
//   getManageUserList,
// } from "../../redux/actions/manageUserAction";
// import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import EditUserModal from "./component/editUserModal";
import AddUserModal from "./component/addUserModal";
// import { Loader } from "components/util-components/Loader";
import { ConfirmationModal } from "../../../_metronic/layout/components/ConfirmationModal";
// import { ConfirmationModal } from "components/util-components/ConfirmationModal";
// import { getEmployeeData } from "../../redux/actions";

export default function ManageUser() {
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const [show, setShow] = useState(false);
  useEffect(() => {
    document.title = "Manage Users | Covis";
  }, []);
  const [open, setOpen] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editShow, setEditShow] = useState(false);
  const editHandleClose = () => setEditShow(false);
  const editHandleShow = () => setEditShow(true);
  const [editUser, setEditUser] = useState(null);
  const [userShow, setUserShow] = useState(false);
  const userHandleClose = () => setUserShow(false);
  const userHandleShow = () => setUserShow(true);
  const [editUserId, setEditUserId] = useState(null);
  const userList = useSelector((state) => state.manageUser);
  let dispatch = useDispatch();
  // let auth = useAuth();
  // let token = auth.user.token;

  // useEffect(() => {
  //   let finalData = {
  //     organizationId: auth.user?.organizationId,
  //     userId: auth.user.userId,
  //   };
  //   dispatch(getManageUserList(finalData, token));
  // }, [dispatch]);
  // function moveToNext() {
  //   let info = {};
  //   dispatch(getEmployeeData(info, auth.user.organizationId, auth.user.token));
  // }
  // function removeManageUser(Id) {
  //   dispatch(deleteManageUser(Id, token, moveToNext, Notification));
  // }

  console.log("userList", userList);
  function filterUser(data) {
    // if (data.length > 2 || data.length == "") {
    //   let finalData = {
    //     organizationId: auth.user?.organizationId,
    //     userId: auth.user.userId,
    //     search: data,
    //   };
    //   dispatch(getManageUserList(finalData, token));
    // }
  }
  const customStyles = {
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        backgroundColor: " rgba(245, 245, 250, 0.4);",
      },
    },
    pagination: {
      pageButtonsStyle: {
        cursor: "no-drop",
        color: "#fbfbfb",
        opacity: "0.75",
        backgroundColor: "#eee",
      },
    },
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.userName,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.roleName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => GetStatusLabel(row, 1),
      left: true,
      // width: "200px",
    },

    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div className="cursor">
                <FiEdit
                  onClick={() => {
                    editHandleShow();
                    setEditUser(row);
                  }}
                  size={17}
                  style={{ color: "#5E81F4" }}
                />
              </div>
              <div>
                <RiDeleteBin6Line
                  className="ml-2 cursor"
                  onClick={() => {
                    setOpen(true);
                    setEditUserId(row.userId);
                  }}
                  size={17}
                  style={{ color: "#E54545" }}
                />
              </div>
            </div>
          </>
        );
      },
      sortable: false,
      allowOverflow: true,
      width: "100px",
      center: true,
    },
  ];
  const Product = [
    {
      userName: "Oscar Anderson",
      organizationName: "Hussan ORG",
      roleName: "Employee",
      isActive: "true",
      email: "qopipy@malinator.com",
    },

    {
      userName: "rashid ali",
      organizationName: "Hussan ORG",
      roleName: "Employee",
      isActive: "false",
      email: "rashid@getnada.com",
    },

    {
      userName: "zain ul Abideen",
      organizationName: "Hussan ORG",
      roleName: "Employee",
      isActive: "true",
      email: "zain@getnada.com",
    },

    {
      userName: "Mubashir Hussan",
      organizationName: "Hussan ORG",
      roleName: "Employee",
      isActive: "true",
      email: "mubashir123@getnada.com",
    },

    {
      userName: "usman qiass",
      organizationName: "Hussan ORG",
      roleName: "Employee",
      isActive: "true",
      email: "usmanqiass1@getnada.com",
    },

    {
      userName: "abid Ali Ali",
      organizationName: "Hussan ORG",
      roleName: "Employee",
      isActive: "true",
      email: "abidAli@getnada.com",
    },
    {
      userName: "Salman",
      organizationName: "Hussan ORG",
      roleName: "Employee",
      isActive: "true",
      email: "salman123@getnada.com",
    },

    {
      userName: "Shafiq Siddique",
      organizationName: "Hussan ORG",
      roleName: "Employee",
      isActive: "true",
      email: "salman@getnada.com",
    },

    {
      userName: "Hamad Zia",
      organizationName: "Hussan ORG",
      roleName: "Employee",
      isActive: "true",
      email: "hamad@getnada.com",
    },
  ];
  console.log("userList?.manageUserList", userList?.manageUserList);
  return (
    <div className="col-md-12 no-padding organization-screen new-user">
      <div className="Home-background  pb-3">
        <section className="content-header px-0 pt-1">
          <div className="container-fluid section-top-header no-padding">
            <div className="d-flex bd-highlight d-flex py-2 justify-content-between flex-wrap align-items-center">
              <div className="p-2 bd-highlight">
                <h1>{"Manage Users"}</h1>
              </div>
              <div className="mr-2">
                <Button
                  style={{
                    background:
                      "linear-gradient(90deg, #009EFF -26.47%, #00F38D 127.21%)",
                  }}
                  onClick={() => {
                    userHandleShow();
                  }}
                  className="px-3 py-2"
                >
                  Add Users
                </Button>
              </div>
            </div>
          </div>
        </section>
        <React.Fragment>
          <Card className="border-0 h-100 mx-2">
            <Card.Body className="px-0 pb-0 ">
              <Row>
                <Col lg={12}>
                  <div className="d-flex flex-wrap justify-content-between align-items-center pb-3 px-4">
                    <h6 className="generic-heading-level-2 generic-theme-color mb-2 mr-2">
                      Users List
                    </h6>
                    <div className="d-flex align-items-center responsive-field">
                      <IoSearchOutline className="search-icon" size={16} />

                      <input
                        type="text"
                        className="form-control align-input-field"
                        placeholder="Search User"
                        name="title"
                        onChange={(e) => filterUser(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="mb-2 table-responsive">
                <>
                  <DataTable
                    className="dataTables_wrapper"
                    columns={columns}
                    data={Product}
                    customStyles={customStyles}
                    highlightOnHover
                    responsive
                    striped
                    keyField="taskId"
                    pagination={true}
                  />
                </>
              </div>

              <ConfirmationModal
                //  removeFunc={() => removeManageUser()}
                show={open}
                onHide={() => setOpen(false)}
                heading={"Delete User"}
                title={"this User"}
              />
            </Card.Body>
          </Card>

          <EditUserModal
            editHandleClose={() => editHandleClose()}
            editShow={editShow}
            editUser={editUser}
          />

          <AddUserModal
            userHandleClose={() => userHandleClose()}
            userShow={userShow}
          />
        </React.Fragment>
      </div>
    </div>
  );
}

export const GetStatusLabel = (row) => {
  // const [statusBadge, setStatusBadge] = useState(value);
  console.log("row in user", row);
  let dispatch = useDispatch();
  // let auth = useAuth();
  // let token = auth.user.token;
  // const moveToNext = () => {
  //   let finalData = {
  //     organizationId: auth.user?.organizationId,
  //     userId: auth.user.userId,
  //   };
  //   dispatch(getManageUserList(finalData, token));
  //   console.log("abcd");
  // };
  const handleStatus = (value) => {
    // let FinalData = {
    //   userId: row?.userId,
    //   status: value === "1" ? true : false,
    // };
    // dispatch(changeUserStatus(FinalData, token, moveToNext, Notification));
  };
  return (
    <>
      {row?.isActive === "true" ? (
        <Badge pill className={`pass_badge`}>
          <select
            defaultValue={row?.isActive === "true" ? 1 : 2}
            className="border-0 bg-transparent"
            onChange={(e) => {
              handleStatus(e.target.value);
            }}
          >
            <option value={1} className="text-black-50">
              Active
            </option>
            <option value={2} className="text-black-50">
              Inactive
            </option>
          </select>
        </Badge>
      ) : (
        <Badge pill className={`fail_badge`}>
          <select
            defaultValue={row?.isActive === "true" ? 1 : 2}
            className="border-0 bg-transparent"
            onChange={(e) => {
              handleStatus(e.target.value);
            }}
          >
            <option value={2} className="text-black-50">
              Inactive
            </option>
            <option value={1} className="text-black-50">
              Active
            </option>
          </select>
        </Badge>
      )}
      {/* {value === 2 && (
        <Badge
          pill
          className={`py-0  
           ${statusBadge == 1
              ? "pass_badge"
              : statusBadge == 2
                ? "fail_badge"
                : "NA_badge"
            }`}
        >
          <select
            className="border-0 bg-transparent"
            onChange={(e) => {
              setStatusBadge(e.target.value);
            }}
          >
            <option value={2} className="text-black-50">
              Fail
            </option>
            <option value={1} className="text-black-50">
              Pass{" "}
            </option>
            <option value={3} className="text-black-50">
              N/A
            </option>
          </select>
        </Badge>
      )}
      {value === 3 && (
        <Badge
          pill
          className={`py-0  
           ${statusBadge == 1
              ? "pass_badge"
              : statusBadge == 2
                ? "fail_badge"
                : "NA_badge"
            }`}
        >
          <select
            className="border-0 bg-transparent"
            onChange={(e) => {
              setStatusBadge(e.target.value);
            }}
          >
            <option value={3} className="text-black-50">
              N/A
            </option>
            <option value={1} className="text-black-50">
              Pass
            </option>
            <option value={2} className="text-black-50">
              Fail
            </option>
          </select>
        </Badge>
      )} */}
    </>
  );
};
