import React from "react";
import { Link, useHistory } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as GoIcons from "react-icons/go";
import * as MdIcons from "react-icons/md";
import * as ImIcons from "react-icons/im";
import * as BiIcons from "react-icons/bi";
import * as IoClipboard from "react-icons/io5";
// import { PATH } from "../../../../config";
// import { useAuth } from "../../../../Navigation/Auth/ProvideAuth";
// import { IMAGES } from "../../../images";
import { HeaderDropDoenoptions } from "./HeaderDropDoenoptions";
import { FiChevronDown } from "react-icons/fi";
import { PATH } from "../../../../../app/config";

export function DropDownListData() {
  // const auth = useAuth();
  const userNavbarData = [
    {
      title: "Dashboard",
      path: PATH.DASHBOARD.replace(":type", "ceo"),
      icon: <AiIcons.AiOutlineDashboard />,
    },
    {
      title: "Income",
      path: PATH.INCOME,
      icon: <IoClipboard />,
    },
    {
      title: "Expenses",
      path: PATH.EXPENSE,
      icon: <ImIcons.ImStatsBars />,
    },
    {
      title: "Pay Codes",
      path: PATH.PAY_CODE,
      icon: <RiIcons.RiProjectorFill />,
    },
    {
      title: "Loan",
      path: PATH.LOAN,
      icon: <AiIcons.AiFillCheckSquare />,
    },
    {
      title: "Salaries",
      path: PATH.SALARIES,
      icon: <AiIcons.AiOutlineUnorderedList />,
    },
    {
      title: "Provident Fund",
      path: PATH.PROVIDENTFUND,
      icon: <AiIcons.AiOutlineUnorderedList />,
    },
    // {
    //   title: "Cash Category",
    //   path: PATH.CASHCATEGORY,
    //   icon: <AiIcons.AiOutlineUnorderedList />,
    // },
    {
      title: "Billable Hours",
      path: PATH.BILLABLEHOURS,
      icon: <AiIcons.AiOutlineUnorderedList />,
    },
    // {
    //   title: "Monthly Employee Compensation",
    //   path: PATH.MONTHLYEMPLOYEECOMPENSATION,
    //   icon: <AiIcons.AiOutlineUnorderedList />,
    // },
  ];
  const adminNavbarData = [
    {
      title: "Dashboard",
      // path: PATH.DASHBOARD.replace(":type", "ceo"),
      icon: <AiIcons.AiOutlineDashboard />,
    },
    {
      title: "Projects",
      // path: PATH.PROJECTS,
      icon: <RiIcons.RiProjectorFill />,
    },
    {
      title: "Workload",
      // path: PATH.WORKLOAD,
      icon: <FaIcons.FaBalanceScale />,
    },
    {
      title: "Day Planner",
      // path: PATH.TODO,
      icon: <BsIcons.BsListUl />,
    },
    {
      title: "Video Call",
      // path: PATH.VIDEOCALL,
      icon: <BsIcons.BsCameraVideo />,
    },
    {
      title: "Team Scheduler",
      // path: PATH.TEAMSCHEDULER,
      icon: <GoIcons.GoDash />,
    },
    // {
    //   title: "Human Resource",
    //   icon: <AiIcons.AiOutlineHome />,
    //   iconClosed: <BsIcons.BsArrowLeftShort />,
    //   iconOpened: <BsIcons.BsArrowDownShort />,
    //   path: "#",
    //   subNav: [
    //     {
    //       title: "Employees",
    //       path: PATH.EMPLOYEE,
    //       icon: <BsIcons.BsPeople />,
    //     },
    //     {
    //       title: "Assets",
    //       path: PATH.ASSETS,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Assets Allocation",
    //       path: PATH.ASSETSALLOCATION,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Daily Attendence",
    //       path: PATH.DAILYATTENDANCE,
    //       icon: <AiIcons.AiFillCheckSquare />,
    //     },
    //     {
    //       title: "Attendence",
    //       path: PATH.ATTENDANCE,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Leave Request",
    //       path: PATH.LEAVEREQUEST,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Users",
    //       path: PATH.USERLIST,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Departments",
    //       path: PATH.DEPARTMENTS,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Late Coming",
    //       path: PATH.LATECOMING,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Teams",
    //       path: PATH.TEAMS,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Suggestions",
    //       path: PATH.SUGGESTIONS,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Holidays",
    //       path: PATH.HOLIDAYS,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Events",
    //       path: PATH.EVENT,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "File",
    //       path: PATH.FILES,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "File Category",
    //       path: PATH.FILE_CATEGORY,
    //       icon: <GoIcons.GoDash />,
    //     },
    //   ],
    // },
    // {
    //   title: "Finance",
    //   icon: <FaIcons.FaHandHoldingUsd />,
    //   iconClosed: <BsIcons.BsArrowLeftShort />,
    //   iconOpened: <BsIcons.BsArrowDownShort />,
    //   subNav: [
    //     {
    //       title: "Dashboard",
    //       path: PATH.FINANCE_DASHBOARD,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Billable Hours",
    //       path: PATH.BILLABLEHOURS,
    //       icon: <GoIcons.GoDash />,
    //       iconClosed: <BsIcons.BsArrowLeftShort />,
    //       iconOpened: <BsIcons.BsArrowDownShort />,
    //     },
    //     {
    //       title: "Create Invoice",
    //       path: PATH.NEWINVOICE,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Invoices",
    //       path: PATH.INVOICELIST,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Income",
    //       path: PATH.INCOME,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Provident Fund",
    //       path: PATH.PROVIDENT_FUND,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Expense",
    //       path: PATH.EXPENSE,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Salaries",
    //       path: PATH.SALARY,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Loans",
    //       path: PATH.LOANS,
    //       icon: <GoIcons.GoDash />,
    //     },
    //     {
    //       title: "Cash Category",
    //       path: PATH.CASHCATEGORY,
    //       icon: <GoIcons.GoDash />,
    //     },
    //   ],
    // },
    // {
    //   title: "Settings",
    //   path: "#",
    //   icon: <MdIcons.MdSettings />,
    //   iconClosed: <BsIcons.BsArrowLeftShort />,
    //   iconOpened: <BsIcons.BsArrowDownShort />,
    //   subNav: [
    //     {
    //       title: "Trash",
    //       path: PATH.TRASH,
    //       icon: <BsIcons.BsTrashFill />,
    //     },
    //     {
    //       title: "User Role",
    //       path: PATH.USER_ROLES,
    //       icon: <FaIcons.FaUserAlt />,
    //     },
    //     {
    //       title: "Manage Menus",
    //       path: PATH.MANAGEMENU,
    //       icon: <BiIcons.BiFoodMenu />,
    //     },
    //     {
    //       title: "Assign Role Based Menus",
    //       path: PATH.ROLEBASEDMENUS,
    //       icon: <FaIcons.FaUserAlt />,
    //     },
    //     {
    //       title: "Organizations",
    //       path: PATH.ORGANIZATION,
    //       icon: <GoIcons.GoOrganization />,
    //     },
    //     {
    //       title: "New User",
    //       path: PATH.MANAGEUSER,
    //       icon: <FaIcons.FaUserAlt />,
    //     },
    //     {
    //       title: "Clients",
    //       path: PATH.CLIENTS,
    //       icon: <FaIcons.FaUserAlt />,
    //     },
    //     {
    //       title: "Project Settings",
    //       path: PATH.PROJECTSETTINGS,
    //       icon: <MdIcons.MdSettings />,
    //     },
    //   ],
    // },
    // {
    //   title: "File Cloud",
    //   path: PATH.REPORTFILES,
    //   image: IMAGES.APPLOGO,
    // },
    // {
    //   title: "Pricing",
    //   path: PATH.PRICING,
    //   image: IMAGES.APPLOGO,
    // },
  ];

  // const navBarData =
  //   auth.user.mainUserId === 2 ? adminNavbarData : userNavbarData;

  const history = useHistory();
  // console.log(history.location.pathname, "history");
  return (
    <>
      <ul className="menu ">
        {userNavbarData.map((data, index) => {
          return (
            <li
              key={index}
              className="at_mainmenu_link nav-menu position-relative"
            >
              <Link
                className={`default-link-subbar smooth hover-enabled css-1i9kywd ${history?.location?.pathname === data.path && "active"
                  } ${index + 1 == userNavbarData.length && "border-0"}`}
                to={data?.path}
              >
                {data.title} {data?.subNav && <FiChevronDown />}
                <span className="default-link-subbar-line" />
              </Link>
              {data?.subNav && <HeaderDropDoenoptions navs={data?.subNav} />}
            </li>
          );
        })}
      </ul>
    </>
  );
}
