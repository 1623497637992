import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Card, Form, Dropdown, Breadcrumb, InputGroup } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

import { SimpleTable } from "../../genericComponents";
import { PATH } from "../../config";
import { AiFillDelete, AiOutlineFilePdf, AiOutlinePlus } from "react-icons/ai";
import { IoSearchOutline } from "react-icons/io5";
import { FiSend } from "react-icons/fi";
import { BsEnvelope, BsThreeDotsVertical } from "react-icons/bs";
import { HiDuplicate } from "react-icons/hi";
import { FaEdit, FaRegBell } from "react-icons/fa";
import { BiDollarCircle } from "react-icons/bi";

const Invoice = () => {
  const history = useHistory();
  const invoiceColumn = [
    {
      Header: "Invoice Number",
      accessor: "invoiceNumber",
    },
    {
      Header: "Client",
      accessor: "client",
    },
    {
      Header: "Date",
      accessor: "dueDate",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: () => <DropdownComponent />,
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: () => <DropdownThreeDotComponent />,
    },
  ];
  const invoiceData = [{
    id: 0,
    invoiceNumber: "001",
    client: "Xeven Solutions",
    dueDate: "Jan 19, 2023",
    amount: "$310,000 USD",
  },
  {
    id: 1,
    invoiceNumber: "002",
    client: "Invozone",
    dueDate: "FEB 19, 2023",
    amount: "$810,000 USD",
  },
  {
    id: 2,
    invoiceNumber: "003",
    client: "NetSole",
    dueDate: "Mar 19, 2023",
    amount: "$810,000 USD",
  },
  {
    id: 3,
    invoiceNumber: "004",
    client: "ArbiSoft",
    dueDate: "Mar 21, 2023",
    amount: "$710,000 USD",
  },
  {
    id: 4,
    invoiceNumber: "005",
    client: "Rolustech",
    dueDate: "Mar 28, 2023",
    amount: "$910,000 USD",
  },
  {
    id: 5,
    invoiceNumber: "005",
    client: "Rolustech",
    dueDate: "Mar 28, 2023",
    amount: "$910,000 USD",
  },
  {
    id: 6,
    invoiceNumber: "005",
    client: "Rolustech",
    dueDate: "Mar 28, 2023",
    amount: "$910,000 USD",
  },
  {
    id: 7,
    invoiceNumber: "005",
    client: "Rolustech",
    dueDate: "Mar 28, 2023",
    amount: "$910,000 USD",
  },
  {
    id: 8,
    invoiceNumber: "005",
    client: "Rolustech",
    dueDate: "Mar 28, 2023",
    amount: "$910,000 USD",
  },
  {
    id: 9,
    invoiceNumber: "005",
    client: "Rolustech",
    dueDate: "Mar 28, 2023",
    amount: "$910,000 USD",
  },
  ]
  const statusOptions = [
    {
      name: "Status 1",
    },
    {
      name: "Status 2",
    },
  ];
  return (
    <>
      <ToastContainer />
      <Breadcrumb className="employee-salary-breadcrumb invoice-breadcrumb">
        <Breadcrumb.Item onClick={() => history.push("/expense")}>Expense</Breadcrumb.Item>
        <Breadcrumb.Item active>Invoice</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-12 d-flex flex-wrap justify-content-between mb-8">
          <h2 className="align-self-center">Invoice</h2>
          <div className="Fms-income">
            <Link to={PATH.ADD_INVOICE}>
              <button
                type="button"
                className="generic-gradient-button"
              >
                Create Invoice
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Card className="finance-card">
        <Card.Body className="p-0">
          <div className="d-flex flex-wrap justify-content-between align-items-center pt-6 pb-2 px-6">
            <div className="d-flex align-items-center mb-2 mr-2 responsive-field">
              <InputGroup className="mb-3">
                <InputGroup.Text className="search-field-input-group" id="basic-addon1"><IoSearchOutline /></InputGroup.Text>
                <Form.Control
                  className="search-field-input-class"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="d-flex flex-wrap justify-content-end">
              <div className="d-flex flex-wrap justify-content-end">
                <div className="select_disable mr-2 mb-2">
                  <select
                    name="designationOptions"
                    className="finance-select"
                  >
                    <option value="" selected disabled>
                      Status
                    </option>

                    {statusOptions?.map((list, index) => {
                      return (
                        <option
                          key={index}
                          value={list.name}
                          selected={
                            list.name === "" ? true : false
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="select_disable mr-2 mb-2">
                  <Form.Group className="mb-3">
                    <input type="date" placeholder="Date" value="01-11-23" className="form-control finance-select-datepicker" />
                  </Form.Group>
                </div>
              </div>
              <div className="mr-2 mb-2">
                <button className="btn project_clr_btn cursor-pointer">
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 px-0">
            <SimpleTable columns={invoiceColumn} data={invoiceData} />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Invoice;

function DropdownThreeDotComponent() {
  const history = useHistory();
  const Swal = require("sweetalert2");
  function ThreeDotDropdown() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#01362c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }
  // const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Dropdown className="day-cell-dropdown text-right bg-transperant fms-button-three-dots">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="dropdown-button"
        >
          <BsThreeDotsVertical color="#666666" size={24} /></Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            value="sadsdfsd"
            onClick={() => history.push("/invoice/add")}
          >
            <FaEdit className="font-size-h3 mx-2" /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={ThreeDotDropdown}
          >
            <HiDuplicate className="font-size-h3 mx-2" /> Duplicate
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={ThreeDotDropdown}
          >
            <FiSend className="font-size-h3 mx-2" /> Mark As Sent
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={ThreeDotDropdown}
          >
            <FaRegBell className="font-size-h3 mx-2" /> Disable Reminder
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={ThreeDotDropdown}
          >
            <BsEnvelope className="font-size-h3 mx-2" /> Send By Email
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={ThreeDotDropdown}
          >
            <AiOutlineFilePdf className="font-size-h3 mx-2" /> Download PDF
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center table-font-color"
            onClick={ThreeDotDropdown}
          >
            <BiDollarCircle className="font-size-h3 mx-2" /> Mark as Paid
          </Dropdown.Item>
          <Dropdown.Item
            className="px-2 d-flex align-items-center text-danger"
            onClick={ThreeDotDropdown}
          >
            <AiFillDelete className="font-size-h3 mx-2" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <EditIncomeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
    </>
  );
}
function DropdownComponent() {
  const [customizeAmount, setCustomizeAmount] = useState("Paid");
  function Notification() {
    toast.success("Your Status has been changed", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <React.Fragment>
      <Dropdown>
        <Dropdown.Toggle
          // variant="success"
          id="dropdown-basic"
          className={`btn-block btn-sm price-modal-dropdown ${customizeAmount === "Paid"
            ? "fms-paid"
            : customizeAmount === "Pending"
              ? "fms-pending"
              : "fms-approved"
            }`}
        >
          {customizeAmount}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            className="py-2"
            onClick={() => {
              setCustomizeAmount("Paid");
              Notification();
            }}
          >
            {"Paid "}
          </Dropdown.Item>
          <Dropdown.Item
            className="py-2"
            onClick={() => {
              setCustomizeAmount("Approved");
              Notification();
            }}
          >
            {" Approved"}
          </Dropdown.Item>
          <Dropdown.Item
            className="py-2"
            onClick={() => {
              setCustomizeAmount("Pending");
              Notification();
            }}
          >
            {" Pending"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
}
